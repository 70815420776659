.alert-wrapper {
  z-index: 1000;
}
.preview-alert {
  top: 4.375rem !important;
}

.start-43 {
  left: 43%;
}

.alert-notification {
  --notification-max-width: 40rem;
  max-width: 30rem;
  min-width: 21.875rem;
  display: inline-flex;
  margin: 0.625rem;
  padding: 1rem;
  align-items: center;
  gap: 1rem;
  border-radius: 0.25rem;
  border: 0.125rem solid transparent;
  &.alert-success {
    border-color: var(--#{$variable-prefix}pastel-green);
    background: var(--#{$variable-prefix}bg-light-mint);
    .alert-icon-wrap {
      background-color: var(--#{$variable-prefix}bg-bright-green);
    }
  }
  &.alert-info {
    border-color: var(--#{$variable-prefix}sky-blue);
    background: var(--#{$variable-prefix}bg-light-blue);
    .alert-icon-wrap {
      background-color: var(--#{$variable-prefix}bg-royal-blue);
    }
  }
  &.alert-warning {
    border-color: var(--#{$variable-prefix}bg-pale-orange);
    background: var(--#{$variable-prefix}bg-cream-color);
    .alert-icon-wrap {
      background-color: var(--#{$variable-prefix}golden-yellow);
    }
  }
  &.alert-danger {
    border-color: var(--#{$variable-prefix}bg-coral-color);
    background: var(--#{$variable-prefix}bg-pale-pink-color);
    .alert-icon-wrap {
      background-color: var(--#{$variable-prefix}bg-orange-red-color);
    }
  }
  &.preview-notify {
    padding: 0.2rem 0.4rem;
    margin: 0.42rem;
    max-width: var(--notification-max-width);
  }
  &.show-alert-for-all-mediaurl {
    max-width: 27rem;
  }
  .alert-icon-wrap {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.25rem;
    justify-content: center;
    align-items: center;
    border-radius: 3.125rem;
    background-color: transparent;
  }

  .alert-info-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    flex: 1;
    h4 {
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p {
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &.show-alert-for-all-mediaurl p {
    font-size: 0.7rem;
  }
  .alert-close-btn {
    width: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      path {
        fill: var(--#{$variable-prefix}soft-plum-gray);
      }
    }
  }

  &.server-ready-alert {
    width: var(--notification-max-width);
  }
}
