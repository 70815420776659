.filter-section {
  display: flex;
  padding: 0.75rem 0;
  align-items: center;
  gap: 0.75rem;
  .filter-text {
    color: #585858;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }
}

.custom-dropdown-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex: 1;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  .custom-dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
    border-radius: 0.25rem;
    border: 0.063rem solid #d9d9d9;
    background-color: #fff;
    padding: 0.625rem;
    color: #000;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    cursor: pointer;
    .dropdown-arrow {
      svg {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }
  .custom-dropdown-menu {
    position: absolute;
    background-color: #fff;
    border: 0.063rem solid #d9d9d9;
    margin-top: 0.313rem;
    list-style: none;
    padding: 0;
    width: 100%;
    z-index: 1;
    max-height: 150px;
    overflow-y: auto;
    .custom-dropdown-item {
      padding: 0.625rem;
      color: #000;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;
      cursor: pointer;
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}
