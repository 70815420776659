.loader-line {
  width: 12.5rem;
  height: 0.188rem;
  position: relative;
  overflow: hidden;
  background-color: var(--#{$variable-prefix}bg-cloud-gray);
  margin: 0.625rem auto;
  -webkit-border-radius: 1.25rem;
  -moz-border-radius: 1.25rem;
  border-radius: 1.25rem;
  &:before {
    content: '';
    position: absolute;
    left: -50%;
    height: 0.188rem;
    width: 40%;
    background-color: var(--#{$variable-prefix}primary-color);
    -webkit-animation: lineAnim 1.5s linear infinite;
    -moz-animation: lineAnim 1.5s linear infinite;
    animation: lineAnim 1.5s linear infinite;
    -webkit-border-radius: 1.25rem;
    -moz-border-radius: 1.25rem;
    border-radius: 1.25rem;
  }
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}

@keyframes loader-animate {
  0% {
    background-position: -12.5rem 0;
  }
  100% {
    background-position: calc(12.5rem + 100%) 0;
  }
}

.content-loader-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.25rem;
}

.content-loader {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 9.7rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background: #f7f7f7;
  overflow: hidden;

  &.full-content-loader {
    height: 7rem;
  }

  .top-loader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .loader-bar {
    background-color: #e3e3e3;
    border-radius: 0.25rem;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0) 81%);
      animation: loader-animate 1.4s linear infinite;
    }

    &.small-bar {
      width: 8rem;
      height: 1.25rem;
    }

    &.large-bar {
      width: 3.875rem;
      height: 5.25rem;
    }

    &.full-bar {
      width: 100%;
      height: 2.5rem;
    }
  }
}