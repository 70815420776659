.render-screen-wrapper {
  height: calc(100vh - 4.163rem);
  .screen-footer,
  .screen-header {
    padding: 1.25rem 2.5rem;
    @media (max-width: 1600px) {
      padding: 0.625rem 2.5rem;
    }
  }
  .screen-size {
    display: flex;
    align-items: center;
    gap: 1.875rem;
  }
  .screen-title-wrap {
    padding: 0;
  }

  .screen-render-holder {
    @media (max-width: 1600px) {
      min-height: calc(100% - 13.4rem);
      max-height: calc(100% - 13.4rem);
    }
    .video-preview-wrap {
      padding: 0.5rem 2.5rem;
      .react-player {
        // @media (max-width: 1600px) {
        //   max-height: 22.5rem;
        //   min-height: 22.5rem;
        // }
        aspect-ratio: 16 / 9;
      }
    }
    .video-preview-content {
      width: 66%;
      margin: 0 auto;
      aspect-ratio: 16 / 9;
      @media (max-width: 1600px) {
        width: 60%;
      }
      @media (max-width: 1499px) {
        width: 85%;
      }
      @media (max-width: 1399px) {
        width: 52%;
      }
      @media (max-width: 1199px) {
        width: 80%;
      }
      @media (max-width: 1023px) {
        width: 100%;
      }
      .video-wrap {
        padding: 0;
      }
    }
  }
  .render-status-wrap {
    flex: 1;
  }
}
.video-thumbnail-wrap {
  .screen-thumbnail-items {
    .video-play-btn {
      display: none;
      margin: 0;
      padding: 0.25rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 1.25rem;
      border: 0.188rem solid rgb(209 212 217);
      background: var(--#{$variable-prefix}background-white);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in-out;
      svg {
        display: flex;
        width: 0.625rem;
        height: 0.625rem;
        padding: 0.069rem 0.074rem 0.07rem 0.137rem;
        justify-content: center;
        align-items: center;
      }
    }
    position: relative;
    transition: all 0.3s ease-in-out;
    &:hover {
      .screen-display-wrap {
        span {
          display: none;
        }
        .download-btn {
          display: inline-block;
        }
      }
      .video-play-btn {
        display: inline-flex;
      }
    }
  }
  .screen-display-wrap {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0.25rem;
    bottom: 0.25rem;
    transition: all 0.3s ease-in-out;
    span {
      display: flex;
      margin: 0;
      padding: 0.125rem 0.375rem;
      justify-content: center;
      align-items: center;
      color: var(--#{$variable-prefix}white-color);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.004rem;
      border-radius: 0.125rem;
      background: var(--#{$variable-prefix}bg-soft-plum-gray);
      transition: all 0.3s ease-in-out;
    }
    .download-btn {
      display: none;
      padding: 0.125rem 0.375rem;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
      background-color: var(--#{$variable-prefix}secondary-color);
      color: var(--#{$variable-prefix}white-color);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border: none;
      transition: all 0.3s ease-in-out;
    }
    .crashed-btn {
      display: inline-flex;
      padding: 0.125rem 0.375rem;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
      border: 0;
      border-radius: 0.125rem;
      background: var(--#{$variable-prefix}dark-red);
      color: var(--#{$variable-prefix}white-color);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      transition: all 0.3s ease-in-out;
    }
  }
}

.screen-thumbnail-items {
  .video-play-btn {
    display: inline-flex;
    margin: 0;
    padding: 0.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1.25rem;
    border: 0.188rem solid rgb(209 212 217);
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out;
    svg {
      display: flex;
      width: 0.625rem;
      height: 0.625rem;
      padding: 0.069rem 0.074rem 0.07rem 0.137rem;
      justify-content: center;
      align-items: center;
    }
  }
  position: relative;
  transition: all 0.3s ease-in-out;
  &:hover {
    .screen-display-wrap {
      // span {
      //   display: none;
      // }
      .download-btn {
        display: inline-block;
      }
    }
    .video-play-btn {
      display: inline-flex;
    }
  }
}

.render-status-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3.75rem;
  @media (max-width: 1099px) {
    gap: 1rem;
  }
  .status-report {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.25rem;
    h4 {
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.011rem;
    }
  }
  .status-info-wrap {
    display: flex;
    align-items: center;
    gap: 1.875rem;
    .status-count {
      color: var(--#{$variable-prefix}soft-plum-gray);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.005rem;
    }
    .render-status-circle {
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 1.875rem;
      background: var(--#{$variable-prefix}light-lavender);
      box-shadow: 0 0 0.375rem 0 var(--#{$variable-prefix}light-lavender);
      &.render-warning-status {
        background: var(--#{$variable-prefix}bg-vivid-orange);
        box-shadow: 0 0 0.375rem 0 var(--#{$variable-prefix}bg-vivid-orange);
      }
      &.render-primary-status {
        background: var(--#{$variable-prefix}primary-color);
        box-shadow: 0 0 0.375rem 0 var(--#{$variable-prefix}primary-color);
      }
      &.render-danger-status {
        background: var(--#{$variable-prefix}bright-red);
        box-shadow: 0 0 0.375rem 0 var(--#{$variable-prefix}bright-red);
      }
      &.render-success-status {
        background: var(--#{$variable-prefix}secondary-color);
        box-shadow: 0 0 0.375rem 0 var(--#{$variable-prefix}secondary-color);
      }
    }
  }
}

.accordion-sub-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1 0 0;
  // border-bottom: 0.063rem solid var(--#{$variable-prefix}midnight-gray);
  .editable-content {
    width: 100%;
  }
  .edit-active {
    .editable-title {
      font-weight: 700;
    }
  }
  .accordion-sub-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    span {
      color: var(--#{$variable-prefix}cloud-gray);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.004rem;
    }
    .separater {
      width: 0.063rem;
      height: 1rem;
      background-color: var(--#{$variable-prefix}bg-cloud-gray);
    }
  }
  .refresh-icon {
    cursor: pointer;
    svg {
      fill: rgba(192, 194, 195, 0.5);
    }
  }
}

.detail-inner-accordion {
  .accordion-body {
    padding: 0;
  }
}

// .accordion-collapse {
//   &.show {
//     .accordion-body {
//       .accordion-inner-sidebar-list {
//         li {
//           background-color: var(--#{$variable-prefix}bg-light-aluminum);
//           .video-badge {
//             color: var(--#{$variable-prefix}white-color);
//           }
//           h4 {
//             color: var(--#{$variable-prefix}primary-color);
//             font-weight: 700;
//           }
//           span {
//             color: var(--#{$variable-prefix}soft-plum-gray);
//           }
//         }
//       }
//     }
//   }
// }

.accordion-inner-sidebar-list {
  .slide-active {
    background-color: var(--#{$variable-prefix}bg-light-aluminum);
    .video-badge {
      color: var(--#{$variable-prefix}white-color);
    }
    h4 {
      color: var(--#{$variable-prefix}primary-color);
      font-weight: 700;
    }
    span {
      color: var(--#{$variable-prefix}soft-plum-gray);
    }
  }
}

.accordion-inner-sidebar-list {
  padding: 0.625rem;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.625rem;
    padding: 0.625rem;
    margin: 0.125rem 0;
    border-radius: 0.25rem;
    cursor: pointer;
    &:hover {
      background-color: var(--#{$variable-prefix}bg-light-aluminum);
      .video-badge {
        color: var(--#{$variable-prefix}white-color);
      }
      h4 {
        color: var(--#{$variable-prefix}primary-color);
        font-weight: 700;
      }
      span {
        color: var(--#{$variable-prefix}soft-plum-gray);
      }
    }
    h4 {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      color: var(--#{$variable-prefix}white-color);
      font-size: 0.938rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.005rem;
    }
    span {
      color: var(--#{$variable-prefix}cloud-gray);
      text-align: right;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.004rem;
    }
  }
}

.video-badge {
  width: 1.625rem;
  height: 1.063rem;
  display: flex;
  padding: 0.125rem 0.375rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--#{$variable-prefix}white-color);
  text-align: center;
  font-size: 0.688rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 0.125rem;
  background: var(--#{$variable-prefix}secondary-color);
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.25);

  &.success-badge {
    background-color: var(--#{$variable-prefix}secondary-color);
  }
  &.danger-badge {
    background-color: var(--#{$variable-prefix}dark-red);
  }
}
.load-more {
  margin-block: 1.25rem;
  margin-inline: auto;
}
