.custom-breadcrumb {
  .breadcrumb {
    margin: 0;
    flex-wrap: nowrap;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: "";
    display: inline-block;
    width: 0.5rem;
    height: 1.063rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='17' viewBox='0 0 8 17' fill='none'%3E%3Cpath d='M0 16.5L8 8.5L0 0.5' stroke='%2379747E'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    margin: 0 0.5rem 0 0;
  }
  .breadcrumb-item {
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.005rem;
    a {
      color: var(--#{$variable-prefix}charcoal-gray);
      font-weight: 700;
    }
  }
}
