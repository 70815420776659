// Base form controls
//
// Overrides for common inputs for easier styling.

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

label {
  // font-size: 0.813rem;
  // font-weight: bold;
  outline: none;
  &:focus {
    outline: none;
  }
}

// input[type="text"],
// input[type="password"],
// input[type="email"],
// input[type="number"],
// input[type="tel"],
// input[type="url"],

input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
input[type='tel'],
input[type='url'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-control {
  // height: 2.375rem;
  // padding: 0.438rem 0.5rem;
  // font-size: 0.813rem;
  // color: var(--#{$variable-prefix}nightshade-gray);
  vertical-align: middle;
  background-color: var(--#{$variable-prefix}background-white);
  background-repeat: no-repeat; // Repeat and position set for form states (success, error, etc)
  background-position: right center;
  border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  border-radius: 0.188rem;
  outline: none;
  display: block;
  width: 100%;
  transition: all ease 0.1s;
  &.focus,
  &:focus {
    // border-color: $blue;
    box-shadow: 0 0 0.313rem rgba(81, 167, 232, 0.5);
  }
}

textarea {
  resize: none;
  height: auto;
}

.form-group {
  margin-bottom: 0.938rem;
}

input[type='checkbox'],
input[type='radio'] {
  display: inline;
  // margin-right: $small-spacing / 2;
}

// Inputs with contrast for easy light gray backgrounds against white.
// input.class is needed here to increase specificity over input[…]
input.input-contrast,
.input-contrast {
  background-color: #fafafa;

  &:focus {
    background-color: var(--#{$variable-prefix}background-white);
  }
}

// Custom styling for HTML5 validation bubbles (WebKit only)
::placeholder {
  color: #aaa;
}

button,
input[type='submit'] {
  &:focus,
  &.focus {
    outline: 0;
  }
}

//placeholder
.form-box {
  input:-moz-placeholder {
    opacity: 1; /* Firefox 18- */
    color: var(--#{$variable-prefix}nightshade-gray) !important;
  }
  input::-moz-placeholder {
    opacity: 1; /* Firefox 19+ */
    color: var(--#{$variable-prefix}nightshade-gray) !important;
  }
  ::-webkit-input-placeholder {
    color: var(--#{$variable-prefix}nightshade-gray) !important;
    opacity: 1 !important; /* for chrome */
  }
  textarea:-moz-placeholder {
    opacity: 1; /* Firefox 18- */
    color: var(--#{$variable-prefix}nightshade-gray) !important;
  }
  textarea::-moz-placeholder {
    opacity: 1; /* Firefox 19+ */
    color: var(--#{$variable-prefix}nightshade-gray) !important;
  }
  ::-webkit-input-placeholder {
    opacity: 1 !important; /* for chrome */
    color: var(--#{$variable-prefix}nightshade-gray) !important;
  }
}

.form-field {
  background: var(--#{$variable-prefix}background-white);
  border: 0.031rem solid var(--#{$variable-prefix}chill-gray);
  box-sizing: border-box;
  border-radius: 0.188rem;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--#{$variable-prefix}light-gray-text);
  padding: 0.25rem 0.5rem;
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
    border: 0.031rem solid var(--#{$variable-prefix}chill-gray);
  }
}

.custom-select {
  position: relative;
  .form-field {
    padding-right: 1.375rem;
  }
  &::after {
    content: '';
    box-sizing: content-box;
    display: inline-block;
    float: right;
    width: 0;
    height: 0;
    margin: 0;
    border-style: solid;
    border-width: 0.5rem 0.313rem 0 0.313rem;
    border-color: var(--#{$variable-prefix}chill-gray) transparent transparent transparent;
    position: absolute;
    right: 0.5rem;
    top: 0.75rem;
    pointer-events: none;
  }
}

.custom-range {
  .color-box {
    background: linear-gradient(
      90deg,
      rgba(229, 231, 235, 0) 0%,
      rgba(229, 231, 235, 0.68) 35.94%,
      #56c4f5 68.75%,
      #c156c2 100%
    );
    border-radius: 6.25rem;
    width: 100%;
    height: 0.375rem;
    display: block;
    position: absolute;
    top: 0.5rem;
  }
  input[type='range'] {
    position: relative;
    -webkit-appearance: none;
    width: 100%;
    height: 0.375rem;
    background: transparent;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0.625rem;
    height: 0.625rem;
    background: transparent;
    cursor: pointer;
    border-radius: 3.125rem;
    border: 0.125rem solid var(--#{$variable-prefix}light-platinum);
    box-sizing: border-box;
    z-index: 9;
  }
  input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0.625rem;
    height: 0.625rem;
    background: transparent;
    cursor: pointer;
    border-radius: 3.125rem;
    border: 0.125rem solid var(--#{$variable-prefix}light-platinum);
    box-sizing: border-box;
    z-index: 9;
  }
  .output {
    color: var(--#{$variable-prefix}light-gray-text);
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    padding: 0.313rem 0.5rem;
    background: var(--#{$variable-prefix}background-white);
    border: 0.031rem solid var(--#{$variable-prefix}chill-gray);
    box-sizing: border-box;
    border-radius: 0.125rem;
  }
}

.custom-range-1 {
  position: relative;
  .color-box {
    background: linear-gradient(270deg, #9ca3af 0%, #e5e7eb 100%);
    border-radius: 6.25rem;
    width: 100%;
    height: 0.375rem;
    display: block;
    position: absolute;
    top: 0.5rem;
    &::before {
      content: '';
      background: url('../../img/Ticks.svg');
      position: absolute;
      width: 100%;
      height: 0.125rem;
      top: -0.5rem;
    }
  }
  input[type='range'] {
    position: relative;
    -webkit-appearance: none;
    width: 100%;
    height: 0.25rem;
    background: transparent;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0.625rem;
    height: 0.875rem;
    background: var(--#{$variable-prefix}background-white);
    cursor: pointer;
    border: 0.125rem solid var(--#{$variable-prefix}light-platinum);
    // box-sizing: border-box;
    z-index: 9;
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    -webkit-transform: rotate(360deg);
    position: relative;
  }

  input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0.625rem;
    height: 0.875rem;
    background: var(--#{$variable-prefix}background-white);
    cursor: pointer;
    border: 0.125rem solid var(--#{$variable-prefix}light-platinum);
    // box-sizing: border-box;
    z-index: 9;
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    -webkit-transform: rotate(360deg);
    position: relative;
  }

  input[type='range']::-webkit-slider-thumb:before {
    position: absolute;
    content: '+';
    height: 0.625rem; /* equal to height of runnable track */
    width: 0.625rem; /* make this bigger than the widest range input element */
    left: 0; /* this should be -0.125rem - width */
    top: 0; /* don't change this */
    background: var(--#{$variable-prefix}dark-red);
  }
  input[type='range']::-moz-range-thumb:before {
    position: absolute;
    content: '+';
    height: 0.625rem; /* equal to height of runnable track */
    width: 0.625rem; /* make this bigger than the widest range input element */
    left: 0; /* this should be -0.125rem - width */
    top: 0; /* don't change this */
    background: var(--#{$variable-prefix}dark-red);
  }

  .output {
    color: var(--#{$variable-prefix}light-gray-text);
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    padding: 0.313rem 0.5rem;
    background: var(--#{$variable-prefix}background-white);
    border: 0.031rem solid var(--#{$variable-prefix}chill-gray);
    box-sizing: border-box;
    border-radius: 0.125rem;
  }
}

/* custom radio starts */

.custom-radio {
  display: block;
  position: relative;
  padding: 0 !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-radio .checkmark:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 0.438rem;
  width: 0.438rem;
  border-radius: 50%;
  box-sizing: content-box;
  background-color: $theme-blue;
  border: 0.188rem solid var(--#{$variable-prefix}background-white);
  @media (max-width: 1600px) {
    width: 0.538rem;
    height: 0.538rem;
  }
}

.custom-radio .checkmark:after {
  display: none;
}

.custom-radio input:checked ~ .checkmark {
  border: 0.063rem solid $theme-blue;
}

.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

.custom-radio .checkmark {
  position: relative;
  display: block;
  height: 0.938rem;
  width: 0.938rem;
  border: 0.063rem solid var(--#{$variable-prefix}dim-gray);
  border-radius: 50%;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* custom radio ends */

.search-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0 auto;
  max-width: 27.5rem;
  min-width: 27.5rem;
  @media (max-width: 767px) {
    min-width: inherit;
  }
}

.search-contain-wrap {
  border: 0.063rem solid #79747e !important;
  border-radius: 0.25rem;
  width: auto !important;
  margin: 0 !important;
  flex: 1;
  .search-close {
    top: 0.538rem;
  }
  .srch-holder {
    width: 100%;
  }
  .srch-inpt-holder {
    padding: 0 !important;
    .form-control {
      padding: 0.625rem 2.5rem 0.625rem 1rem !important;
      max-width: 100% !important;
      height: 100%;
    }
  }
}

.search-btn,
.upload-video-wrap {
  padding: 0.625rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  gap: 0.375rem;
  cursor: pointer;
  svg {
    width: 0.875rem;
  }
}

.upload-controls {
  .upload-control-btn {
    padding: 0.6rem 1.875rem;
    font-size: 1rem;
    font-weight: 500;
    gap: 0.375rem;
  }
}
