.voiceover-card-container {
  display: flex;
  padding: 1.25rem;
  margin: 0 -1.25rem -1.5rem;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-self: stretch;
  background-color: #f0edf6;
  min-height: 20rem;
  max-height: 20rem;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-color: auto;
  scrollbar-width: auto;
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.5rem;
    cursor: pointer;
  }
}

.voiceover-card {
  display: flex;
  width: 32.3%;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border: 0.063rem solid #f1f1f1;
  background-color: #fff;
  cursor: pointer;
  @media (max-width: 899px) {
    width: 48.9%;
  }
  &.active {
    border-color: #6750a4;
  }
  .checked-icon {
    display: flex;
    width: 1.25rem;
    height: 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.625rem;
    background-color: #6750a4;
    color: #fff;
    svg {
      width: 0.5rem;
      height: 0.5rem;
    }
  }
  .voiceover-card-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .hidden {
    display: none;
  }
}
