.email-secondary-header {
  display: flex;
  align-items: center;
  justify-content: end;
  position: sticky;
  z-index: 8;
  top: 4.125rem;
  flex-wrap: wrap;
  gap: 1.5rem;
  border-bottom: 0.063rem solid var(--#{$variable-prefix}gentle-sky);
  background-color: var(--#{$variable-prefix}background-white);
  padding: 0.75rem 2.5rem;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0.5rem;
  .dropdown-select {
    max-width: 15.438rem;
    min-width: 15.438rem;
    font-size: 0.875rem;
    letter-spacing: 0.004rem;
    font-weight: 400;
    font-style: normal;
    .css-1pahdxg-control {
      border: 0.063rem solid var(--bs-bg-soft-plum-gray);
      box-shadow: 0 0 0 0.063rem var(--bs-bg-soft-plum-gray);
      &:hover {
        border: 0.063rem solid var(--bs-bg-soft-plum-gray);
      }
    }
  }
}

.emails-table-wrapper {
  display: flex;
  gap: 0.625rem;
  .emails-table {
    width: calc(50% - 0.625rem);
    overflow-x: auto;
    .common-border-table .common-table tbody {
      .active-row {
        background-color: var(--bs-bg-pale-silver);
      }
    }
  }
  
}

.email-details {
  width: 100%;
  padding-left: unset;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.625rem;
  .emails-header {
    padding: 0.625rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .emails-body {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    gap: 0.625rem;
    padding: 0.625rem;
    span {
      strong {
        font-weight: 500;
      }
    }
  }
}

.email-detail-offcavas {
  min-width: 50%;
}