.chat-bg-color {
  background-color: #f7f7f7;
}

.chat-gpt-content {
  max-width: 102rem;
  margin: 0 auto;
  padding: 1.875rem;
  text-align: center;
  .project-compose-option {
    max-width: 100%;
  }
  .project-compose-item {
    @media (max-width: 991px) {
      width: 47.5%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  h2 {
    margin: 0 0 1rem;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.006rem;
  }
  .chat-button-wrap {
    display: flex;
    gap: 1.875rem;
    margin: 0 0 3.75rem;
  }
}

.chat-generate-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.625rem;
  align-self: stretch;
  .primary-btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}

.characters-count {
  color: var(--#{$variable-prefix}soft-plum-gray);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.004rem;
}

.chat-gpt-textarea {
  margin: 0 0 1.875rem;
  width: 100%;
  textarea {
    min-height: calc(100vh - 26.875rem);
    height: 100%;
  }
}

.chat-wrap {
  &.active {
    border: 0.125rem solid #6750a4;
  }
}

.chat-title-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  &.active {
    border: 0.125rem solid #6750a4;
  }
  &.slide {
    padding-left: 0.625rem;
  }
  h4 {
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.005rem;
  }
  span {
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.004rem;
  }
  strong {
    font-weight: 700;
  }
}

.generate-progress-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding: 0 2rem;
  .generate-title {
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.004rem;
  }
  .screen-size {
    color: var(--#{$variable-prefix}soft-plum-gray);
    text-align: left;
  }
  .loader-line {
    width: 100%;
    height: 0.375rem;
    &::before {
      height: 0.375rem;
    }
  }
}

.script-screen-wrapper {
  max-height: calc(100vh - 66px);
  padding: 2rem;
  @media (max-width: 1399px) {
    max-height: 100%;
  }
  .processed-sidebar-content {
    .screen-process-scroll {
      min-height: calc(100vh - 17.2rem);
      max-height: calc(100vh - 17.2rem);
    }
  }
  .chat-gpt-textarea {
    margin: 0 0 1.5rem;
    textarea {
      min-height: calc(100vh - 21.1rem);
      max-height: calc(100vh - 21.1rem);
      @media (max-width: 1399px) {
        min-height: calc(100vh - 40rem);
        max-height: calc(100vh - 40rem);
      }
      scrollbar-color: auto;
      scrollbar-width: auto;
      &::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
      }
    
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 0.5rem;
      }
    
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 0.5rem;
        cursor: pointer;
      }
    }
  }
}

.script-screen {
  display: flex;
  padding: 1.25rem 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  flex: 1 0 0;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow:
    0 -1px 3px 0 #f1f1f1,
    0 1px 2px 0 #f1f1f1;
    @media (max-width: 1399px) {
      margin-bottom: 1.5rem;
    }
  h4 {
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
}
