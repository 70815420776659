.card-thumbnail {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.25rem;
  background: var(--#{$variable-prefix}bg-light-aluminum);
  position: relative;
  padding-bottom: 1rem;
  .common-dropdown-wrap {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    .common-dropdown-btn {
      background-color: var(--#{$variable-prefix}bg-dark-navy);
      border-color: var(--#{$variable-prefix}bg-dark-navy);
      color: var(--#{$variable-prefix}white-color);
    }
    .common-dropdown-menu {
      svg {
        width: 0.875rem;
        height: 0.875rem;
      }
    }
    .dropdown-menu {
      z-index: 7 !important;
    }
  }
  .img-wrap {
    width: 100%;
    height: 13rem;
    border-radius: 0.25rem 0.25rem 0 0;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }
  .info-wrap {
    width: 100%;
    padding: 0 1rem;
    flex: 1;
    h4,
    .editable-title {
      margin: 0 0 1rem;
      color: var(--#{$variable-prefix}charcoal-black);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.005rem;
      @media (max-width: 1536px) {
        font-size: 0.875rem;
      }
    }

    .editable-content {
      .editable-input {
        margin: 0;
        @extend .editable-title;
      }
    }
  }
  .icon-wrap {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    color: var(--#{$variable-prefix}charcoal-black);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.005rem;
    @media (max-width: 1536px) {
      font-size: 0.875rem;
    }
  }
  .progress-wrap {
    padding: 0 1rem;
  }
  .progress-value {
    @media (max-width: 1536px) {
      font-size: 0.875rem;
    }
  }

  .icon-wrap-holder {
    display: flex;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    margin-top: 1rem;
    .icon-wrap {
      display: flex;
      padding-right: 0;
      align-items: center;
      gap: 0.625rem;
      flex: 1 0 0;
      border-right: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
      &:last-child {
        border-right: 0;
      }
    }
  }

  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    margin-bottom: 1rem;
    h4 {
      margin: 0;
    }
  }
}
