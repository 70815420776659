.secondary-header-spacing {
  padding: 0.625rem 2.5rem 0.625rem 1.25rem;
  .filter-by {
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.004rem;
  }
  .date-picker-field{
    height: 2.375rem;
  }
}

.status-report-header {
  display: flex;
  padding: 1.25rem 2.5rem;
  align-items: center;
  justify-content: flex-end;
  gap: 0.625rem;
  .render-status-wrap {
    flex: 1;
  }
}

.template-table-list {
  .common-table {
    tbody {
      tr {
        &:hover {
          background-color: var(--#{$variable-prefix}bg-pale-silver);
        }
      }
    }
    td {
      .table-thumbnail {
        display: flex;
        width: 4.5rem;
        height: 2.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0.125rem;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .cirlce-play-icon {
          color: var(--#{$variable-prefix}white-color);
          position: absolute;
          z-index: 1;
        }
      }
      .render-loading {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
      }
      .badge {
        width: 4.625rem;
        color: var(--#{$variable-prefix}white-color);
        border-radius: 0.125rem;
        padding: 0.313rem 0.625rem;
        &.bg-secondary {
          background-color: var(--#{$variable-prefix}bg-light-blue) !important;
          color: var(--#{$variable-prefix}soft-plum-gray);
        }
        &.bg-success {
          background-color: var(--#{$variable-prefix}bg-bright-green) !important;
        }
        &.bg-danger {
          background-color: var(--#{$variable-prefix}bg-orange-red-color) !important;
        }
      }

      span {
        font-size: 0.875rem;
        // text-align: center;
      }
      // &:nth-child(1),
      // &:nth-child(2),
      // &:nth-child(3) {
      //   span {
      //     display: block;
      //     text-align: left;
      //   }
      // }

      // &:nth-child(2),
      // &:nth-child(3) {
      //   span {
      //     text-align: center !important;
      //   }
      // }
    }
  }
}

.ellipse-btn {
  font-size: 0.875rem;
  text-align: center;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2.5rem;
  background-color: var(--#{$variable-prefix}background-white);
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.15);
  svg {
    width: 0.281rem;
    height: 0.875rem;
    flex-shrink: 0;
    color: var(--#{$variable-prefix}soft-plum-gray);
  }
}

.table-detail-list {
  margin: 0;
  th {
    padding: 0.875rem 0;
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.005rem;
  }
  td {
    @extend th;
    font-weight: 400;
  }
}

.template-detail-offcanvas {
  min-width:50%;
  padding: 1.25rem;
  // padding: 1.25rem 2.5rem 2.5rem 1.875rem;
  border-left: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  background: var(--#{$variable-prefix}background-white);
  margin-top: 4.125rem;
  .offcanvas-header {
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
    .offcanvas-title {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.005rem;
      cursor: pointer;
    }
  }
  .offcanvas-body {
    padding: 0 !important;
  }
  .client-settings-form {
    overflow-x: hidden;
  }
}

.template-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0.625rem 2.5rem;

  .template-header-right {
    display: flex;
    // align-items: center;
    gap: 0.625rem;
    .template-switch-wrapper {
      display: flex;
      align-items: center;
      border-radius: 0.25rem;
      gap: 1.25rem;
      padding: 0.5rem 1rem;
      border: 0.063rem solid #d9d9d9;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.004rem;
      color: var(--#{$variable-prefix}charcoal-gray);
      background-color: #fff;
      .custom-switch-label {
        .custom-switch-slider {
          height: 1.281rem;
          width: 2.778rem;
          &.checked {
            .circle {
              transform: translate(1.25rem, -50%);
            }
          }
          .circle {
            height: 0.625rem;
            width: 0.625rem;
          }
        }
      }
    }
  }
}
