.user-management-wrapper {
  display: flex;
  align-items: flex-start;
  padding: 2.5rem;
  gap: 2.5rem;
  @media (max-width: 1199px) {
    flex-direction: column;
    gap: 1rem;
  }
}
.user-detail-header {
  display: flex;
  align-items: end;
  justify-content: end;
}

.user-sidebar {
  display: flex;
  min-width: 20rem;
  max-width: 20rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  @media (max-width: 1199px) {
    min-width: 100%;
    max-width: 100%;
  }
}

.table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  align-self: stretch;
}

.common-box {
  width: 100%;
  padding: 1.25rem;
  border-radius: 0.25rem;
  border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  background: var(--#{$variable-prefix}background-white);
}

.common-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
  h4 {
    color: var(--#{$variable-prefix}charcoal-gray);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.005rem;
  }
}

.common-box-search {
  border-bottom: 0.063rem solid var(--#{$variable-prefix}gentle-sky);
  .custom-form-floating {
    margin: 0;
    input {
      padding: 0.625rem 1.875rem;
      &:focus {
        + {
          label {
            left: 0;
          }
        }
      }
      &:not(:placeholder-shown) {
        + {
          label {
            left: 0;
          }
        }
      }
    }
    label {
      left: 1.875rem;
    }
    .form-left-icon {
      left: 0;
    }
    .label-with-asterisk {
      display: none;
    }
  }
}

.circle-detail-block {
  display: flex;
  align-items: center;
  gap: 1rem;
  .info-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    flex: 1;
    h4 {
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.004rem;
    }
    span {
      color: var(--#{$variable-prefix}soft-plum-gray);
      font-size: 0.813rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.004rem;
    }
  }
}

.circle-img-wrap {
  width: 2.5rem;
  height: 2.5rem;
  border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}

// Common table
.common-table {
  margin: 0;
  th,
  td {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  th {
    padding: 1.25rem 0.938rem 0.313rem;
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
    text-transform: uppercase;
    white-space: nowrap;
  }
  td {
    padding: 0.938rem;
  }

  .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  tfoot {
    .btn {
      &:hover {
        font-weight: 600;
      }
      &:focus {
        background-color: var(--#{$variable-prefix}primary-color);
        color: var(--#{$variable-prefix}white-color);
      }
    }
  }
  .table-hour-spent {
    display: flex;
    justify-content: flex-end;
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
  }
}

.user-thumbnail {
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  background: var(--#{$variable-prefix}background-white);

  .user-img-holder {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .user-circle {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--#{$variable-prefix}background-white);
    padding: 0.625rem;
    margin-top: -3.125rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .user-img-wrap {
    width: 100%;
    height: 6.25rem;
    border-radius: 0.25rem;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .user-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    h4 {
      color: var(--#{$variable-prefix}charcoal-gray);
      text-align: center;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.006rem;
    }
    a {
      width: 100%;
      color: var(--#{$variable-prefix}charcoal-gray);
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.004rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .btn {
    width: 100%;
    &:focus {
      background-color: var(--#{$variable-prefix}primary-color);
      color: var(--#{$variable-prefix}white-color);
    }
  }
}

.profile-block {
  width: 100%;
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  border-radius: 0.25rem;
  border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  background: var(--#{$variable-prefix}background-white);
  h2 {
    color: var(--#{$variable-prefix}charcoal-gray);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.005rem;
  }
  h4 {
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
    text-transform: uppercase;
  }
}

.profile-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.25rem;
  margin-bottom: 1.25rem;
  &:last-child {
    margin-bottom: 0;
  }
  li {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    svg {
      width: 1rem;
      height: 1rem;
      color: var(--#{$variable-prefix}soft-plum-gray);
    }
    span {
      color: var(--#{$variable-prefix}charcoal-black);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.005rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex: 1;
    }
  }
}

.table-responsive {
  height: 100%;
  overflow-x: auto;
  scrollbar-color: var(--#{$variable-prefix}scrollbar-thumb-color)
    var(--#{$variable-prefix}scrollbar-track-color);
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    height: 0.313rem;
    height: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--#{$variable-prefix}scrollbar-track-color);
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--#{$variable-prefix}scrollbar-thumb-color);
    border-radius: 0.625rem;
    cursor: pointer;
  }
}

.common-border-table {
  padding: 1.25rem 2.5rem 0;
  .table-responsive {
    min-height: calc(100vh - 13.85rem);
    max-height: calc(100vh - 13.85rem);
    @media (max-width: 792px) {
      min-height: calc(100vh - 19.5rem);
      max-height: calc(100vh - 19.5rem);
    }
  }
  &.email-table {
    .table-responsive {
      min-height: 0;
      max-height: calc(100vh - 14.25rem);
    }
  }
  &.payments-log-table {
    .table-responsive {
      min-height: 0;
      max-height: calc(100vh - 14.25rem);
    }
  }
  &.template-table-list {
    .table-responsive {
      min-height: calc(100vh - 17.55rem);
      max-height: calc(100vh - 17.55rem);
    }
  }
  &.user-table-list {
    .table-responsive {
      min-height: calc(100vh - 17.25rem);
      max-height: calc(100vh - 17.25rem);
    }
  }

  &.tracking-table-list {
    .table-responsive {
      min-height: calc(100vh - 20.15rem);
      max-height: calc(100vh - 20.15rem);
    }
  }
  &.action-log-table {
    @media (max-width: 1069px) {
      .table-responsive {
        min-height: calc(100vh - 16rem);
        max-height: calc(100vh - 16rem);
      }
    }
    @media (max-width: 899px) {
      .table-responsive {
        min-height: calc(100vh - 18.5rem);
        max-height: calc(100vh - 18.5rem);
      }
    }
  }

  &.report-action-log-table,
  &.report-action-login-table {
    .table-responsive {
      min-height: calc(100vh - 14.15rem);
      max-height: calc(100vh - 14.15rem);
    }
  }
  h4 {
    margin-bottom: 1.875rem;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.011rem;
  }

  .common-table {
    th {
      padding: 0.625rem;
      font-weight: 700;
      text-transform: capitalize;
    }
    td {
      padding: 0.625rem;
      span {
        color: var(--#{$variable-prefix}soft-plum-gray);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.005rem;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    tbody {
      tr {
        &:hover {
          background-color: var(--bs-bg-pale-silver);
        }
      }
    }
    tr {
      &.disabled {
        span {
          opacity: 0.7;
          pointer-events: none;
        }
        .common-dropdown-btn {
          opacity: 0.7;
        }
      }
    }
    tbody > tr {
      border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
    }
    .common-dropdown-wrap {
      justify-content: center;
      // .dropdown {
      //   position: inherit;
      // }
      .common-dropdown-btn {
        border: 0;
      }
      .dropdown-item {
        svg {
          width: 0.875rem;
          height: 0.875rem;
        }

        svg.vidayopro_undelete_icon {
          width: 1rem;
          height: 2rem;
        }
      }
    }
  }
  .usages-table {
    td {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .deleted-box {
        display: inline;
        padding: 0.6rem;
        background-color: var(--#{$variable-prefix}bg-orange-red-color);
        color: var(--#{$variable-prefix}white-color);
        border-radius: 0.2rem;
      }
    }
    .status {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-inline: unset;
      .action-status {
        &:before {
          top: 50%;
          left: 0.125rem;
        }
      }
    }
    .template-status {
      .action-status {
        &:before {
          top: 50%;
          left: 0.125rem;
        }
      }
    }
    .delete-box {
      span {
        margin: 0 auto;
      }
    }
  }
  .client-list-table {
    td {
      span {
        display: block;
      }
    }
    .status {
      .action-status {
        &:before {
          top: 50%;
        }
      }
    }
  }
}
.usage-tracking-header {
  padding: 2.5rem;
  gap: 1.25rem;
  flex-direction: column;
  flex-wrap: wrap;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  .usage-tracking-header__item {
    @media screen and (min-width: 768px) {
      width: calc((100% - 1.25rem) / 2);
    }
    @media screen and (min-width: 1024px) {
      width: calc((100% - 2.5rem) / 3);
    }
    @media screen and (min-width: 1200px) {
      width: calc((100% - 5rem) / 5);
    }
  }
  .usage-tracking-header__item-title {
    color: var(--bs-charcoal-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }
  .usage-tracking-header__item-value {
    color: var(--bs-charcoal-gray);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.011rem;
  }
}

.action-status {
  color: var(--#{$variable-prefix}secondary-color);
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.004rem;
  position: relative;
  padding-left: 0.938rem;
  width: fit-content;
  margin: auto;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.625rem;
  }
  &.default {
    color: var(--#{$variable-prefix}soft-plum-gray);
    &:before {
      background: var(--#{$variable-prefix}bg-soft-plum-gray);
      box-shadow: 0 0 0.25rem 0 var(--#{$variable-prefix}jet-black);
      border-radius: 0.625rem;
    }
  }
  &.success {
    color: var(--#{$variable-prefix}secondary-color);
    &:before {
      background: var(--#{$variable-prefix}secondary-color);
      box-shadow: 0 0 0.25rem 0 var(--#{$variable-prefix}secondary-color);
    }
  }
  &.danger {
    color: var(--#{$variable-prefix}dark-red);
    &::before {
      background: var(--#{$variable-prefix}dark-red);
      box-shadow: 0 0 0.25rem 0 var(--#{$variable-prefix}bright-red);
    }
  }
  &.warning {
    color: var(--#{$variable-prefix}vivid-orange);
    &::before {
      background: var(--#{$variable-prefix}bg-vivid-orange);
      box-shadow: 0 0 0.25rem 0 var(--#{$variable-prefix}bg-vivid-orange);
    }
  }
}

.total-number-count {
  color: var(--#{$variable-prefix}soft-plum-gray);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.004rem;
  strong {
    font-weight: 700;
    color: var(--#{$variable-prefix}charcoal-gray);
  }
}

.custom-modal-right {
  position: absolute;
  top: 50%;
  right: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-right: 0;
  width: 32.5rem;
}

.dropdown-max-width {
  min-width: 13.438rem;
  justify-content: space-between;
}

.dropdown-max-width-sm {
  justify-content: space-between;
  min-width: 8.625rem;
}

.separater-text-line {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin: 1.25rem 0;
  color: var(--#{$variable-prefix}soft-plum-gray);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.031rem;
  position: relative;
  &::before,
  &:after {
    content: '';
    background: var(--#{$variable-prefix}bg-cloud-gray);
    width: 100%;
    height: 0.063rem;
  }
  span {
    min-width: max-content;
  }
}

.react-tel-input {
  .selected-flag {
    .arrow {
      content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wIDAuNUw1IDUuNUwxMCAwLjUiIHN0cm9rZT0iIzc5NzQ3RSIvPgo8L3N2Zz4K);
      width: 0.625rem !important;
      height: 0.375rem !important;
      border: 0 !important;
    }
    .arrow.up {
      content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wIDAuNUw1IDUuNUwxMCAwLjUiIHN0cm9rZT0iIzc5NzQ3RSIvPgo8L3N2Zz4K);
      width: 0.625rem;
      height: 0.375rem;
      border: 0;
      transform: rotate(180deg);
    }
  }
  .country-list {
    .search {
      padding: 0.625rem;
    }
    .search-box {
      width: 100%;
      padding: 0.375rem 0.75rem;
    }
  }
}

.th-id {
  width: 6.25rem;
}
.td-width {
  max-width: 12.5rem;
}
