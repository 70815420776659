.voice-modal {
  .modal-dialog {
    max-width: 71.375rem;
  }
  .modal-content {
    border-color: transparent;
    border-radius: 0.25rem;
    background: var(--#{$variable-prefix}background-white);
    box-shadow: 0 0 0.375rem 0 rgba(0, 0, 0, 0.25);
  }
  .modal-header {
    padding: 1.875rem 1.875rem 1.25rem;
    border: transparent;
    gap: 0.5rem;
    @media (max-width: 575px) {
      flex-direction: column;
      align-items: start;
    }
    .info-wrap {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    h4 {
      font-size: 1.125rem;
      font-weight: 600;
      color: #515151;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: #515151;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: row;
    padding: 0;
    border-top: 0.063rem solid #f2f2f2;
    @media (max-width: 1023px) {
      flex-direction: column;
    }
  }
  .modal-footer {
    margin-top: 1.875rem;
    padding: 1.25rem 0 0;
    border-top-color: #d9d9d9;
  }
}

.voice-left-content {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  max-width: 28.875rem;
  min-width: 28.875rem;
  padding: 1.875rem;
  background-color: #f2f2f2;
  border-radius: 0 0 0 0.25rem;
  @media (max-width: 1023px) {
    max-width: 100%;
    min-width: 100%;
  }
  .custom-search-form {
    margin: 0;
    border: 0.063rem solid #79747e;
    border-radius: 0.25rem;
    input {
      padding: 1.125rem 2.813rem;
    }
  }
}

.voice-right-content {
  padding: 1.875rem 1.875rem 1.25rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  .voice-controls {
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    font-weight: 500;
    color: #6750a4;
    margin-bottom: 0.938rem;
    cursor: pointer;
  }
  .radio-button-wrap {
    gap: 1.25rem;
  }
  .radio-label {
    .radio-info {
      h4 {
        font-size: 1rem;
        font-weight: 400;
        color: #79747e;
      }
    }
  }
  .generate-preview-wrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.938rem;
  }
  .voice-box {
    @media (max-width: 767px) {
      max-width: 48%;
      min-width: 48%;
    }
    @media (max-width: 575px) {
      max-width: 100%;
      min-width: 100%;
    }
  }
}

.select-voice-title {
  margin-bottom: 1rem;
  color: #393939;
  font-size: 1rem;
  font-weight: 600;
}

.voice-box-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.938rem;
  margin-bottom: 0.938rem;
}

.voice-pointer {
  cursor: pointer;
}

.voice-speed-wrap {
  padding: 2.188rem 1.875rem 1.875rem;
  border: 0.063rem solid #aaa;
  border-radius: 0.25rem;
  margin-bottom: 1.25rem;
  position: relative;
  .voice-speed-text {
    position: absolute;
    top: -0.938rem;
    left: 0.938rem;
    background-color: #fff;
    padding: 0.375rem;
    color: #79747e;
    font-size: 0.875rem;
    line-height: 1rem;
  }
  .css-ltlhnc-MuiSlider-root {
    height: 0.75rem;
    color: #4f3f7a;
  }
  .css-7o8aqz-MuiSlider-rail {
    opacity: 1;
    color: #edeef1;
  }
  .css-ttgsjq-MuiSlider-track {
    display: none;
  }
  .css-hzp7sc-MuiSlider-mark,
  .css-z5sdnj-MuiSlider-mark {
    background-color: #4f3f7a;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    opacity: 0.4;
  }
  .css-1as9v07-MuiSlider-thumb {
    border: 0.125rem solid #fff;
    box-shadow: 0 0 0.375rem 0 rgba(0, 0, 0, 0.25);
  }
  .css-1ucwjgd-MuiSlider-markLabel,
  .css-1bvr8oc-MuiSlider-markLabel {
    font-size: 0.75rem;
    font-weight: 400;
    color: #515151;
    top: 2.125rem;
  }
}
