.custom-form-floating {
  position: relative;
  margin-bottom: 1.25rem;

  input {
    width: 100%;
    padding: 1.156rem 2.813rem;
    border: 0.063rem solid var(--#{$variable-prefix}bg-soft-plum-gray);
    background: var(--#{$variable-prefix}background-white);
    border-radius: 0.25rem;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.031rem;

    &:focus {
      border-color: var(--#{$variable-prefix}primary-color);
      outline: none;

      + label {
        .label-with-asterisk {
          display: inline-block;
        }
      }
    }

    &:focus + label .label-with-asterisk,
    &:not(:placeholder-shown) + label .label-with-asterisk,
    &:not(:empty) + label .label-with-asterisk {
      display: initial;
    }
  }

  label {
    position: absolute;
    left: 2.813rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.875rem;
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.031rem;
    transition:
      all 0.3s ease,
      font-size 0.3s ease,
      color 0.3s ease,
      transform 0.1s ease-in-out;
    .label-with-asterisk {
      display: none;
      &:after {
        content: ' *';
        color: var(--#{$variable-prefix}dark-red);
        position: relative;
        top: -0.188rem;
      }
    }
  }

  textarea:focus + .focused-label,
  textarea:not(:placeholder-shown) + label,
  input:focus + label,
  input:not(:placeholder-shown) + label {
    color: var(--#{$variable-prefix}soft-plum-gray);
    background-color: var(--#{$variable-prefix}background-white);
    padding: 0.25rem 0.625rem;
    border-radius: 1.875rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.031rem;
    top: -0.125rem;
    left: 0.875rem;
    max-width: 92%;
  }

  .form-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
    color: var(--#{$variable-prefix}soft-plum-gray);
  }

  .form-left-icon {
    left: 1rem;

    & > svg {
      @extend .form-icon;
    }
  }

  .form-right-icon {
    right: 1rem;

    & > svg {
      @extend .form-icon;
    }
  }
}

.custom-select-dropdown {
  &.show {
    .caret-icon {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  .form-control {
    width: 100%;
    height: calc(3.2rem + 0.125rem);
    padding: 1rem 2.5rem 1rem 1rem;
    border: 0.063rem solid var(--#{$variable-prefix}bg-soft-plum-gray);
    background: var(--#{$variable-prefix}background-white);
    border-radius: 0.25rem;
    font-size: 1rem;
    transition:
      all 0.3s ease,
      font-size 0.3s ease,
      color 0.3s ease,
      transform 0.1s ease-in-out;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.031rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:focus {
      border-color: var(--#{$variable-prefix}primary-color);
      outline: none;
      + label {
        .label-with-asterisk {
          display: inline-block;
          margin-left: 0.25rem;
        }
      }
    }
  }
  .form-floating {
    > label {
      padding: 1.1rem;
      color: var(--#{$variable-prefix}soft-plum-gray);
      font-size: 0.875rem;
    }
    > .form-control {
      &:focus {
        // padding: 1rem;
        ~ {
          label {
            color: var(--#{$variable-prefix}soft-plum-gray);
            background-color: var(--#{$variable-prefix}background-white);
            padding: 0.25rem 0.625rem;
            border-radius: 1.875rem;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.031rem;
            top: -0.125rem;
            left: 0.875rem;
            opacity: 1;
            height: auto;
          }
        }
      }
      &:not(:placeholder-shown) {
        padding: 1rem 2.5rem 1rem 1rem;
        ~ {
          label {
            color: var(--#{$variable-prefix}soft-plum-gray);
            background-color: var(--#{$variable-prefix}background-white);
            padding: 0.25rem 0.625rem;
            border-radius: 1.875rem;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.031rem;
            top: -0.125rem;
            left: 0.875rem;
            opacity: 1;
            height: auto;
          }
        }
      }
    }
    > .form-select {
      ~ {
        label {
          color: var(--#{$variable-prefix}soft-plum-gray);
          background-color: var(--#{$variable-prefix}background-white);
          padding: 0.25rem 0.625rem;
          border-radius: 1.875rem;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.031rem;
          top: -0.125rem;
          left: 0.875rem;
          opacity: 1;
          height: auto;
        }
      }
    }
  }

  .caret-icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    display: inline-block;
    width: auto;
    color: var(--#{$variable-prefix}dark-navy);
    vertical-align: middle;
    transform: translateY(-50%) rotate(0deg);
    transition: transform 0.2s ease-in-out;
  }

  .dropdown-menu {
    width: 100%;
    max-height: 15rem;
    padding: 0.5rem;
    overflow: auto;
    border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
    border-radius: 0;
    overflow-x: hidden;
  }
  .dropdown-item {
    display: block;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.031rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0.063rem 0;
    &.active,
    &:hover {
      background-color: #e9ecef;
    }
  }
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;

  width: calc(100% - 2.75rem);
  height: calc(100% - 3.438rem);
  margin-top: 0.625rem;
  left: 1.125rem;
}
.custom-form-textarea {
  margin: 0;
  &.focused {
    .focused-label {
      color: var(--#{$variable-prefix}primary-color) !important;
    }
    textarea {
      padding: 1.25rem !important;
    }
  }

  textarea {
    width: 100%;
    padding: 1.25rem;
    border-radius: 0.25rem;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.005rem;
    resize: vertical;
    height: 9.625rem;
    &:focus {
      border-color: var(--#{$variable-prefix}primary-color);
      outline: none;
    }
    &:disabled {
      cursor: default;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
    }
  }
  label {
    top: 1.25rem;
    left: 1.25rem;
  }
  .focused-label {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
}
.title-textarea {
  textarea {
    height: 3.87rem;
  }
}

.custom-form-none-icon {
  margin-bottom: 0;
  input {
    padding: 1.156rem 1rem;
    &:focus {
      + label {
        .label-with-asterisk {
          display: none;
        }
      }
    }
  }
  label {
    left: 1rem;
  }
  .label-with-asterisk {
    display: none;
  }

  input:focus + label .label-with-asterisk,
  input:not(:placeholder-shown) + label .label-with-asterisk,
  input:not(:empty) + label .label-with-asterisk {
    display: initial;
  }
}

.add-form-component {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-bottom: 1.25rem;
  svg {
    width: 1rem;
    color: var(--#{$variable-prefix}charcoal-gray);
  }
  .custom-form-floating {
    flex: 1;
  }
}

.custom-calculate-floating {
  border-radius: 0.25rem;
  border: 0.063rem solid var(--#{$variable-prefix}primary-color);
  background: var(--#{$variable-prefix}background-white);
  padding: 1.25rem 1rem;
  position: relative;
  label {
    position: absolute;
    color: var(--#{$variable-prefix}soft-plum-gray);
    background-color: var(--#{$variable-prefix}background-white);
    padding: 0.25rem 0.625rem;
    border-radius: 1.875rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.031rem;
    top: -0.875rem;
    left: 1rem;
  }
  input {
    padding: 0;
    width: 100%;
    flex: 1;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.031rem;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
  }
  .calculate-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .calculate-wrap {
      position: absolute;
      right: 0;
      background-color: var(--#{$variable-prefix}background-white);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      svg {
        color: var(--#{$variable-prefix}charcoal-gray);
      }
    }
    textarea {
      border: none;
      height: 100%;
      width: 100%;
    }
  }
  .css-1uccc91-singleValue {
    height: 56% !important;
  }
  .template-type {
    z-index: unset !important;
  }

  .org-name {
    .css-2613qy-menu {
      z-index: 1061 !important;
    }
  }
}
.restriction-select {
  padding: unset;
  padding-block: calc(1.25rem / 2);
  .css-yk16xz-control {
    border: none;
  }
  .css-1pahdxg-control {
    border: none;
    box-shadow: none;
  }
  .css-1fhf3k1-control {
    border: none;
    background-color: transparent;
  }
}
.interval {
  width: calc((100% - 1.25rem) / 2);
}

.disbled-restrictions {
  background-color: hsl(0, 0%, 95%);
}

.custom-search-form {
  margin-top: 0.5rem;
  .label-with-asterisk {
    display: none;
  }
  input {
    border: none;
    appearance: none;
    padding: 0.625rem 2.813rem;
    &:focus {
      + label {
        .label-with-asterisk {
          display: none;
        }
      }
    }
  }
}

.custom-label-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition:
    all 0.3s ease,
    border-color 0.3s ease,
    box-shadow 0.3s ease;
  &:hover {
    border-color: var(--#{$variable-prefix}bg-soft-plum-gray);
  }
  &.active {
    border-color: var(--#{$variable-prefix}primary-color);
  }
  .input-wrap {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
  }
  .label-text {
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.005rem;
  }
  .input-icon {
    width: 1.125rem;
    height: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--#{$variable-prefix}background-white);
    border: 0.063rem solid var(--#{$variable-prefix}bg-night-sky-gray);
    border-radius: 0.125rem;
    transition: background-color 0.3s ease;
    font-size: 0.875rem;
    svg {
      width: 0.625rem;
    }
  }
  input[type='checkbox'] {
    &:checked {
      + {
        .input-icon {
          color: var(--#{$variable-prefix}night-sky-gray);
        }
      }
    }
    display: none;
  }
}

.textarea-full-height {
  height: 100%;
  textarea {
    height: 100%;
  }
}

.response-textarea {
  min-height: max-content;
  width: 100%;
  textarea {
    width: 100%;
    height: 100%;
  }
}

.custom-editor {
  padding: 0;

  .tox-tinymce {
    border: 0;
  }

  label {
    z-index: 99;
  }

  .calculate-input-container {
    display: inline;
  }
}

.checkbox-label {
  display: inline;
  color: var(--bs-soft-plum-gray);
}
