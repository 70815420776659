.video-content-wrapper {
  display: flex;
  flex-direction: column;
}
.video-segment-wrapper {
  display: flex;
  padding: 1.125rem 0rem;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  .add-new-section-wrap {
    min-width: 10rem;
    .add-new-screen-btn {
      width: 100%;
      height: 5.925rem;
    }
  }
}

.segment-slider-container {
  width: calc(100% - 10.938rem);
  display: flex;
  overflow: hidden;
  position: relative;
  .slick-slide {
    width: auto !important;
  }
  .slick-slider {
    .slick-arrow {
      display: none !important;
    }
  }
  & > div {
    display: flex;
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
    scrollbar-color: auto;
    scrollbar-width: auto;

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: #ebebeb;
      -webkit-border-radius: 6px;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 6px;
      border-radius: 6px;
      background: #6d6d6d;
    }
  }
}

.segment-slide {
  width: 10rem !important;
  min-width: 10rem;
  text-align: center;
  margin: 0.125rem 1rem 0.125rem;
  position: relative;
  &:hover {
    .segment-close-btn {
      display: flex;
    }
  }
  p,input,.screen-title {
    color: #79747e;
    font-size: 0.8125rem;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.00406rem;
    width:100%;
    // display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    border:none;
    border-bottom: solid 2px #fff;
    display: block;
    
    
    &.editmode{

      overflow: inherit;
      text-overflow: initial;
      white-space: normal;
    }
  }
  &.active {
    .segment-slider-img {
      border-color: #6750a4;
      min-height: 90px;
      background-color: var(--bs-bg-cloud-gray);
    }
  }
}

.segment-slider-img {
  border: 0.25rem solid #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.8);
  margin-bottom: 0.4375rem;
  min-height: 93px;
  background-color: #ccc;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.segment-close-btn {
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: absolute;
  top: 0.45rem;
  right: 0.45rem;
  background-color: var(--#{$variable-prefix}primary-color);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  cursor: pointer;
  svg {
    width: 0.6rem;
    height: 0.6rem;
    color: #fff;
  }
}
.segment-refresh-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: absolute;
  top: 0.45rem;
  left: 0.45rem;
  background-color: var(--#{$variable-prefix}primary-color);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  cursor: pointer;
  svg {
    width: 0.9rem;
    height: 0.9rem;
    color: #fff;
    fill: #fff;
    path: {
      fill: #fff;
    }
  }
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0.625rem;
  cursor: pointer;
  z-index: 2;
  &.nextBtnSegment {
    right: 0;
    max-width: 35px;
    z-index: 999;
  }
  &.prevBtnSegment {
    left: 0;
    max-width: 35px;
    z-index: 999;
  }
}
.newslider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  .slick-arrow {
    position: relative;
    margin: 0 10px;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    display: flex;
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
      cursor: default;
    }
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 6px;
    padding: 5px;
    left: 0px;
    top: 0px;
    background: transparent;
    border: solid 1px var(--#{$variable-prefix}primary-color);
    svg {
      position: absolute;
      width: 10px;
      height: auto;

      path {
        fill: var(--#{$variable-prefix}primary-color);
      }
    }
  }
  .segment-slider-container {
    width: calc(100vw - 199px);
    margin: 0 10px 0 0;
  }
}

.video-segment-top {
  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;
  border-bottom: 0.063rem solid #d9d9d9;
}

.video-segment-left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;
}

.slider-arrow-control {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .slick-arrow {
    transform: none;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    content: none;
  }
  .slick-next {
    position: static;
    color: #585858;
    background: none;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      &:hover {
        color: #585858;
      }
    }
  }
  .slick-prev {
    position: static;
    color: #585858;
    background: none;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      &:hover {
        color: #585858;
      }
    }
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: #585858;
  }
}

.segments-total-wrap {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .label {
    color: #585858;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.625rem;
  }
  .count {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;

    .current {
      color: #585858;
    }
    .total {
      color: #8d8d8d;
    }
  }
}

.rearrange-segments {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  color: #585858;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  &.disabled{
    opacity:0.5;
    pointer-events: none;
  }
  svg {
    width: 1rem;
    height: 1rem;
  }
}
