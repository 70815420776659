.main-content-wrapper {
  width: calc(100% - 5.5rem);
  margin-left: auto;
  position: relative;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 5.5rem;
  height: 100%;
  background-color: var(--#{$variable-prefix}bg-dark-navy);
  color: var(--#{$variable-prefix}white-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
}

.sidebar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.188rem 0.625rem;
  text-align: center;
  background-color: var(--#{$variable-prefix}bg-dark-navy);
}

.sidebar-list {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  overflow: hidden;
  ul {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    width: 100%;
    &:first-child {
      overflow-y: auto;
    }
    scrollbar-color: #999 #13171f;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 0.313rem;
      height: 0.313rem;
    }

    &::-webkit-scrollbar-track {
      background: #13171f;
      border-radius: 0.625rem;
    }

    &::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 0.625rem;
      cursor: pointer;
    }
  }
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.625rem 0.563rem;
    color: var(--#{$variable-prefix}white-color);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.004rem;
    text-decoration: none;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: var(--#{$variable-prefix}primary-color);
    }
  }
  .sidebar-icon {
    margin-bottom: 0.375rem;
    width: 1.5rem;
    aspect-ratio: 1;
    svg {
      // width: 100%;
      // height: 100%;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.inner-sidebar-list {
  padding-top: 4.313rem;
  li {
    a {
      display: flex;
      align-items: flex-start;
      gap: 0.625rem;
      padding: 1rem;
      color: var(--#{$variable-prefix}white-color);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.005rem;
      &:hover {
        background: var(--#{$variable-prefix}bg-dark-navy);
      }
      &.active {
        border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-dark-gray);
        background: var(--#{$variable-prefix}bg-dark-navy);
      }
    }
  }
}
