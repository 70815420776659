.custom-confirm-modal {
  max-width: 25rem;
  .modal-body {
    padding: 1.875rem;
    display: flex;
    flex-direction: column;
    .modal-icon {
      width: 3.75rem;
      height: 3.75rem;
      margin: 0 auto 1.25rem;
      display: flex;
      padding: 0.375rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 3.125rem;
      svg {
        width: 2rem;
        height: 2rem;
      }
      &.bg-success {
        background-color: var(--#{$variable-prefix}bg-light-mint) !important;
        color: var(--#{$variable-prefix}bright-green);
      }
      &.bg-info {
        background-color: var(--#{$variable-prefix}bg-light-blue) !important;
        color: var(--#{$variable-prefix}royal-blue);
      }
      &.bg-warning {
        background-color: var(--#{$variable-prefix}bg-cream-color) !important;
        color: var(--#{$variable-prefix}golden-yellow);
      }
      &.bg-danger {
        background-color: var(--#{$variable-prefix}bg-pale-pink-color) !important;
        color: var(--#{$variable-prefix}orange-red-color);
      }
    }
    h3 {
      margin-bottom: 0.625rem;
      color: var(--#{$variable-prefix}charcoal-gray);
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p {
      margin-bottom: 1.25rem;
      color: var(--#{$variable-prefix}charcoal-gray);
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .modal-content {
    border-radius: 0.25rem;
    background: var(--#{$variable-prefix}background-white);
    box-shadow: 0 0 0.375rem 0 rgba(0, 0, 0, 0.2);
  }
  .modal-close {
    position: absolute;
    top: 0.875rem;
    right: 1.5rem;
    svg {
      fill: var(--#{$variable-prefix}soft-plum-gray);
      width: 0.75rem;
    }
  }
  .modal-button-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    button {
      font-size: 0.875rem;
      font-weight: 500;
      padding: 0.625rem 1.875rem;
      &:disabled {
        opacity: 0.6;
      }
    }
    .primary-btn {
      background-color: var(--#{$variable-prefix}bg-light-aluminum);
    }
  }
}

.modal-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  svg {
    path {
      fill: var(--#{$variable-prefix}soft-plum-gray);
    }
  }
}

// Add files modal
.add-files-modal {
  // .modal-dialog {
  //   max-width: 30.563rem;
  // }

  .modal-content {
    padding: 1.875rem;
    gap: 1.875rem;
    border-radius: 0.25rem;
    background: var(--#{$variable-prefix}background-white);
    box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.25);
  }

  .modal-body {
    width: 100%;
    padding: 0;
    h2 {
      margin-bottom: 1.875rem;
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.015rem;
    }

    .active-component {
      width: 100%;
      height: calc(3.2rem + 0.125rem);
      padding: 1rem 2.5rem 1rem 1rem;
      border: 0.063rem solid var(--#{$variable-prefix}bg-soft-plum-gray);
      background: var(--#{$variable-prefix}background-white);
      border-radius: 0.25rem;
      font-size: 1rem;
      transition:
        all 0.3s ease,
        font-size 0.3s ease,
        color 0.3s ease,
        transform 0.1s ease-in-out;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;
      letter-spacing: 0.031rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .uploaded-image-container {
      width: 100%;
      height: 15rem;
      margin-bottom: 1rem;
      border-radius: 0.25rem;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .modal-footer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    padding: 0;
    border: 0;
    button {
      padding: 0.625rem 2.375rem;
      margin: 0;
      line-height: 1;
      border-radius: 0.25rem;
    }
    .outline-primary-btn {
      background-color: var(--#{$variable-prefix}bg-light-aluminum);
      &:hover {
        background-color: var(--#{$variable-prefix}primary-color) !important;
        border-color: var(--#{$variable-prefix}primary-color);
        color: var(--#{$variable-prefix}white-color);
      }
    }
  }
}

.file-upload-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
}

.file-upload-box {
  display: flex;
  padding: 1.25rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-align: center;
  h4 {
    color: var(--#{$variable-prefix}night-sky-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
  }
  .upload-btn {
    width: 8rem;
    &:hover {
      background-color: var(--#{$variable-prefix}primary-color);
      border-color: var(--#{$variable-prefix}primary-color);
      color: var(--#{$variable-prefix}white-color);
    }
  }
}

// Preview Modal
.media-preview-modal {
  .modal-close-btn {
    width: 2.25rem;
    height: 2.25rem;
    right: 1.25rem;
    top: 1.25rem;
    display: flex;
    padding: 0.625rem 0.625rem 0.625rem 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 3.125rem;
    background: var(--#{$variable-prefix}bg-charcoal-gray);
    color: var(--#{$variable-prefix}white-color);
    z-index: 2;
    svg {
      width: 0.75rem;
      height: 0.75rem;
      path {
        fill: var(--#{$variable-prefix}background-white);
      }
    }
  }
  .modal-dialog {
    max-width: 56.25rem;
  }
  .modal-body {
    padding: 0.625rem;
  }
  .modal-content {
    min-height: 37.375rem;
    border-left: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
    background: var(--#{$variable-prefix}background-white);
    border-radius: 0.25rem;
  }
  .preview-img-wrap {
    width: 100%;
    height: 37.375rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .react-player {
      height: 100%;
    }
  }
}


// add-template-modal

.add-template-modal {
  .modal-dialog {
    max-width: 71.25rem;
  }
  .modal-body {
    padding: 3.125rem;
    h2 {
      margin-bottom: 1.5rem;
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.011rem;
    }
    .selected-file-name {
      border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
      padding: 0.25rem;
      border-radius: 0.25rem;
      position: relative;
      .close-button {
        position: absolute;
        right: -0.625rem;
        top: -0.625rem;
        border: none;
        outline: none;
        background-color: #fff;
        border-radius: 50%;
      }
    }
  }
  .modal-content {
    border-radius: 0.25rem;
    background: var(--#{$variable-prefix}background-white);
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.25);
  }
  .ps-40 {
    padding-left: 2.5rem;
  }
}
.template-already-exist-modal {
  .modal-content {
    max-width: 25rem;
    .modal-body {
      padding: 1.875rem;
      border-radius: 0.25rem;
      p {
        margin-bottom: 1.25rem;
        color: var(--#{$variable-prefix}charcoal-gray);
        text-align: center;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
.ost-asset-modal {
  .modal-content {
    min-height: unset;
    .modal-body {
      h2 {
        margin-bottom: 1.5rem;
        color: var(--#{$variable-prefix}charcoal-gray);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.011rem;
      }
    }

    .ost-video-preview {
      height: unset;
    }
  }
}

.template-preview {
  .progress {
    width: 74%;
  }
}
.add-template-modal {
  top: 0.625rem;
  right: 0.625rem;
}

.contact-sales-modal {
  .modal-dialog {
    max-width: 35rem;
  }
}

.template-preview-body {
  .template-preview {
    position: inherit;
    left: 0;
    top: 0;
    -webkit-transform: none;
    transform: none;
    width: 100%;
    height: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.preview-video {
  .modal-content {
    min-height: unset;
    border-left: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
    background: var(--#{$variable-prefix}background-white);
    border-radius: 0.25rem;
  }
  .preview-img-wrap {
    width: 100%;
    height: unset;
  }
}

.render-segment-modal {
  max-width: 42.5rem;
  @media (max-width: 767px) {
    .modal-button-wrap {
      flex-wrap: wrap;
      button {
        width: 100%;
      }
    }
  }
}

.voiceover-modal {
  .close-modal {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    svg {
      width: 0.75rem;
      height: 0.75rem;
      color: #3E3E3E;
    }
  }
  .modal-dialog {
    max-width: 54.75rem;
  }
  .modal-content {
    border-radius: 0.375rem;
    border: 0.063rem solid #f1f1f1;
    background-color: #fff;
  }
  .modal-body {
    padding: 2.5rem;
  }
  .modal-buttton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    .bg-primary-btn {
      padding: 0.75rem 1rem;
    }
  }
  &.play-voice-over-modal {
    .modal-body {
      padding: 1.25rem;
    }
  }
}
