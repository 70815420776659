.switch-card-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.875rem;
  .switch-card {
    max-width: 19.538rem;
    height: 5.875rem;
    flex: 1 0 calc((100% - 3.75rem) / 3);
    @media (max-width: 1500px) {
      flex: 1 0 calc((100% - 3.75rem) / 2);
      max-width: 22.438rem;
    }
    @media (max-width: 1199px) {
      flex: 1 0 calc((100% - 3.75rem) / 1);
      max-width: 100%;
    }
  }
  @media (max-width: 991px) {
    gap: 1rem;
  }
}

.switch-card {
  display: flex;
  padding: 1rem;
  align-items: center;
  gap: 1.875rem;
  border-radius: 0.25rem;
  border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  background: var(--#{$variable-prefix}background-white);
  height: 100%;
  .switch-card-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    flex: 1;
    h4 {
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.005rem;
    }
    span {
      color: var(--#{$variable-prefix}soft-plum-gray);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.004rem;
    }
  }
}

.switch-card-sm {
  max-width: 14rem;
  padding: 0.313rem 1rem;
  gap: 1.25rem;
  .switch-card-info {
    h4 {
      font-size: 0.875rem;
    }
  }
  .custom-switch-slider {
    width: 2.875rem;
    height: 1.5rem;
    .circle {
      width: 0.875rem;
      height: 0.875rem;
    }
  }
}
