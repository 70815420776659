.offcanvas-end {
  width: 32.75rem;
  border-left-color: #dee2e6;
}

.w-40 {
  width: 40%;
}

.custom-offcanvas {
  .offcanvas-title {
    margin: 0;
    color: var(--#{$variable-prefix}charcoal-gray);
    // font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.015rem;
  }
  .offcanvas-header {
    padding: 1.25rem 2.688rem;
  }
  .offcanvas-body {
    height: 9.375rem;
    padding: 1.25rem 2.688rem;
  }

  .offcanvas-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: right;
    padding: 1.25rem 2.688rem;
    margin-top: 0.438rem;
    gap: 0.5rem;
    .bg-primary-btn {
      padding: 0.7rem 1rem;
    }
    .outline-primary-btn {
      &:hover,
      &:focus {
        background-color: var(--#{$variable-prefix}primary-color) !important;
        border-color: var(--#{$variable-prefix}primary-color);
      }
    }
  }

  .register-warning {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    border-radius: 0.25rem;
    border: 0.125rem solid #f7d386;
    background: #fef8eb;
    .register-icon-container {
      width: 100%;
      display: flex;
      align-items: center;
      gap: .8rem;
    }

    h2 {
      color: #515151;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0.25rem;
    }
    p {
      color: #515151;
      font-size: 0.813rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.register-off-canvas {
  width: 40%;
  @media (max-width: 1399px) {
    width: 50%;
  }
  @media (max-width: 1199px) {
    width: 60%;
  }
  @media (max-width: 991px) {
    width: 80%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
  .offcanvas-header {
    padding-bottom: 0;
    @media (max-width: 767px) {
      padding: 1.25rem 1.25rem 0;
    }
    button {
      display: none;
    }
    .btn-close {
      display: none;
    }
  }
  .offcanvas-body {
    @media (max-width: 767px) {
      padding: 1.25rem;
    }
    .login-page-form {
      max-width: unset;
    }
  }
}

.plans-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  h4 {
    margin: 0;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.015rem;
  }
}

.off-canvas-register {
  padding: unset;
}
.user-status {
  .action-status {
    &::before {
      top: 50%;
    }
  }
}
