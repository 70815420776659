.data-not-found-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 8.25rem);
  max-height: calc(100vh - 8.25rem);
}
.cluster-table {
  min-width: calc(100vw - 10.25rem);
  max-width: calc(100vw - 10.25rem);
}
.invoice-table-data-not-found {
  min-width: calc(100vw);
  max-width: calc(100vw - 25rem);
}
.superadmin-data-not-found {
  width: 100vw;
  min-height: unset;
  max-height: unset;
  margin-top: 2rem;
}
.data-not-found-container {
  min-width: calc(100vw - 20.5rem);
  max-width: calc(100vw - 20.5rem);
  min-height: unset;
  max-height: unset;
  margin-top: 2rem;
}

.data-not-found {
  display: flex;
  width: 15.125rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  .icon-wrap {
    color: var(--#{$variable-prefix}bg-cloud-gray);
  }
  .title-wrap {
    font-family: Inter;
    color: var(--#{$variable-prefix}dark-navy);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
  }
}
.assets-data-not-found {
  width: 100%;
  min-height: unset;
}
