.create-new-project-bg-color {
  background-color: #f7f7f7;
}

.create-new-project-container {
  display: flex;
  padding: 1.5rem 1.25rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  flex: 1 0 0;
  align-self: stretch;
}

.create-new-title {
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
}

.steps-holder {
  display: flex;
  align-items: flex-start;
  gap: 3.5rem;
  flex: 1 0 0;
  align-self: stretch;
  flex-wrap: wrap;
  @media (max-width: 1299px) {
    gap: 2rem;
  }
}

.step-sidebar {
  @media (max-width: 1299px) {
    display: flex;
    flex-wrap: wrap;
  }
  a {
    display: flex;
    padding: 0.625rem 0.75rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.25rem;
    &.current-step {
      background-color: #f0edf6;
      .step-text {
        color: #000;
      }
      .step-circle {
        background-color: #f0edf6;
        border-color: #6750a4;
        color: #6750a4;
      }
    }
    &.completed-step {
      .step-circle {
        color: #d9d9d9;
        border-color: #6750a4;
        background-color: #6750a4;
      }
    }
  }

  .step-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    gap: 0.5rem;
    border-radius: 50%;
    border: 0.063rem solid #d9d9d9;
    background: #fff;
    color: #8d8d8d;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }

  .step-text {
    color: #8d8d8d;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
}

.step-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.9rem;
  max-width: 54.75rem;
  @media (max-width: 1299px) {
    width: 100%;
    max-width: 100%;
  }
}

.step-title {
  color: #585858;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;

  span {
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

.project-info-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.375rem;
  align-self: stretch;
  h5 {
    color: #515151;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem;
    span {
      color: #585858;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.625rem;
    }
  }
  span {
    color: #79747e;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.00438rem;
  }
}

.add-project-detail-form {
  width: 100%;
}

.add-category-wrap {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .separater {
    display: flex;
    align-items: center;
    width: 1.188rem;
    color: #585858;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .dropdown-select {
    flex: 1;
    @media (max-width: 767px) {
      width: 100%;
      flex: auto;
    }
  }
  .outline-primary-btn {
    padding: 1rem 1.875rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.category-divider {
  width: 100%;
  height: 0.063rem;
  background-color: #d9d9d9;
}

.brand-title {
  margin-bottom: 1.2rem;
  color: var(--#{$variable-prefix}charcoal-gray);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.005rem;
}

.typography-row {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
  flex-wrap: wrap;
  .background-style {
    flex: 1 0 0;
  }
  .font-style-holder {
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .font-style-content {
    display: flex;
    gap: 1rem;
    @media (max-width: 575px) {
      width: 100%;
      flex-wrap: wrap;
      gap: 1.5rem;
    }
    .custom-select-dropdown {
      @media (max-width: 767px) {
        flex: 1;
      }
      @media (max-width: 575px) {
        width: 100%;
        flex: auto;
      }
    }
    .custom-form-floating {
      @media (max-width: 575px) {
        margin-bottom: 0;
      }
    }
  }
}

.project-compose-selection {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
  flex-wrap: wrap;
  .project-compose-item {
    max-width: 17.25rem;
    min-width: 17.25rem;
    @media (max-width: 767px) {
      max-width: 100%;
      min-width: 100%;
    }
    .project-compose-btn {
      svg {
        color: #3e3e3e;
      }
    }
    &:hover {
      .project-compose-btn {
        background-color: #f0edf6;
        svg {
          color: #6750a4;
        }
      }
    }
    &.active {
      border: 1px solid #6750a4;
      box-shadow:
        0 -4px 8px 0 var(--colors-greys-100, #e3e3e3),
        0 20px 24px -4px var(--colors-greys-050, #f1f1f1),
        0 8px 8px -4px var(--colors-greys-100, #e3e3e3);
      p {
        color: #6750a4;
      }
      .project-compose-btn {
        background-color: #f0edf6;
        svg {
          color: #6750a4;
        }
      }
      .round-badge {
        background-color: #6750a4;
      }
    }
  }
}

.floating-border-color {
  &.tag-input-container {
    border-color: #d9d9d9;
  }
  &.custom-color-input {
    border-color: #d9d9d9;
    background-color: #fff;
    .custom-color-input {
      input {
        border-color: #d9d9d9;
      }
    }
  }
  input {
    border-color: #d9d9d9;
  }

  .form-control,
  textarea {
    border-color: #d9d9d9;
  }
  .caret-icon {
    svg {
      path {
        stroke: #8d8d8d;
      }
    }
  }
}

.custom-upload-label {
  border: 0.063rem dashed #d9d9d9;
  background: #fff;
  .upload-text,
  .upload-description {
    color: #000;
  }
}

.current-view-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
}

.upload-format-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.format-text {
  color: #585858;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.875rem;
}

.form-texarea-holder {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
}

.add-typography-btn {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  color: #6750a4;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: var(--core-fonts-line-height-body-button, 1.25rem);
  svg {
    width: 1rem;
    height: 1rem;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.segment-title-section {
  display: flex;
  padding-bottom: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  h4 {
    color: #515151;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
}

.uploaded-media {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .img-wrap {
    width: 6rem;
    height: 8.3rem;
    display: flex;
    padding: 1.125rem 1rem;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0.25rem;
    border: 0.063rem solid #d9d9d9;
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.25rem;
    }
  }
}

.processing-method-wrap {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.action-card {
  display: flex;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border: 0.063rem solid transparent;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow:
    0 -1px 3px 0 #f1f1f1,
    0 1px 2px 0 #f1f1f1;
  &.active {
    border-color: #6750a4;
    .rounded-icon {
      background-color: #f0edf6;
      color: #6750a4;
    }
    .action-text {
      color: #6750a4;
    }
  }
  .rounded-icon {
    display: flex;
    padding: 0.5rem;
    align-items: flex-start;
    border-radius: 1.75rem;
    background-color: #f7f7f7;
    color: #3e3e3e;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  .action-text {
    color: #585858;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
  .action-icon {
    svg {
      width: 1.1rem;
      height: 1rem;
      color: #6750a4;
    }
  }
}

.add-category-holder {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
  .btn-wrap {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .btn {
      font-size: 1rem;
      line-height: 1;
      padding: 0.8rem 1.875rem;
    }
  }
}

.upload-half-wrap {
  width: 100%;
  @media (max-width: 767px) {
    .row {
      gap: 1.9rem;
    }
  }
}
