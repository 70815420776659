.video-setting-wrapper {
  display: flex;
  justify-content: space-between;
  margin-right: 6.875rem;
  min-height: calc(100vh - 17.813rem);
  max-height: calc(100vh - 17.813rem);
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-color: auto;
  scrollbar-width: auto;
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  .document-sidebar {
    height: 100;
    margin: 0;
    position: fixed;
    right: 0;
  }
}

.video-container {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 17.813rem);
  max-height: calc(100vh - 17.813rem);
  overflow-y: auto;
}

.custom-sidebar {
  width: 17.5rem;
  transition: width 0.3s;
  background-color: #fff;
  margin: 1.25rem 0 1.25rem 1.25rem;
  border-radius: 0.5rem;
  box-shadow:
    0 -1px 3px 0 #f1f1f1,
    0 1px 2px 0 #f1f1f1;
  transition: opacity 0.3s ease-in-out;
  @media (max-width: 1439px) {
    position: fixed;
    height: calc(100% - 10.313rem);
    top: 8.125rem;
    bottom: 0;
    z-index: 3;
  }
  &.open {
    opacity: 1;
  }
  &.closed {
    width: 0;
    overflow: hidden;
    margin-left: 0;
    padding: 0;
    opacity: 0;
  }
}

.video-script-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  padding: 0 1.25rem 0;
  @media (max-width: 1439px) {
    flex-direction: column;
  }
}

.video-content-left {
  width: 100%;
  background-color: #f7f7f7;
  padding: 1.5rem 1.25rem 0 0;
  border-right: 0.063rem solid #d9d9d9;
  img.dashImg {
    max-width: 100%;
  }
  @media (max-width: 1439px) {
    border-right: none;
    padding-right: 0;
  }
  .slide-title {
    display: flex;
  }
}

.video-content-right {
  width: 100%;
  // max-width: 45.5rem;
  padding: 1.5rem 0 0 1.25rem;
}

.custom-sidebar-content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .custom-accordion-header {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 0;
      overflow: hidden;

      text-overflow: ellipsis;
      white-space: nowrap;

      background-color: transparent;
      border: none;
      padding: 0;
      div.text{
        display: flex;
        div{
          display: flex;
          align-items: center;
          i{
            margin-left:10px;
            cursor: pointer;
            &.disabled{
              opacity: 0.3;
              cursor:crosshair;
              pointer-events: none;
            }
          }
        }
      }
      span.editable,
      input.editable {
        display: flex;
        color: #6750a4;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5rem;
        
        margin-right: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: none;
        border-bottom: solid 2px white;
        display: flex;
        b{min-width: 4rem; display: block;
        text-overflow: ellipsis;
    display: block;
    width: 160px;
    overflow: hidden;
    text-align: left;

        }
       
        &.editmode {
          overflow: inherit;
          text-overflow: initial;
          white-space: normal;
        }
      }
       input.editable {
        width:8.5rem
       }
      .show-delete {
        visibility: hidden;
      }
    }
    &:hover {
      .show-delete {
        visibility: visible;
      }
    }
  }
}

.custom-sidebar-footer {
  display: flex;
  padding: 1.25rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  .outline-primary-btn {
    font-size: 1rem;
    line-height: 1;
    font-weight: 400;
    padding: 0.625rem;
    svg {
      width: 1rem;
    }
  }
}

.segment-content {
  .segment {
    cursor: pointer;
  }
  .editable {
    cursor: text;
  }
  .segment,
  .editable {
    display: flex;
    padding: 0 .5rem;
    align-items: center;
    align-self: stretch;
    background: #f0edf6;
    overflow: hidden;
    color: #01003e;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    margin: 0.5rem 0;
    position: relative;
    width: 100%;
    justify-content: space-between;
    border: none;
    .text {
      display: flex;
      div{
        display: flex;
        align-items: center;
        i{
          padding-left:5px;
          cursor: pointer;
          &.disabled{
            opacity: 0.3;
            cursor:crosshair;
            pointer-events: none;
          }
        }
      }
    }
    &:hover {
      .delete-button {
        visibility: visible;
      }
    }
    &.active {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0.125rem;
        height: 100%;
        align-self: stretch;
        border-radius: 0 0.0625rem 0.0625rem 0;
        background: #6750a4;
      }
    }
    span {
      border-bottom: solid 2px #f0edf6;
      display: inline-block;
     
      &.editmode {
        overflow: inherit;
        text-overflow: initial;
        white-space: normal;
      }
      i{
        margin-left:5px;
        display: inline-block;
      }
    }
    .delete-button {
      border: none;
      outline: none;
      background-color: transparent;
      color: #6750a4;
      visibility: hidden;
    }
  }
  .ouline-none-btn {
    width: 100%;
    justify-content:left;
    svg {
      width: 1rem;
    }
  }
}

.custom-accordion-wrap {
  width: 100%;
  height: calc(100vh - 395px);
  overflow-y: auto;
  padding: 0 0.5rem;
  @media (max-width: 1439px) {
    height: 100%;
  }
}

.module-btn {
  display: flex;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: 0.063rem solid #585858;
  background-color: transparent;
  color: #585858;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  white-space: nowrap;
  &.active {
    color: #fff;
    border-color: #6750a4;
    background-color: #6750a4;
  }
}

.segment-header-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.segment-info-wrap {
  display: flex;
  justify-content: flex-end;
  flex: auto;
  gap: 1rem;

  .ouline-none-btn {
    font-weight: 600;
    padding: 0;
    svg {
      width: 1rem;
    }
  }
}

.segement-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  h4 {
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    @media (max-width: 1600px) {
      font-size: 1.25rem;
    }
  }
  span {
    display: block;
    width: 0.094rem;
    height: 1.4rem;
    background-color: #8d8d8d;
  }
  h5,input[type=text] {
    color: var(--Text-secondary, #585858);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
    border:none;
    background-color: transparent;
    display: flex;
    @media (max-width: 1600px) {
      font-size: 1.25rem;
    }
    min-width:200px;
    b{
      display: block;
      min-width:50px;
 
    }
    i{
      margin-left:1rem;
    }
  }
  .text{
    display: flex;
    input[type=text] {
      border-bottom:dotted 2px #ccc;
    }
    i{
      cursor: pointer;
    }
    i.disabled{
      opacity: 0.3;
      cursor:crosshair;
      pointer-events: none;
    }
  }
}

.video-segment-fotter {
  background-color: #fff;
  box-shadow:
    0 -1px 3px 0 #f1f1f1,
    0 1px 2px 0 #f1f1f1;
  position: relative;
  z-index: 1;
}

.required-text {
  display: flex;
  margin-top: 0.25rem;
  color: #585858;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  span {
    display: inline-block;
    color: #d30000;
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
}

.voiceover-dropdown {
  @media (max-width: 991px) {
    margin-top: 0;
  }
  .common-dropdown-filter {
    gap: 0.625rem;
    span {
      display: inline-block;
      color: #585858;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
  .common-dropdown-menu {
    .dropdown-item {
      font-size: 0.675rem;
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

.voice-player {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 1rem 1rem;
  border-radius: 0.25rem;
  background: #f7f7f7;
  .control-wrap {
    display: flex;
    gap: 1rem;
    align-items: center;
    .play-icon {
      color: #585858;
      svg {
        width: 0.95rem;
        height: 0.95rem;
      }
      &:hover {
        color: #6750a4;
      }
    }
    .play-time {
      color: #585858;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: var(--core-fonts-line-height-body-tiny, 0.875rem);
    }
  }
  .progress-wrap {
    padding: 0;
    flex: 1;
    .progress {
      height: 1rem;
      border-radius: 1rem;
      padding: 3px;
    }
  }
  .voice-control-icon {
    cursor: pointer;
  }
}
