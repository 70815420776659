.header-navbar {
  background-color: var(--#{$variable-prefix}background-white);
  border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  padding: 0.75rem 1.25rem;
  min-height: 4.125rem;
  gap: 0.5rem;
  .login-page-logo {
    margin-bottom: unset;
  }
  .features-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}

.navbar-toggler-wrap {
  @media (max-width: 991px) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.user-info-holder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 991px) {
    margin-top: 1.25rem;
    display: block;
    .default-add-btn {
      width: 100%;
      display: inline-flex;
      justify-content: flex-start;
    }
  }
}

.circle-wrap {
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  overflow: hidden;
  color: var(--#{$variable-prefix}white-color);
  border-radius: 6.25rem;
  background-color: var(--#{$variable-prefix}primary-color);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @include border-radius($border-radius-full);
  img {
    width: $w-100;
    aspect-ratio: 1;
    object-fit: cover;
  }
  svg {
    width: $w-100;
    aspect-ratio: 1;
  }
}

// create project
.secondary-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  border-bottom: 0.063rem solid var(--#{$variable-prefix}gentle-sky);
  padding: 0.75rem 2.5rem;
  .custom-search-form {
    min-width: 12.5rem;
    margin: 0;
    flex: 1;
  }
}

.secondary-sticky-header {
  position: sticky;
  top: 4.125rem;
  z-index: 8;
  background-color: var(--#{$variable-prefix}background-white);
  transition: all 0.3s ease-in-out;
}

.create-project-wrapper {
  padding: 2.5rem 2.5rem 0;
  min-height: calc(100vh - 12.313rem);
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: 767px) {
    padding: 2rem 1rem 0;
  }
}

.common-dropdown-filter {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  .dropdown-text {
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.004rem;
  }
  .dropdown-icon {
    display: flex;
    align-items: baseline;
    gap: 0.625rem;
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-weight: 400;
    svg {
      width: 0.875rem;
    }
    @extend .dropdown-text;
  }
}

.common-dropdown-filter-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1.875rem;
  position: relative;
  padding-left: 1rem;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 0.063rem;
    height: 2.313rem;
    background-color: var(--#{$variable-prefix}gentle-sky);
  }
  .show {
    .dropdown-icon {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .common-dropdown-menu {
    max-height: 15.438rem;
    overflow-y: auto;
    overflow-x: hidden;
    &.dropdown-menu {
      margin-top: 1.313rem;
    }
  }
}
.client-list-dropdown {
  padding-left: 0;
  &::before {
    display: none;
  }
}
.remaining-feature {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  .circular-progress-bar {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    svg {
      width: 100%;
      height: 100%;
      rotate: -90deg;
      circle {
        fill: none;
        stroke-width: 4;
      }
      .outer-circle {
        &.pending {
          stroke: #ffe0bb;
        }
        &.success {
          stroke: #cff0da;
        }
        &.failed {
          stroke: #ffcfcf;
        }
      }
      .progress-circle {
        transition: stroke-dashoffset 0.5s ease;
        &.pending {
          stroke: #ff8a00;
        }
        &.success {
          stroke: #00aa3a;
        }
        &.failed {
          stroke: #d30000;
        }
      }
    }
    .progress-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.75rem;
      &.pending {
        color: #ff8a00;
      }
      &.success {
        color: #00aa3a;
      }
      &.failed {
        color: #d30000;
      }
    }
    .progress-text.four-chars {
      margin-left: 0.063rem;
    }
  }
  .title {
    display: flex;
    flex-direction: column;
    color: #515151;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    .pending {
      color: #ff8a00;
    }
    .success {
      color: #00aa3a;
    }
    .failed {
      color: #d30000;
    }
  }
}

.pos-left {
  position: relative;
  left: 0;
}
