.common-card {
  display: flex;
  padding: 0.625rem;
  flex-direction: column;
  gap: 0.25rem;
  align-self: stretch;
  border-radius: 0.25rem;
  background: var(--#{$variable-prefix}bg-light-aluminum);
  border: 0.063rem solid transparent;
  margin-bottom: 0.625rem;
  &:last-child {
    margin-bottom: 0;
  }
  cursor: pointer;
  &:hover,
  &.active {
    border-color: var(--#{$variable-prefix}primary-color);
    background-color: #faf8ff;
  }
  &.active {
    .common-card-arrow-icon {
      border-color: #6750a4;
      background-color: #6750a4;
      color: #fff;
    }
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    background-color: transparent;
    border: 0;
    padding: 0;
    .card-title {
      margin: 0;
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      letter-spacing: 0.004rem;
    }
  }
  .card-body {
    padding: 1rem 0 0;
    .card-text {
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0.004rem;
    }
    .media-container{
      display: flex;
      align-items: center;
      gap: 0.625rem;
      flex-wrap: wrap;
    }
  }
}

.common-card-arrow-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.common-card-arrow-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border: 0.063rem solid #8e8e8e;
  padding: 0.188rem;
  svg {
    width: 0.766rem;
    height: 0.438rem;
  }
}
