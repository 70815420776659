.pagination-wrapper {
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  background-color: var(--#{$variable-prefix}background-white);
  padding: 1.25rem 2.5rem;
  text-align: center;
  z-index: 9;
  @media (max-width: 849px) {
    justify-content: center;
  }
}

.custom-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;

  .disabled {
    .custom-pagination-control {
      pointer-events: none;
      color: var(--#{$variable-prefix}soft-lilac);
      cursor: not-allowed;
    }
  }

  li {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.75rem;

    .custom-pagination-control {
      display: flex;
      gap: 0.75rem;
      color: var(--#{$variable-prefix}primary-color);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.009rem;
      cursor: pointer;
    }

    input {
      width: 1.75rem;
      height: 1.75rem;
      display: flex;
      padding: 0.063rem;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 0.5rem;
      border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
      color: var(--#{$variable-prefix}soft-plum-gray);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.009rem;
      appearance: none;
    }

    span {
      display: inline;
      color: var(--#{$variable-prefix}soft-plum-gray);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.009rem;
    }
  }
}

/* page-pagination */
.page-pagination {
  display: flex;
  align-items: center;
  color: var(--#{$variable-prefix}nightshade-gray);
  gap: 0.75rem;

  span {
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.009rem;
  }

  .per-page {
    display: flex;
    padding: 0.375rem 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.009rem;
    position: relative;
    cursor: pointer;

    .dropdown-list {
      position: absolute;
      bottom: 100%;
      left: 0;
      background-color: var(--#{$variable-prefix}background-white);
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
      border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
      margin: 0 0 0.188rem -0.063rem;
      display: none;
      bottom: 1.688rem;
      z-index: 1;
      li {
        padding: 0.625rem;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: var(--#{$variable-prefix}bg-cloud-gray);
        }
      }
    }

    &:hover {
      .dropdown-list {
        display: block;
      }
    }
  }
}

.common-pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  margin-top: 1.25rem;
}

.common-pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  .pagination-prev,
  .pagination-next {
    border: none;
    background-color: transparent;
  }

  li {
    margin: 0 0.25rem;

    .pagination-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 2.25rem;
      height: 2.25rem;
      border: 0.063rem solid #8d8d8d;
      border-radius: 0.25rem;
      background-color: #fff;
      color: #585858;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem;
      cursor: pointer;
      transition:
        background-color 0.3s,
        color 0.3s;

      &.active {
        background-color: #01003e;
        border-color: #01003e;
        color: #fff;
      }

      &:hover:not(.active) {
        background-color: #f0f0f0;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
        border: none;
      }
    }

    &.ellipsis {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.25rem;
      height: 2.25rem;
      color: #01003e;
      font-size: 1rem;
    }
  }
}
