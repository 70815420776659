.pb-tbl-blk {
  .render-table {
    @include border-radius(0.125rem);
    margin: 0.5rem 0;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 2fr !important;
    grid-auto-flow: row dense;
    grid-auto-rows: minmax(2.5rem, auto);
    grid-gap: 0.625rem;
    background: var(--#{$variable-prefix}bg-pale-silver);
    border: 0.031rem solid rgba(var(--#{$variable-prefix}chill-gray), 0.5);
  }
  .render-list-table {
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 2.5fr !important;
  }
  .admin-render-list {
    grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr 2.5fr !important;
  }
}

.errorlog-list {
  th {
    width: 10rem;
  }
}
