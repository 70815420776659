.custom-upload-container {
  display: flex;
  padding: 1.875rem 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 0.063rem solid #8e8e8e;
  background-color: #d9d9d9;
  color: #393939;
  cursor: pointer;
  &:hover {
    background-color: #6750a4;
    color: #fff;
    .upload-text,
    .upload-description {
      color: #fff;
    }
  }

  .upload-icon {
    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .upload-text {
    color: #393939;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
  }

  .upload-description {
    color: #393939;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
  }
}

.image-file-updated-size {
  min-height: auto;
  height: 8.5rem;
  &.new-selected-height {
    height: 7.5rem;
  }
}
