.create-modal {
  max-width: 60%;
  .modal-body {
    padding: 1.563rem 3.125rem;
    @media (max-width: 767px) {
      padding: 0.938rem;
    }
  }
  @media (max-width: 991px) {
    max-width: 90%;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
}
.modal-close {
  font-size: 1.75rem;
  font-weight: 300;
  color: var(--#{$variable-prefix}nightshade-gray);
  line-height: 1.875rem;
  cursor: pointer;
  &:hover {
    color: var(--#{$variable-prefix}steel-gray);
  }
}
.modal-caption {
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.438rem;
  @media (max-width: 767px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}
.modal-subcaption {
  font-size: 1rem;
  line-height: 1.75rem;
  color: $panel-grey-txt;
  @media (max-width: 767px) {
    font-size: 0.813rem;
    line-height: 1.125rem;
  }
}
.edit-brand-modal {
  max-width: 75%;
  .modal-body {
    padding: 1.563rem 3.125rem;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0.938rem;
  }
}
.modal-close svg {
  fill: var(--#{$variable-prefix}nightshade-gray);
}
.custom-modal .modal-content {
  overflow: hidden;
  @include border-radius(0.625rem);
  background: none;
}

.temp-modal {
  max-width: 75%;
  .modal-body {
    padding: 0.938rem;
    @media (max-width: 767px) {
      padding: 0.938rem;
    }
  }
  @media (max-width: 991px) {
    max-width: 90%;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
}

// 15-11

// edit-modal
.edit-modal {
  max-width: 75%;
  @media screen and (min-width: 1200px) {
    max-width: 62.5rem;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    padding: 0.938rem;
    margin: 0;
  }
  .modal-body {
    padding: 1.5rem;
  }
  .img-block {
    flex: 1;
    margin-right: 1.75rem;
    @media screen and (max-width: 991px) {
      margin-right: 0;
      width: 100%;
      margin-bottom: 0.938rem;
    }
    img {
      width: 100%;
      max-height: 20rem;
    }
  }
  .img-more {
    flex: 0 0 20.938rem;
    max-width: 20.938rem;
    @media screen and (max-width: 991px) {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
    .footer-btn button {
      min-width: 8.375rem;
      min-height: 2.5rem;
    }
  }
  .label-block {
    flex: 0 0 4.5rem;
    max-width: 4.5rem;
    p {
      color: var(--#{$variable-prefix}light-gray-text);
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  .field-block {
    flex: 1;
    svg {
      stroke: var(--#{$variable-prefix}light-gray-text);
    }
    small {
      color: var(--#{$variable-prefix}light-gray-text);
      font-size: 0.625rem;
      line-height: 1rem;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}

.edit-modal.dark {
  .label-block p,
  .field-block small {
    color: $dark-grey-font;
  }
  .custom-range-1 .output,
  .custom-range .output {
    background: transparent;
    color: $dark-grey-font;
  }
  .field-block svg {
    stroke: $dark-grey-font;
  }
  .editor-btn {
    border: 0.031rem solid var(--#{$variable-prefix}gunmetal-gray);
    background: transparent;
  }
  .divider {
    background: var(--#{$variable-prefix}gunmetal-gray);
  }
}

// view-modal
.view-modal {
  max-width: 75%;
  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    padding: 0.938rem;
    margin: 0;
  }
  .modal-body {
    padding: 0;
  }
  .img-block {
    img {
      width: 100%;
    }
  }
}

// audio-modal
.audio-modal {
  position: fixed;
  z-index: 1060; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  .modal-overlay {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 0%, 0.5);
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s linear 0.3s,
      opacity 0.3s;
  }
  .modal-wrapper {
    position: absolute;
    z-index: 9999;
    top: 6em;
    left: 50%;
    width: 32em;
    margin-left: -16em;
    background-color: var(--#{$variable-prefix}background-white);
    box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
    border-radius: 0.625rem;
    @media screen and (max-width: 767px) {
      width: 100%;
      left: 0;
      right: 0;
      margin: 0;
    }
  }
  .modal-content {
    .img-block {
      z-index: 1061;
      img {
        width: 100%;
      }
    }
    .audio-sec {
      position: relative;
      margin: 0 0 0.938rem;
      .play-audio {
        position: absolute;
        left: 0;
        top: 0.75rem;
      }
      .pause-btn {
        position: absolute;
        left: 0;
        top: 0.75rem;
      }
      .sound-audio {
        position: absolute;
        right: 0;
        top: 0.75rem;
      }
      svg {
        fill: var(--#{$variable-prefix}light-gray-text);
      }
      .audio-wrap {
        display: block;
        margin: 0 2.188rem;
      }
    }
  }
  .footer-btn {
    button {
      text-transform: uppercase;
      min-height: 2.5rem;
      min-width: 8.375rem;
      svg {
        stroke: var(--#{$variable-prefix}light-gray-text);
      }
      &.upload-btn {
        svg {
          margin-right: 0.375rem;
        }
        span {
          padding-left: 0.375rem;
          border-left: 0.063rem solid var(--#{$variable-prefix}neutral-gray);
        }
      }
    }
  }
  .modal-transition {
    transition: all 0.3s 0.12s;
    transform: translateY(-10%);
    opacity: 0;
  }
  &.is-visible {
    visibility: visible;
    .modal-overlay {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
    }
    .modal-transition {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
.audio-upload {
  top: 50%;
  left: 50%;
  visibility: unset !important;
  transform: translate(-50%, -50%) !important;
  .img-block {
    video {
      z-index: 1061;
    }
  }
}

.audio-modal.dark {
  .modal-wrapper {
    background: var(--#{$variable-prefix}obsidian-black);
  }
  .modal-content {
    background: var(--#{$variable-prefix}obsidian-black);
    .audio-sec svg {
      fill: $dark-grey-font;
    }
  }
  .time-duration {
    color: $dark-grey-font;
  }
  .upload-btn {
    background: transparent !important;
    color: $dark-grey-font;
  }
  .btn-outline {
    background: var(--#{$variable-prefix}gunmetal-gray);
    border: 0.063rem solid var(--#{$variable-prefix}gunmetal-gray);
    color: $dark-grey-font;
  }
  .footer-btn button.upload-btn span {
    border-left: 0.063rem solid $dark-grey-font;
  }
  .footer-btn button svg {
    stroke: $dark-grey-font;
  }
}

// video::-webkit-media-controls-panel{
//      display: none !important;
//      opacity: 0 !important;
// }

// 1
.toggle-wrap {
  padding: 0.625rem 0.938rem;
  position: relative;
  cursor: pointer;
  float: left;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: none;
  @media screen and (max-width: 991px) {
    display: block;
  }
}
.toggle-bar,
.toggle-bar::before,
.toggle-bar::after,
.toggle-wrap.active .toggle-bar,
.toggle-wrap.active .toggle-bar::before,
.toggle-wrap.active .toggle-bar::after {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.toggle-bar {
  width: 1.625rem;
  margin: 0.625rem 0;
  position: relative;
  border-top: 0.125rem solid var(--#{$variable-prefix}graphite-gray);
  display: block;
}
.toggle-bar::before,
.toggle-bar::after {
  content: '';
  display: block;
  background: var(--#{$variable-prefix}graphite-gray);
  height: 0.125rem;
  width: 1.625rem;
  position: absolute;
  top: -0.75rem;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -ms-transform-origin: 13%;
  -webkit-transform-origin: 13%;
  transform-origin: 13%;
}
.toggle-bar::after {
  top: 0.5rem;
}
.toggle-wrap.active .toggle-bar {
  border-top: 0.125rem solid transparent;
}
.toggle-wrap.active .toggle-bar::before {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -0.375rem;
}
.toggle-wrap.active .toggle-bar::after {
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.close-priview {
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  cursor: pointer;
  background: var(--#{$variable-prefix}background-white);
  border-radius: 0.313rem;
  svg {
    fill: var(--#{$variable-prefix}dark-navy);
  }
}

.replace-modal {
  max-width: 75%;
  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    padding: 0.938rem;
    margin: 0;
  }
  .modal-content {
    // background: $dark-grey-bg;
    background: var(--#{$variable-prefix}background-white);
    border-radius: 0.375rem !important;
  }
  .replace-img {
    position: relative;
    border-radius: 0.375rem;
    overflow: hidden;
    max-height: 7.875rem;
    .icon-wrap {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      background: #5161708f;
      -webkit-transition: all 0.4s ease-in-out 0s;
      -moz-transition: all 0.4s ease-in-out 0s;
      transition: all 0.4s ease-in-out 0s;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-right: 0;
        cursor: pointer;
        &.img-view {
          background: rgba(0, 0, 0, 0.6);
          opacity: 0.6;
          box-shadow:
            inset 0.35rem -0.35rem 0.35rem rgba(53, 106, 176, 0.146),
            inset -0.35rem 0.35rem 0.35rem rgba(255, 255, 255, 0.146);
          backdrop-filter: blur(0.315rem);
          border-radius: 0.375rem 0 0 0.375rem;
          &:hover {
            background: rgba(70, 139, 232, 0.806);
          }
        }
        &.img-edit {
          background: rgba(0, 0, 0, 0.6);
          opacity: 0.6;
          box-shadow:
            inset 0.35rem -0.35rem 0.35rem rgba(53, 106, 176, 0.146),
            inset -0.35rem 0.35rem 0.35rem rgba(255, 255, 255, 0.146);
          backdrop-filter: blur(0.315rem);
          border-radius: 0 0.375rem 0.375rem 0;
          &:hover {
            background: rgba(70, 139, 232, 0.806);
          }
        }
        // svg {
        //   fill: var(--#{$variable-prefix}background-white);
        // }
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      .icon-wrap {
        opacity: 1;
      }
    }
  }
  .srch-temp-blk {
    position: relative;
    p {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      text-align: center;
      color: var(--#{$variable-prefix}light-gray-text);
      position: absolute;
      right: 0;
      margin-bottom: 0;
      @media screen and (max-width: 1199px) {
        font-size: 0.75rem;
      }
      @media screen and (max-width: 767px) {
        position: relative;
      }
      a {
        font-weight: 600;
        text-decoration-line: underline;
        color: $theme-blue;
      }
    }
  }
}

#view-img {
  z-index: 9999 !important;
}

.dark {
  .toggle-bar {
    border-top: 0.125rem solid var(--#{$variable-prefix}silver-gray);
  }
  .toggle-bar::before,
  .toggle-bar::after {
    background: var(--#{$variable-prefix}silver-gray);
  }
}
.styles-modal {
  @media (min-width: 992px) {
    max-width: 41.875rem !important;
  }
  .modal-body {
    padding: 2.188rem;
  }
  .style-wraper {
    .style-modal-head {
      margin-bottom: 2.188rem;
      h3 {
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.438rem;
        color: var(--#{$variable-prefix}light-aluminum);
        margin-bottom: 1.25rem;
      }
      p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.75rem;
        color: var(--#{$variable-prefix}silver-gray);
        margin-bottom: 0;
      }
    }
    .style-inner {
      max-width: 24.375rem;
      margin: auto;
      .cm-color-picker {
        margin-bottom: 1.875rem;
        margin-top: 0;
      }
      .color-picker .color-palette {
        margin: 0.625rem auto;
      }
      .cm-color-picker .edit-label {
        font-weight: 400;
        color: var(--#{$variable-prefix}silver-gray);
      }
      .cm-style-upload-holder {
        border: 0.063rem dashed var(--#{$variable-prefix}silver-gray);
        padding: 1.875rem 0 2.25rem;
        min-height: 9.813rem;
        max-height: 9.813rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .cm-style-container {
          max-width: 12.5rem;
          margin: 0 auto;
        }
        .cm-brand {
          margin: 0 auto;
        }
        .cc-csv-btn .btn {
          border-radius: 0.25rem;
        }
      }
    }
    .style-modal-footer {
      .btn {
        min-width: 16.75rem;
        min-height: 2.75rem;
      }
    }
  }
}

.alert-modal {
  .modal-dialog {
    max-width: 28.75rem;
  }

  .modal-content {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    border-radius: 0.25rem;
    background: #fff;
  }

  .modal-body {
    padding: 1.25rem;
  }

  .modal-icon {
    width: 3rem;
    height: 3rem;
    margin: 0 auto 1.25rem;
    display: flex;
    padding: 0.75rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 1.5rem;
    background-color: #fff8f0;
    svg {
      width: 1.5rem;
      height: 1.5rem;
      path {
        fill: #8c5618;
      }
    }
  }
  &.delete-modals {
    .modal-dialog {
      max-width: 32rem;
    }
    .modal-info {
      text-align: center;
    }
    .modal-icon {
      background-color: #fff5f5;
      svg {
        width: 1.5rem;
        height: 1.5rem;
        path {
          fill: #d30000;
        }
      }
    }
  }

  .modal-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin: 0 0 2rem;
  }

  h4 {
    color: #000;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
  }
  p {
    color: #585858;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
  }
  .modal-buttton {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
  }
}

.cancels-btn {
  padding: 0.625rem;
  flex: 1 0 0;
  color: #8d8d8d;
  background-color: #fff;
  border: 0.063rem solid #8d8d8d;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.reset-btn {
  padding: 0.625rem;
  flex: 1 0 0;
  color: #fff8f0;
  background-color: #8c5618;
  border: 0.063rem solid #8c5618;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.delete-btn {
  flex: 1 0 0;
  padding: 0.625rem;
  color: #fff5f5;
  background-color: #871818;
  border: 0.063rem solid #871818;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}
