.editable-content {
  cursor: pointer;
  .editable-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    color: var(--#{$variable-prefix}white-color);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.005rem;
  }
  .editable-input {
    width: 100%;
    color: var(--#{$variable-prefix}white-color);
    background-color: transparent;
    border: 0;
  }
  .editable-btn {
    background-color: transparent;
    border: 0;
    padding: 0;
  }
  .edit-icon {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    margin-left: 0.313rem;
    cursor: pointer;

    &.fadeIn {
      opacity: 1;
    }

    &.fadeOut {
      opacity: 0;
    }
  }
}

.editable-border-line {
  margin-bottom: 0.5rem;
  padding: 0.625rem;
  border-left: 0.125rem solid transparent;
  &:hover,
  &.active {
    border-color: var(--#{$variable-prefix}primary-color);
  }
}

.editable-dark-text {
  border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-soft-plum-gray);
  padding-bottom: 0.375rem;
  .editable-title,
  .editable-input {
    color: var(--#{$variable-prefix}charcoal-gray);
  }
}

.draft-content {
  .editable-title {
    color: #a5a5a5;
  }
}
