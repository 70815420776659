.thank-you {
  display: flex;
  align-items: center;
  height: 100vh;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .thank-you__left {
    width: 50%;
    height: 100%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .thank-you__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
    padding: 2.5rem 70px;
    width: 50%;
    @media (max-width: 991px) {
      padding: 2.5rem;
    }
    @media (max-width: 767px) {
      width: 100%;
      padding: 1.25rem 1.25rem 2.5rem;
    }
    .thank-you-title {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.015rem;
    }
    .thank-you-subtitle {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem; /* 150% */
      letter-spacing: 0.02rem;
    }
    .subscription-details {
      border-radius: 0.25rem;
      background: #f6f6f6;
      padding: 1.875rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      .subs-title {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.009rem;
      }
      .plan,
      .features {
        color: #000;
        font-size: 1.063rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        span {
          color: #000;
          font-size: 1.063rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
    .post-script {
      color: #000;
      text-align: center;
      font-size: 1.063rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      strong {
        color: #000;
        font-size: 1.063rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}
