.templates-thumbnail-wrapper {
  width: 100%;
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  gap: 0.75rem;
  min-height: 24rem;
  max-height: 24rem;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-color: auto;
  scrollbar-width: auto;
  img.noTemplateFound{
    max-width: 100%;
    height:auto
  }
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.5rem;
    cursor: pointer;
  }
}

.templates-thumbnail {
  display: flex;
  width: 23%;
  height: 7.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.063rem solid #f7f7f7;
  position: relative;
  @media (max-width: 899px) {
    width: 47%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.active {
    border: 0.188rem solid#6750a4;
    background-color: rgba(0, 0, 0, 0.3);
    img {
      filter: brightness(0.5);
      background-color: rgba(0, 0, 0, 0.3);
    }
    .custom-checkbox {
      color: #fff;
      border-color: #6750a4;
      background-color: #6750a4;
    }
  }

  .custom-checkbox {
    width: 1.5rem;
    height: 1.5rem;
    border: 0.125rem solid #d9d9d9;
    border-radius: 0.25rem;
    padding: 0;
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  .custom-checkbox .check-icon {
    font-size: 0.875rem;
    color: #fff;
  }
}

.eye-btn {
  width: 2.125rem;
  height: 2.125rem;
  position: absolute;
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background-color: var(--#{$variable-prefix}primary-color);
  color: #fff;
}

.thumbnail-modal {
  .modal-dialog {
    max-width: 56.25rem;
  }
  .modal-content {
    border-radius: 0.375rem;
    border: 0.063rem solid #f1f1f1;
    background-color: #fff;
  }
  .img-wrap,
  .video-wrap {
    width: 100%;
    height: 30rem;
    border-radius: 0.375rem;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
