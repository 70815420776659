.voice-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 2.5rem 0.75rem 0.75rem;
  border: 0.063rem solid #aaa;
  border-radius: 0.375rem;
  max-width: 12.25rem;
  min-width: 12.25rem;
  position: relative;
  transition: all .3s ease-in-out;
  &.active,
  &:hover {
    border: 0.125rem solid #4f3f7a;
    background-color: #f9f8fc;
  }
  &.active {
    &:hover {
      border-width: 0.125rem;
    }
  }
  &:hover {
    border-width: 0.063rem;
  }
  .img-wrap {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 0.125rem;
    padding: 0.375rem;
    background-color: #e2d9ff;
    &.bg-female {
      background-color: #d9e9ff;
    }
  }
  span {
    display: block;
    font-size: 1rem;
    color: #393939;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .check-circle {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}

.check-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  background-color: #00aa3a;
  color: #fff;
}
