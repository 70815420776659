.detail-sidebar {
  display: flex;
  border-left: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  background: var(--#{$variable-prefix}background-white);
  padding: 2.5rem 1.25rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5rem;
  max-width: 10%;
  @media (max-width: 1600px) {
    max-width: 13%;
    align-items: center;
  }
}

.video-preview-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  // max-width: 59.875rem;
  // height: 100%;
  margin: 0 auto;
  padding: 1rem 2.5rem 2.5rem;
  gap: 0.625rem;
  .react-player {
    // max-height: 31.75rem;
    // min-height: 31.75rem;
    aspect-ratio: 16 / 9;
    // border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
    // padding: 0.875rem;
  }
}

.accordion-detail-title-wrap {
  .detail-project-title {
    display: block;
    color: var(--#{$variable-prefix}white-color);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.005rem;
  }

  .detail-project-sub-title {
    color: var(--#{$variable-prefix}cloud-gray);
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.004rem;
  }
}

.project-detail-thumbnail {
  display: flex;
  padding: 0.625rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.875rem;
  border: 0.063rem solid var(--#{$variable-prefix}bg-dark-gray);
  margin-bottom: 0.5rem;
  position: relative;
  cursor: pointer;
  &.active {
    border-color: var(--#{$variable-prefix}primary-color);
    background-color: var(--#{$variable-prefix}primary-color);
  }
  &:last-child {
    margin-bottom: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .count-item {
    position: absolute;
    right: 0.938rem;
    top: 0.625rem;
    color: rgba(163, 163, 163, 0.8);
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.016rem;
  }
  .thumbnail-title {
    position: absolute;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
    padding: 0.625rem;
    opacity: 0.7;
    background: var(--#{$variable-prefix}bg-dark-navy);

    p {
      margin: 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
      color: var(--#{$variable-prefix}white-color);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.005rem;
    }
  }
}

.detail-screen-wrapper {
  @media (max-width: 991px) {
    height: auto;
    flex-direction: column;
    .video-preview-wrap {
      padding: 1rem;
    }
    .detail-sidebar {
      max-width: 100%;
      flex-direction: row;
      justify-content: center;
      padding: 1rem;
    }
  }
}

.document-sidebar {
  background: #fff;
  display: flex;
  width: 6.325rem;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-left: 0.063rem solid #f1f1f1;
  margin-right: 0.7rem;

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.075rem;
    padding: 1rem 0.5rem;
    align-self: stretch;
    border-bottom: 0.063rem solid #f1f1f1;
    background-color: #fff;
    color: #585858;
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.875rem;
    transition: all;
    border: none;
    &:last-child {
      border-bottom: transparent;
    }
    &.active,
    &:focus,
    &:hover {
      border-color: #01003e;
      background-color: #01003e;
      color: #fff;
    }
    &.active:hover {
      cursor: not-allowed;
    }

    &:disabled {
      color: #d9d9d9;
      &:hover {
        color: #d9d9d9;
        border-bottom: 0.063rem solid #f1f1f1;
        background-color: #fff;
        cursor: not-allowed;
      }
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
