.navigation-tabs-container {
  display: flex;
  justify-content: space-around;
  margin: 0 0 1.5rem;
  @media (max-width: 991px) {
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.naviagation-tab {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.625rem;
  gap: 0.625rem;
  border-bottom: 0.125rem solid transparent;
  transition: background-color 0.3s ease;
  white-space: nowrap;

  &.active {
    border-color: #6750a4;
    .icon-wrap {
      background-color: #6750a4;
      color: #fff;
    }
    .label {
      color: #000;
    }
  }
  .icon-wrap {
    display: flex;
    padding: 0.375rem;
    align-items: flex-start;
    border-radius: 0.125rem;
    background-color: #d9d9d9;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  .label {
    color: #585858;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    @media (max-width: 991px) {
      font-size: 0.875rem;
    }
  }
}

.video-scroll-section {
  padding: 0 1.25rem 0 0;
  min-height: calc(100vh - 27rem);
  max-height: calc(100vh - 27rem);
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-color: auto;
  scrollbar-width: auto;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.5rem;
    cursor: pointer;
  }
}

.common-card-section {
  margin-bottom: 1.5rem;
  border-radius: 0.375rem;
  background-color: #fff;
  box-shadow:
    0 -1px 3px 0 #f1f1f1,
    0 1px 2px 0 #f1f1f1;
}

.common-card-section-header {
  display: flex;
  padding: 1rem 1.25rem;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  cursor: pointer;
  h2 {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #515151;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem;
  }
}

.common-card-section-body {
  display: flex;
  padding: 0.25rem 1.25rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.3s ease;
  .segment-title-section {
    padding: 0;
    gap: 0.5rem;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
    h4 {
      margin-bottom: 0.5rem;
    }
  }
}

.fonts-spacing-row {
  .custom-select-dropdown {
    margin-bottom: 1.5rem;
  }
  .custom-color-input {
    margin-bottom: 1.5rem;
  }
}

.apply-checkbox {
  margin: 0;
  .input-icon {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.25rem;
    border: 0.125rem solid #d9d9d9;
    background-color: #fff;
    &.checked {
      color: #fff;
      border-color: #6750a4;
      background-color: #6750a4;
    }
    svg {
      width: 1rem;
    }
  }
  .apply-all-text {
    color: #000;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
  }
}

.voice-tab-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
  @media (max-width: 899px) {
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.voice-tab-wrap {
  display: flex;
  padding: 0.25rem;
  align-items: center;
  gap: 0.125rem;
  border-radius: 0.375rem;
  background-color: #f7f7f7;
  transition: all 0.3s ease;
  .voice-tab {
    display: flex;
    padding: 0.125rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: #585858;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.3s ease;
    &.active {
      color: #fff;
      background-color: #6750a4;
    }
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}

.text-speech-content {
  width: 100%;
  .custom-form-textarea {
    margin-bottom: 1.25rem;
  }
}

.speed-preview-wrap {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.25rem;
  gap: 0.5rem;
  @media (max-width: 899px) {
    .custom-select-dropdown {
      width: 100%;
    }
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.upload-voiceover-content {
  width: 100%;
  .upload-format-wrap {
    margin-bottom: 1.25rem;
  }
}

.voiceover-audio-section {
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 0.5rem;
  border: 0.063rem solid #e4e7ec;
  background-color: #fff;
  margin-bottom: 2rem;
  .info-wrap {
    display: flex;
    padding-right: 1rem;
    align-items: center;
    gap: 0.75rem;
    flex: 1 0 0;
    .icon {
      display: flex;
      width: 2.25rem;
      height: 2.25rem;
      padding: 0.625rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      border-radius: 1.125rem;
      border: 0.063rem solid #6750a4;
      color: #6750a4;
    }
    .text {
      color: #000;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
    }
  }
  .control-wrap {
    display: flex;
    gap: 1rem;
    align-items: center;
    .play-icon {
      svg {
        width: 24px;
        height: 24px;
      }
      &:hover {
        color: #6750a4;
      }
    }
  }
}

.trash-icon {
  &:hover {
    color: #d30000;
  }
}

.voice-selection {
  display: flex;
  width: 10.25rem;
  padding: 0.625rem;
  align-items: center;
  border-radius: 0.25rem;
  border: 0.063rem solid #d9d9d9;
  background-color: #fff;
  cursor: pointer;
  @media (max-width: 899px) {
    width: 100%;
  }
  .voice-selection-info {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    flex: 1 0 0;
    svg {
      width: 0.75rem;
      height: 0.75rem;
    }
    span {
      color: #000;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;
    }
  }
}

.voice-selection-border {
  border-bottom: 0.063rem solid #f7f7f7;
}

.voiceover-progress-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  .progress-value {
    color: #585858 !important;
    font-size: 0.875rem;
  }
}

.media-tab-icon {
  display: flex;
  svg {
    width: 1.25rem;
  }
}

.video-tab-content,
.images-tab-content,
.vector-tab-content,
.library-tab-content,
.upload-tab-content,
.voice-selection-content {
  width: 100%;
  .custom-form-textarea {
    textarea {
      height: 5.8125rem;
    }
  }
  .videoPreview {
    display: flex;
    align-items: center;
    gap: 1rem;
    video {
      border-radius: 5px;
      width: 350px !important;
      height: auto !important;
      margin: 10px 0 30px 0;
    }
  }
}

.library-tab-content,
.upload-tab-content {
  margin-top: 1.5rem;
}

.media-action-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;
  .action-card {
    padding: 0.75rem 2rem;
  }
}
.images-tab-content {
  &:last-child {
    padding-top: 10px;
  }
}

.asset-thumbnail {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &.active {
    .video-wrap {
      border: 0.188rem solid #6750a4;
    }
    img {
      filter: brightness(0.5);
    }
  }

  .video-wrap {
    display: flex;
    width: 14rem;
    height: 11.375rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    border: 0.063rem solid #d9d9d9;
    overflow: hidden;
    position: relative;
    .eye-btn {
      opacity: 0;
    }
    &:hover {
      .eye-btn {
        opacity: 1;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .icon-wrap {
      position: absolute;
      display: flex;
      padding: 0.5rem;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 0.25rem;
      background-color: #6750a4;
    }
  }
}

.max-limit-info {
  display: flex;
  padding: 0.75rem;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 0.063rem solid #b3a7d1;
  background-color: #f0edf6;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
  span {
    color: #000;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
  }
}

.disabled-btn {
  pointer-events: none;
  border-color: #d9d9d9;
  color: #d9d9d9;
}

.media-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1.25rem;
  gap: 1.25rem;
  h4 {
    color: #585858;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

.generate-vector-wrap {
  border-bottom: 0.063rem solid #f1f1f1;
  margin-bottom: 1.5rem;
  &:last-child {
    border-bottom: none;
  }
}

.max-vector-limit-reached {
  margin: 1.25rem 0;
  color: #585858;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
}

.radio-toggle-button {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.75rem;

  .option {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    color: #585858;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    cursor: pointer;

    .radio-circle {
      width: 1.5rem;
      height: 1.5rem;
      border: 0.094rem solid #d9d9d9;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: '';
        width: 1rem;
        height: 1rem;
        background-color: transparent;
        border-radius: 50%;
      }
    }

    &.active {
      color: #000;

      .radio-circle {
        border-color: #6750a4;

        &::after {
          background-color: #6750a4;
        }
      }
    }
  }

  .library-assets-content {
    margin-top: 1.25rem;
    padding: 0.625rem;
    border: 0.063rem solid #ccc;
    border-radius: 0.625rem;
    width: 6.25rem;
  }
}

.search-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  .search-wrap {
    display: flex;
    padding: 0.6rem 1rem;
    align-items: center;
    flex: 1 0 0;
    border-radius: 0.25rem;
    border: 0.063rem solid #d9d9d9;
    background-color: #fff;
    input {
      width: 100%;
      padding-left: 0.625rem;
      border: none;
      color: #585858;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;
      &::placeholder {
        color: #585858;
      }
    }
  }
}

.video-naviagtion-footer {
  padding: 0 1.75rem 0 0;
  margin-top: -1.5rem;
  position: relative;
  z-index: 1;
}

.video-naviagtion-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: #fff;
  box-shadow:
    0 -1px 3px 0 #f1f1f1,
    0 1px 2px 0 #f1f1f1;
  @media (max-width: 899px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
  .button-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
    .ouline-none-btn {
      color: #585858;
      font-weight: 600;
      &:disabled {
        cursor: no-drop;
      }
    }
    .outline-primary-btn {
      &:disabled {
        cursor: no-drop;
      }
    }
  }
  .unsave-text {
    @media (max-width: 899px) {
      flex: auto;
      justify-content: center;
    }
  }
}

.unsave-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  // flex: 1;
  width: 100%;
  svg {
    width: 1.25rem;
    height: 1.25rem;
    path {
      fill: #8c5618;
    }
  }
  span {
    color: #8c5618;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    letter-spacing: 0.01rem;
  }
}
