.custom-checkbox-wrap {
    position: relative;
    display: flex;
    align-items: center;
    gap: .6rem;
    margin-bottom: 1rem;
    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }
  
    .input-icon {
      width: 1.125rem;
      height: 1.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--#{$variable-prefix}background-white);
      border: 0.063rem solid var(--#{$variable-prefix}bg-night-sky-gray);
      border-radius: 0.125rem;
      transition: background-color 0.3s ease;
      font-size: 0.875rem;
      cursor: pointer;
      svg {
        opacity: 0;
        width: 0.625rem;
      }
      &.checked {
        background-color: var(--#{$variable-prefix}secondary-color);
        border-color: var(--#{$variable-prefix}secondary-color);
        color: var(--#{$variable-prefix}white-color);
        svg {
          opacity: 1;
        }
      }
    }
    .apply-all-text {
      font-size: 0.875rem;
      font-weight: 500;
      color: #515151;
      cursor: pointer;
    }
  }

  .checkbox-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex: 1 0 0;
    padding: 0.625rem 1rem;
    border-radius: 0.25rem;
    border: 0.063rem solid var(--#{$variable-prefix}bg-soft-plum-gray);
    cursor: pointer;
    .checkbox-info {
      flex: 1;
      cursor: pointer;
      h4 {
        color: var(--#{$variable-prefix}charcoal-gray);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.004rem;
      }
      span {
        color: var(--#{$variable-prefix}soft-plum-gray);
        font-size: 0.813rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.004rem;
      }
    }
  }