.payment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1.25rem 2.5rem;

  .payment-header__right {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    flex-wrap: wrap;
    .view-btn {
      padding: 0.5rem 1rem;
      height: 2.5rem;
      transition: all 0.3s ease-out;
      &:hover {
        background-color: var(--#{$variable-prefix}primary-color);
        color: #fff;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
    .contact-sales {
      font-size: 0.875rem;
      font-weight: 500;
      padding: 0.25rem;
    }
    .download-btn {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      border: none;
      outline: none;
      background-color: transparent;
      color: #515151;
      // font-family: Roboto;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem; /* 171.429% */
      letter-spacing: 0.004rem;
      padding: 0.5rem 1rem;
      height: 2.5rem;
      border-radius: 0.375rem;
      border: 0.063rem solid transparent;
      transition: all 0.3s ease-out;
      &:hover {
        border: 0.063rem solid #515151;
      }
    }
  }
}
.payment-card {
  padding: 2.5rem;
  display: flex;
  align-items: center;
  gap: 1.875rem;
  flex-wrap: wrap;
  .payment-card__plan,
  .payment-card__method {
    border-radius: 0.375rem;
    border: 0.063rem solid #d9d9d9;
    display: flex;
    padding: 0.875rem 1.25rem 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
  }
  .plan-card-container {
    display: flex;
    align-items: start;
    gap: 1.25rem;
    flex-wrap: wrap;
  }
}
.plan-card {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.25rem;
  border: 0.125rem solid var(--#{$variable-prefix}primary-color);
  position: relative;
  .plan-icon {
    position: absolute;
    top: -0.938rem;
    right: -0.938rem;
  }
  .plan-card-header {
    flex-direction: column;
    margin-bottom: 0.625rem;
    .astris {
      color: #6750a4;
    }
  }
  .remain {
    color: #79747e;
    // font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.009rem;
  }
  strong {
    color: #13171f;
    // font-family: Roboto;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.015rem;
  }
  .cancel-subs {
    border-radius: 0.25rem;
    border: 0.063rem solid #6750a4;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.25rem;
    padding: 0.625rem 1.031rem 0.625rem 1.06rem;
    color: #6750a4;
    text-align: center;
    // font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem; /* 114.286% */
    letter-spacing: 0.025rem;
  }
}
.credit-card {
  display: flex;
  gap: 1.313rem;
  .card-body {
    border-radius: 0.375rem;
    border: 0.063rem solid #d9d9d9;
    background: #f6f6f6;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.875rem;
    .card-details {
      display: flex;
      align-items: center;
      gap: 1.313rem;

      .image-container {
        width: 5rem;
        height: 3.438rem;
        border-radius: 0.25rem;
        overflow: hidden;
      }
      .visa-card {
        background: #fff;
      }
    }
  }
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    .bg-primary-btn {
      transition: all 0.3s ease-out;
      &:hover {
        background-color: #fff;
        border: 0.063rem solid #6750a4;
        color: #6750a4;
      }
      &:disabled {
        opacity: 0.7;
        cursor: no-drop;
      }
    }
  }
}
.invoice-history {
  padding: 0 2.5rem;
}
.invoice-table {
  padding: unset;

  .invoice-name {
    display: flex;
    align-items: center;

    .pdf-icon {
      display: inline-block;
      min-height: fit-content;
      min-width: fit-content;
      margin-right: 0.625rem;
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
    span {
      display: inline-block !important;
    }
  }
  .common-table {
    td {
      padding: 1.25rem 0.625rem;
    }
    .invoice-status-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .invoice-status {
        border-radius: 0.125rem;
        padding: 0.25rem 0.625rem;
        color: #fff;
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        gap: 0.625rem;
        width: fit-content;
        justify-content: center;
        border: none;
        outline: none;
        // button::after {
        //   content: '\25BE'; /* Unicode for downward arrow */
        //   margin-left: 0.313rem;
        // }
        &.success {
          background-color: var(--#{$variable-prefix}secondary-color) !important;
          button {
            border: none !important;
          }
        }
        &.failed {
          background-color: var(--#{$variable-prefix}dark-red) !important;
          button {
            border: none !important;
          }
        }
        &.pending {
          background-color: var(--#{$variable-prefix}vivid-orange) !important;
          button {
            border: none !important;
          }
        }
      }
    }
    .action-status {
      &::before {
        left: 0.188rem;
      }
    }
    .upload-button {
      border: none;
      outline: none;
      background: transparent;
      width: 100%;
      color: var(--bs-soft-plum-gray);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.005rem;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:disabled {
        cursor: no-drop;
      }
      .pdf-icon {
        svg {
          width: 1.5rem;
          height: 1.875rem;
        }
      }
    }
  }

  .invoice-pagination {
    padding-inline: unset;
  }
}
.plan-title {
  color: #13171f;
  // font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 133.333% */
  letter-spacing: 0.006rem;
  margin-bottom: .3rem;
}
.plan-subtitle {
  color: #13171f;
  //   font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
  letter-spacing: 0.004rem;
}
.action-status {
  // &:before {
  //   left: 0.25rem;
  // }
  &.success {
    color: var(--#{$variable-prefix}secondary-color) !important;
    &:before {
      background: var(--#{$variable-prefix}secondary-color);
      box-shadow: 0 0 0.25rem 0 var(--#{$variable-prefix}secondary-color);
    }
  }
  &.failed {
    color: var(--#{$variable-prefix}dark-red) !important;
    &::before {
      background: var(--#{$variable-prefix}dark-red);
      box-shadow: 0 0 0.25rem 0 var(--#{$variable-prefix}bright-red);
    }
  }
  &.pending {
    color: var(--#{$variable-prefix}vivid-orange) !important;
    &::before {
      background: var(--#{$variable-prefix}bg-vivid-orange);
      box-shadow: 0 0 0.25rem 0 var(--#{$variable-prefix}bg-vivid-orange);
    }
  }
  &.sent {
    color: var(--#{$variable-prefix}royal-blue) !important;
    &::before {
      background: var(--#{$variable-prefix}bg-royal-blue);
      box-shadow: 0 0 0.25rem 0 var(--#{$variable-prefix}bg-royal-blue);
    }
  }
  &.delivered {
    color: var(--#{$variable-prefix}sky-blue) !important;
    &::before {
      background: var(--#{$variable-prefix}sky-blue);
      box-shadow: 0 0 0.25rem 0 var(--#{$variable-prefix}sky-blue);
    }
  }
  &.bounced {
    color: var(--#{$variable-prefix}bright-red) !important;
    &::before {
      background: var(--#{$variable-prefix}bright-red);
      box-shadow: 0 0 0.25rem 0 var(--#{$variable-prefix}bright-red);
    }
  }
  &.outboxed {
    color: var(--#{$variable-prefix}dark-red) !important;
    &::before {
      background: var(--#{$variable-prefix}dark-red);
      box-shadow: 0 0 0.25rem 0 var(--#{$variable-prefix}dark-red);
    }
  }
  &.rejected {
    color: var(--#{$variable-prefix}dark-red) !important;
    &::before {
      background: var(--#{$variable-prefix}dark-red);
      box-shadow: 0 0 0.25rem 0 var(--#{$variable-prefix}dark-red);
    }
  }
  &.complained {
    color: var(--#{$variable-prefix}dark-red) !important;
    &::before {
      background: var(--#{$variable-prefix}dark-red);
      box-shadow: 0 0 0.25rem 0 var(--#{$variable-prefix}dark-red);
    }
  }
  &.clicked {
    color: var(--#{$variable-prefix}primary-color) !important;
    &::before {
      background: var(--#{$variable-prefix}primary-color);
      box-shadow: 0 0 0.25rem 0 var(--#{$variable-prefix}primary-color);
    }
  }
}
.upload-invoice-modal {
  .modal-content {
    .modal-body {
      border-radius: 0.25rem;
      padding: 1rem;
      .modal-close-btn {
        top: 0.625rem;
        right: 0.625rem;
      }
      .button-container {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: end;
        .bg-btn-primary {
          border-radius: 0.25rem;
          line-height: 1.5rem;
          padding: 0.5rem 1rem;
        }
      }
    }
  }
}

.plan-vat-text {
  display: block;
  margin-bottom: .5rem;
  font-size: 0.813rem;
  font-weight: 400;
  color: #79747e;
  font-style: italic;
  span {
    color: #6750a4;
  }
}
