@font-face {
  font-family: 'Inter';
  src:
    url('../../font/Inter-Black.woff2') format('woff2'),
    url('../../font/Inter-Black.woff') format('woff');
  // src: url('../font/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:
    url('../../font/Inter-Light.woff2') format('woff2'),
    url('../../font/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:
    url('../../font/Inter-Bold.woff2') format('woff2'),
    url('../../font/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:
    url('../../font/Inter-Medium.woff2') format('woff2'),
    url('../../font/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:
    url('../../font/Inter-ExtraBold.woff2') format('woff2'),
    url('../../font/Inter-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:
    url('../../font/Inter-ExtraLight.woff2') format('woff2'),
    url('../../font/Inter-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:
    url('../../font/Inter-SemiBold.woff2') format('woff2'),
    url('../../font/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:
    url('../../font/Inter-Thin.woff2') format('woff2'),
    url('../../font/Inter-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:
    url('../../font/Inter-Regular.woff2') format('woff2'),
    url('../../font/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: Inter !important;
}
// * {
//     font-family: Inter !important;
// }

.fs-9 {
  font-size: 0.563rem !important;
}
.fs-10 {
  font-size: 0.625rem !important;
}
.fs-11 {
  font-size: 0.688rem !important;
}
.fs-12 {
  font-size: 0.75rem !important;
}
.fs-13 {
  font-size: 0.813rem !important;
}
.fs-14 {
  font-size: 0.875rem !important;
}
.fs-15 {
  font-size: 0.938rem !important;
}
.fs-16 {
  font-size: 1rem !important;
}
.fs-17 {
  font-size: 1.063rem !important;
}
.fs-18 {
  font-size: 1.125rem !important;
}
.fs-19 {
  font-size: 1.188rem !important;
}
.fs-20 {
  font-size: 1.25rem !important;
}
.fs-21 {
  font-size: 1.313rem !important;
}
.fs-22 {
  font-size: 1.375rem !important;
}
.fs-23 {
  font-size: 1.438rem !important;
}

input[type='text']::-webkit-clear-button,
input[type='search']::-webkit-search-cancel-button,
input[type='number']::-webkit-clear-button,
input[type='number']::-webkit-search-cancel-button {
  display: none;
}

input[type='search']::-ms-clear,
input[type='number']::-ms-clear {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.content-wrapper {
  position: relative;
  width: 100%;
  &.active {
    padding-left: 15rem;
  }
}

.login {
  &-left-part {
    padding: 1.563rem 2.5rem;
    width: calc(100% - 35%);
    // border-right: 0.063rem solid var(--#{$variable-prefix}pale-gray);
    @media only screen and (max-width: 991px) {
      width: 50%;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 0.938rem;
      min-height: 16.813rem;
      height: 100%;
    }
  }
  &-logo {
    width: 10rem;
    height: 2.188rem;
  }
}

.login {
  &-right-part {
    width: 35%;
    padding: 3.75rem;
    @media only screen and (max-width: 991px) {
      width: 50%;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 0.938rem;
    }
  }
  &-infog {
    width: 100%;
    height: calc(100% - 2.188rem);
    background: url('../../img/banner.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &-cap {
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 2.125rem;
    color: var(--#{$variable-prefix}dark-navy);
    margin-bottom: 0.563rem;
    @media only screen and (max-width: 767px) {
      font-size: 1.25rem;
    }
  }
  &-para {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--#{$variable-prefix}ashen-gray);
    font-weight: 300;
    margin-bottom: 0.938rem;
    @media only screen and (max-width: 767px) {
      font-size: 0.75rem;
    }
  }
  &-inp-wrapper {
    margin-top: 1.438rem;
  }
}
.login-wrapper {
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: space-between;
  }
}

.custom-input-holder {
  min-height: 5.625rem;
  margin-bottom: 0.938rem;
  position: relative;
  .custom-label {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: $dark-grey-font;
    margin-bottom: 0.625rem;
    @media (max-width: 767px) {
      font-size: 0.813rem !important;
    }
  }
  .custom-input {
    @include border-radius(0.188rem);
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 300;
    color: var(--#{$variable-prefix}nightshade-gray);
    background: var(--#{$variable-prefix}bg-pale-silver);
    padding: 0.938rem 2.188rem 0.938rem 2.813rem;
    border: 0.063rem solid transparent;
    &:focus {
      border: 0.063rem solid $theme-blue;
    }
    @media (max-width: 767px) {
      font-size: 0.75rem !important;
    }
  }
  .input-error-msg {
    font-size: 0.813rem;
    font-weight: 300;
    color: $theme-red;
    padding-top: 0.188rem;
    position: absolute;
  }
}
.login-btn {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.938rem;
  box-shadow:
    0 0.25rem 0 rgba(70, 139, 232, 0.24),
    0 0 2.813rem 0.25rem rgba(70, 139, 232, 0.3);
}

.user-avatar-inpt {
  @include position(absolute, 50%, 1.063rem, auto, auto);
  transform: translateY(-50%);
  svg {
    width: 1.25rem;
    height: auto;
    fill: var(--#{$variable-prefix}pearl-gray);
  }
}
.pass-lock {
  @include position(absolute, 50%, 0.938rem, auto, auto);
  transform: translateY(-50%);
  svg {
    width: 1.25rem;
    height: auto;
    fill: var(--#{$variable-prefix}pearl-gray);
  }
}
.pass-eye {
  cursor: pointer;
  @include position(absolute, 1.125rem, auto, 0.563rem, auto);
  svg {
    width: 1.25rem;
    height: auto;
    fill: var(--#{$variable-prefix}ashen-gray);
  }
}
.login-arrow {
  display: inline-block;
  animation: login-arrow 2s ease infinite;
  svg {
    width: 1rem;
    height: auto;
    fill: var(--#{$variable-prefix}white-color);
    margin-top: -0.125rem;
  }
}
@keyframes login-arrow {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.5rem);
  }
  100% {
    transform: translateX(0);
  }
}

.cc-blk {
  min-height: calc(100% - 3.5rem);
}
html,
body,
#root,
.theme,
.main-wrapper {
  height: 100%;
  scrollbar-color: var(--#{$variable-prefix}scrollbar-thumb-color) var(--#{$variable-prefix}scrollbar-track-color);
  scrollbar-width: thin;
}
.cc {
  &-heading {
    font-size: 2.5rem;
    font-weight: 500;
    color: $black-font;
    line-height: 3rem;
    margin: 1rem 0;
    text-transform: capitalize;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 1.25rem;
      margin: 0.75rem 0;
    }
  }
  &-para {
    font-size: 0.875rem;
    font-weight: 400;
    color: $panel-grey-txt;
    line-height: 1.25rem;
    width: 60%;
    margin: 0 auto;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 0.75rem;
      width: 100%;
      padding: 0 0.938rem;
    }
  }
  &-left-part {
    margin-top: 10%;
    padding: 4% 10% 0;
    // border-right: 0.063rem solid $dark-grey-font;
    @media (max-width: 991px) {
      margin-top: 0;
      border-right: 0;
      border-bottom: 0.063rem solid $dark-grey-font;
      padding: 4% 10% 5%;
    }
    @media (max-width: 767px) {
      padding: 0.938rem;
    }
  }

  &-right-part {
    margin-top: 10%;
    padding: 4% 10% 0;
    @media (max-width: 991px) {
      padding: 0.938rem;
    }
  }
  &-csv-blk {
    @include border-radius(0.25rem);
    background-color: var(--#{$variable-prefix}bg-pale-silver);
    padding: 0.5rem;
    color: $dark-grey-font;
  }
  &-csv-btn {
    .csv-upload {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.188rem;
      text-transform: uppercase;
      height: 3.375rem;
      width: 10rem;
      @media (max-width: 767px) {
        font-size: 0.75rem;
        height: auto;
        width: auto;
      }
    }
  }
}
.cc-wrapper .custom-input-holder .custom-label {
  font-weight: 600;
  color: $panel-grey-txt;
}
.cc-wrapper .custom-input-holder .custom-input {
  font-size: 1rem;
  font-weight: 400;
  padding: 1.5rem 2.188rem;
  @media (max-width: 767px) {
    padding: 0.938rem;
  }
}
.download-csv {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  color: $panel-grey-txt;
  line-height: 1.25rem;
  text-decoration: underline;
  margin-top: 0.375rem;
  cursor: pointer;
}
.theme-bluebtn {
  font-weight: 600 !important;
  font-size: 1rem !important;
  line-height: 1.188rem;
  text-align: center;
  text-transform: uppercase;
  min-width: 6.125rem;
  height: 3.563rem;
  &:hover {
    opacity: 0.85;
  }
  @media (max-width: 767px) {
    font-size: 0.813rem !important;
    min-width: auto;
    height: auto;
  }
}
.cc-or-seperator {
  @include transform(0%, -50%);
  @include position(absolute, 47%, auto, -2.5rem, auto);
  width: 5rem;
  height: 5rem;
  background-color: var(--#{$variable-prefix}bg-pale-silver);
  border-radius: 50%;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  color: $black-font;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px) {
    @include transform(-50%, 0%);
    @include position(absolute, 85%, 50%, auto, auto);
  }
  @media (max-width: 767px) {
    top: 78%;
  }
}

.cp-img-holder {
  width: 11.25rem;
  height: 7.813rem;
  margin: 0 auto 1.875rem;
  background: url('../../img/course_create.png');
  background-repeat: no-repeat;
  background-size: 100%;
}
.cp-heading {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.188rem;
  color: $black-font;
}
.cp-subheading {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: $panel-grey-txt;
}
.theme-btnblue {
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.75rem 1.438rem;
  &:hover {
    opacity: 0.85;
  }
}
.cm-btn {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-transform: uppercase;
  min-width: 10.188rem;
  min-height: 2.5rem;
  box-shadow: none;
  &:hover {
    opacity: 0.85;
  }
}
.b-logo-up {
  @include border-radius(0);
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  min-height: 2.5rem;
  text-transform: uppercase;
  box-shadow: none;
  &:hover {
    opacity: 0.85;
  }
}
// custom checkbox
.custom-checkbox {
  .check-label {
    font-size: 0.875rem;
    font-weight: 400;
    color: $panel-grey-txt;
    line-height: 1.25rem;
    padding-left: 0.938rem;
  }
  .checkmark {
    @include border-radius(0.25rem);
    position: absolute;
    top: 0;
    left: 0;
    height: 1.063rem;
    width: 1.063rem;
    border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
    margin-top: 0.125rem;
    &:after {
      display: none;
      content: '';
      position: absolute;
      left: 0.25rem;
      top: 0.125rem;
      width: 0.375rem;
      height: 0.563rem;
      border: solid $theme-blue;
      border-width: 0 0.125rem 0.125rem 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    margin-right: 0.313rem;
    vertical-align: middle;
    &:checked {
      ~ {
        .checkmark {
          &:after {
            display: block;
          }
        }
      }
    }
  }
  display: block;
  position: relative;
  padding-left: 0.938rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cancel-btn {
  font-size: 0.813rem;
  font-weight: 500;
  color: $panel-grey-txt;
  line-height: 1.25rem;
  box-shadow: none;
  background: none;
  outline: none;
  border: none;
  &:hover {
    opacity: 0.85;
  }
}

.pb-wrapper {
  padding: 3% 4%;
  min-height: calc(100% - 3.5rem);
  .pb-card {
    position: relative;
    @include border-radius(0.375rem);
    background-color: var(--#{$variable-prefix}light-platinum);
    height: 7.813rem;
    padding: 1.563rem;
    overflow: hidden;
    .pb-count {
      font-size: 4.5rem;
      line-height: 5.438rem;
      font-weight: 700;
      text-shadow: 0 0.25rem 0.25rem rgba(176, 176, 176, 0.15);
    }
    .pb-desc {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.188rem;
      text-transform: uppercase;
      color: var(--#{$variable-prefix}light-gray-text);
    }
    .tick {
      @include position(absolute, auto, auto, 1.313rem, 1.313rem);
      svg {
        width: 1.688rem;
        height: auto;
        fill: $theme-blue;
      }
    }
    @media (max-width: 991px) {
      height: auto;
      padding: 0.938rem;
      margin-bottom: 0.625rem;
    }
  }
  .table-wraper {
    overflow-x: auto;
    scrollbar-color: var(--#{$variable-prefix}scrollbar-thumb-color) var(--#{$variable-prefix}scrollbar-track-color);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      height: 0.313rem;
      height: 0.25rem;
    }

    &::-webkit-scrollbar-track {
      background: var(--#{$variable-prefix}scrollbar-track-color);
      border-radius: 0.625rem;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--#{$variable-prefix}scrollbar-thumb-color);
      border-radius: 0.625rem;
      cursor: pointer;
    }
    .dropdown-menu {
      min-width: 6.25rem;
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.375rem;
        padding: 0.25rem 0.75rem;
        color: $slate-grey;
        svg {
          width: 0.75rem;
          fill: $slate-grey;
        }
        &:hover {
          background: none;
          color: $slate-grey;
        }
      }
    }
  }
}
.pointer {
  cursor: pointer;
}
.col:nth-child(1) .pb-card .pb-count {
  color: $theme-blue;
}
.col:nth-child(2) .pb-card .pb-count {
  color: #8791dd;
}
.col:nth-child(3) .pb-card .pb-count {
  color: #b364c8;
}

.custom-progress {
  height: 0.25rem;
  @include position(absolute, auto, 0.25rem, 0.25rem, 0);
  .progress-bar {
    background: linear-gradient(90deg, #e5e7eb 0%, #e5e7eb 35.94%, #56c4f5 68.75%, #c156c2 100%);
  }
}

//   08-11
.main-wrapper {
  .content-inner {
    height: calc(100vh - 3.5rem);
    main {
      flex: 1;
    }
  }
}

// top-bar
.top-bar {
  background: var(--#{$variable-prefix}background-white);
  min-height: 3.5rem;
  align-items: center;
  @media screen and (max-width: 991px) {
    padding: 0 0.938rem;
  }
  .create-btn {
    padding: 0.813rem 1.25rem;
    font-size: 0.75rem;
    line-height: 1.75rem;
    color: var(--#{$variable-prefix}light-gray-text);
    font-weight: 600;
    margin-bottom: 0;
    outline: none;
    flex: 0 0 14.188rem;
    max-width: 14.188rem;
    @include border-radius(0);
    box-shadow: 0 0 0.5rem 0.125rem rgba(240, 239, 239, 0.75);
    -webkit-box-shadow: 0 0 0.5rem 0.125rem rgba(240, 239, 239, 0.75);
    -moz-box-shadow: 0 0 0.5rem 0.125rem rgba(240, 239, 239, 0.75);
    @media screen and (max-width: 991px) {
      flex: 0 0 11.563rem;
      max-width: 11.563rem;
      padding: 0.813rem 0;
    }
    svg {
      stroke: var(--#{$variable-prefix}light-gray-text);
      // margin-right: 0.313rem;
    }
  }
  .topbar-menu {
    flex: 1;
    align-items: center;
    padding: 0 2.25rem;
    @media screen and (max-width: 991px) {
      padding: 0 0 0 0.938rem;
    }
    // h3 {
    //     font-weight: 600;
    //     font-size: 1rem;
    //     line-height: 1.188rem;
    //     color: var(--#{$variable-prefix}light-gray-text);
    //     margin-bottom: 0;
    //     flex: 1;
    //     position: relative;
    //     &::before {
    //         content: '';
    //         height: 0.063rem;
    //         width: 20%;
    //         background: var(--#{$variable-prefix}chill-gray);
    //         position: absolute;
    //         bottom: -0.5rem;
    //         opacity: 0.5;
    //     }
    // }
    input {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.188rem;
      color: var(--#{$variable-prefix}light-gray-text);
      margin-bottom: 0;
      flex: 1;
      max-width: 10rem;
      position: relative;
      border: none;
      border-bottom: 0.063rem solid rgba(var(--#{$variable-prefix}chill-gray), 0.5);
      padding-bottom: 0.5rem;
      background: transparent;
    }
    ul {
      align-items: center;
      li {
        .notification {
          position: relative;
          svg {
            stroke: var(--#{$variable-prefix}light-gray-text);
          }
          span {
            background: $theme-blue;
            box-shadow: 0 0.063rem 0.25rem rgba(70, 139, 232, 0.39);
            position: absolute;
            width: 0.625rem;
            height: 0.625rem;
            left: 0.75rem;
            top: 0.188rem;
          }
        }
        .account-user-avatar {
          display: inline-block;
          width: 2.063rem;
          height: 2.063rem;
          @include border-radius(0.5rem);
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .dropdown {
          .dropdown-toggle {
            &::after {
              content: none;
            }
          }
          .dropdown-menu {
            -webkit-animation-name: fadeInDown;
            animation-name: fadeInDown;
            -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            top: 100% !important;
            left: -15rem !important;
            margin-top: 0.625rem !important;
            @include border-radius(0.5rem);
            border: none;
            // border: 0.063rem solid red;
            // padding: 1.25rem 0;
            box-shadow: none;
            background: var(--#{$variable-prefix}background-white);
            min-width: 18.563rem;
            padding-bottom: 0;
            &::before {
              content: '';
              border-bottom: 0.5rem solid var(--#{$variable-prefix}background-white);
              border-left: 0.625rem solid transparent;
              border-right: 0.625rem solid transparent;
              transform: rotate(360deg);
              width: 1.313rem;
              height: 0.375rem;
              position: absolute;
              top: -0.375rem;
              right: 1.375rem;
            }
            .dropdown-header {
              padding: 1.25rem 1.875rem 0 1.875rem;
              margin-bottom: 1rem;
              h6 {
                font-size: 1rem;
                line-height: 1.375rem;
                font-weight: 400;
                color: var(--#{$variable-prefix}light-gray-text);
              }
              p {
                font-size: 0.625rem;
                line-height: 1.125rem;
                font-weight: 400;
                margin-bottom: 0;
                color: var(--#{$variable-prefix}light-gray-text);
              }
            }
            ul {
              padding: 0 1.875rem;
              margin-bottom: 1.875rem;
              li {
                a {
                  padding: 0.625rem 0;
                  .dropdown-icon {
                    margin-right: 1.125rem;
                    &.stroke {
                      svg {
                        stroke: var(--#{$variable-prefix}light-gray-text);
                      }
                    }
                    &.fill {
                      svg {
                        fill: var(--#{$variable-prefix}light-gray-text);
                      }
                    }
                  }
                  &:hover {
                    background: none;
                  }
                  span {
                    font-size: 0.875rem;
                    line-height: 1rem;
                    font-weight: 400;
                    color: var(--#{$variable-prefix}light-gray-text);
                  }
                }
              }
            }
            .mode-change {
              padding: 1.25rem 1.875rem;
              h6 {
                font-size: 0.875rem;
                line-height: 1.25rem;
                font-weight: 400;
                flex: 1;
                color: var(--#{$variable-prefix}light-gray-text);
              }

              .toggle-switch {
                position: relative;
                width: 1.875rem;

                label {
                  position: absolute;
                  width: 100%;
                  height: 0.875rem;
                  background-color: var(--#{$variable-prefix}bg-dark-gray);
                  border-radius: 3.125rem;
                  cursor: pointer;
                }

                input {
                  position: absolute;
                  display: none;
                }

                .slider {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  border-radius: 3.125rem;
                  transition: 0.3s;
                }

                input:checked ~ .slider {
                  background-color: var(--#{$variable-prefix}gentle-sky);
                }

                .slider::before {
                  content: '';
                  position: absolute;
                  top: 0.125rem;
                  left: 0.188rem;
                  width: 0.625rem;
                  height: 0.625rem;
                  border-radius: 50%;
                  background-color: var(--#{$variable-prefix}background-white);
                  transition: 0.3s;
                }

                input:checked ~ .slider::before {
                  transform: translateX(0.5rem);
                  box-shadow: 0.313rem 0 0 0 var(--#{$variable-prefix}bg-night-sky-gray);
                  border-radius: 100%;
                  background: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
// aside
aside {
  flex: 0 0 14.188rem;
  max-width: 14.188rem;
  background: var(--#{$variable-prefix}background-white);
  box-shadow: 0.188rem 0 1.25rem 0.5rem rgba(240, 239, 239, 0.75);
  -webkit-box-shadow: 0.188rem 0 1.25rem 0.5rem rgba(240, 239, 239, 0.75);
  -moz-box-shadow: 0.188rem 0 1.25rem 0.5rem rgba(240, 239, 239, 0.75);
  @media screen and (max-width: 991px) {
    width: 14.375rem;
    max-width: 14.375rem;
    height: 100%;
    top: 3.5rem;
    left: -25rem;
    overflow: hidden;
    // background-color: black;
    box-shadow: 0.313rem 0.313rem 0.625rem grey;
    position: fixed;
    z-index: 11;
    opacity: 0;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
  }
  &.is-visible {
    opacity: 1;
    left: 0;
  }
  .tab-content {
    max-height: calc(100vh - 3.5rem);
    overflow: auto;
    margin-right: 0.063rem;
    .accordion > .card {
      border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
      border: none;
      @include border-radius(0);
      .card-header {
        @include border-radius(0);
        margin-bottom: 0;
        background: var(--#{$variable-prefix}light-platinum);
        padding: 0 1.25rem;
        // border: none;
        border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
        &:first-child {
          border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
        }
        h5 {
          a {
            width: 100%;
            display: block;
            text-align: left;
            outline: none;
            box-shadow: none;
            min-height: 2.5rem;
            font-size: 1rem;
            line-height: 1.625rem;
            color: var(--#{$variable-prefix}light-gray-text);
            font-weight: 600;
            text-decoration: none;
            position: relative;
          }
        }
      }
      .card-body {
        padding: 0;
        .card {
          background: transparent !important;
          padding: 0 1.25rem 0 2.5rem;
          border: none !important;
          border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray) !important;
          @include border-radius(0);
          &:last-child {
            border: none !important;
          }
          .card-header {
            border: none;
            background: transparent !important;
            padding: 0;
            a {
              width: 100%;
              display: block;
              text-align: left;
              outline: none;
              box-shadow: none;
              min-height: 1.875rem;
              font-size: 0.875rem;
              line-height: 1.25rem;
              color: var(--#{$variable-prefix}light-gray-text);
              font-weight: 600;
              text-decoration: none;
              position: relative;
              padding: 0.5rem 0 0.5rem 1.25rem;
              &:after {
                content: '';
                position: absolute;
                left: 0;
                z-index: 11;
                display: block;
                width: 0.5rem;
                height: 0.5rem;
                border-top: 0.5rem solid var(--#{$variable-prefix}chill-gray);
                border-left: 0.313rem solid transparent;
                border-right: 0.313rem solid transparent;
                transform: rotate(275deg);
                top: 0;
                bottom: 0;
                margin: auto;
              }
              &[aria-expanded='true']:after {
                transform: rotate(360deg);
              }
              strong {
                font-size: 1.25rem;
                line-height: 1.688rem;
                font-weight: 700;
                color: rgba(var(--#{$variable-prefix}light-gray-text), 0.5);
                margin-right: 0.438rem;
              }
            }
          }
        }
      }
    }
    .card-header {
      a {
        position: relative;
        padding-left: 1.25rem;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          z-index: 11;
          display: block;
          width: 0.5rem;
          height: 0.5rem;
          border-top: 0.5rem solid var(--#{$variable-prefix}chill-gray);
          border-left: 0.313rem solid transparent;
          border-right: 0.313rem solid transparent;
          transform: rotate(360deg);
          top: 0;
          bottom: 0;
          margin: auto;
        }
        &[aria-expanded='true']:after {
          transform: rotate(180deg);
        }
      }
    }
    .slide-wrap {
      // margin-left: 0.438rem;
      .slide-inner {
        max-height: 18.75rem;
        overflow: auto;
        li {
          padding-left: 1.125rem;
          padding-bottom: 0.938rem;
          margin-left: 0.5rem;
          position: relative;
          min-height: 5rem;
          // border
          &:first-child {
            &::before {
              content: none;
            }
          }
          &::before {
            content: '';
            border-left: 0.063rem dashed var(--#{$variable-prefix}chill-gray);
            position: absolute;
            width: 0.313rem;
            height: 100%;
            left: -0.063rem;
            top: -5rem;
            -webkit-transition: 0.5s ease-in-out;
            -moz-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
          }
          .slide-list {
            &::before {
              content: '';
              width: 0.875rem;
              height: 0.875rem;
              background: var(--#{$variable-prefix}background-white);
              border: 0.063rem solid var(--#{$variable-prefix}chill-gray);
              position: absolute;
              left: -0.5rem;
              border-radius: 3.125rem;
              z-index: 9;
            }
            &::after {
              content: '';
              width: 0.625rem;
              height: 0.625rem;
              background: $theme-blue;
              border: 0.125rem solid var(--#{$variable-prefix}background-white);
              position: absolute;
              left: -0.375rem;
              top: 0.125rem;
              border-radius: 3.125rem;
              z-index: 9;
              opacity: 0;
              animation-duration: 1s;
              animation-fill-mode: both;
              -webkit-animation-duration: 1s;
              -webkit-animation-fill-mode: both;
            }
          }
          .img-wrap {
            position: relative;
            filter: drop-shadow(0 0.25rem 0.25rem rgba(70, 139, 232, 0.15));
            border-radius: 0.375rem;
            overflow: hidden;
            max-height: 4.625rem;
            opacity: 0.5;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            h3 {
              font-weight: 800;
              font-style: italic;
              font-size: 2.688rem;
              line-height: 3.25rem;
              opacity: 0.63;
              color: var(--#{$variable-prefix}white-color);
              @include position(absolute, auto, auto, 0.625rem, 0);
            }
          }

          &.active {
            &:first-child {
              &::before {
                content: none;
              }
            }
            &::before {
              border-color: $theme-blue;
            }
            .img-wrap {
              opacity: 1;
            }
            .slide-list {
              &::before {
                background: $theme-blue;
                left: -0.5rem;
              }
              &::after {
                opacity: 1;
                animation-name: fadeInDown;
                -webkit-animation-name: fadeInDown;
              }
            }
          }
        }
      }
    }
  }
}

// video-sec
.video-sec {
  flex: 0 0 60%;
  max-width: 60%;
  overflow: auto;
  max-height: calc(100vh - 3.5rem);
  background: var(--#{$variable-prefix}bg-pale-silver);
  padding: 1.5rem 2.25rem;
  position: relative;
  @media screen and (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 1.5rem 1.25rem;
  }
  img {
    width: 100%;
  }
  .preview-sec {
    padding-bottom: 5rem;
    .btn {
      min-height: 2.125rem;
      min-width: 6rem;
      @include border-radius(0.125rem);
    }
  }
  .video-inner {
    .video-head {
      margin-bottom: 0.875rem;
      h4 {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.75rem;
        color: var(--#{$variable-prefix}light-gray-text);
        border-bottom: 0.031rem solid var(--#{$variable-prefix}chill-gray);
        display: inline-block;
      }
    }
  }
}
.video-control {
  .rSlider {
    position: relative;
    margin: 1rem 0 0.625rem;
    .slide {
      background: $theme-blue;
      height: 0.313rem;
      width: 5rem;
      position: absolute;
      left: 0;
      top: 0.563rem;
      z-index: 2;
      pointer-events: none;
    }
    input[type='range'] {
      position: relative;
      -webkit-appearance: none;
      width: 100%;
      height: 0.313rem;
      background: var(--#{$variable-prefix}light-platinum);
      outline: none;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
    }

    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 0.75rem;
      height: 0.75rem;
      background: $theme-blue;
      cursor: pointer;
      border-radius: 1.25rem;
      z-index: 9;
    }
    input[type='range']::-moz-range-thumb {
      width: 0.75rem;
      height: 0.75rem;
      background: $theme-blue;
      cursor: pointer;
    }
  }
}

.time-duration {
  color: var(--#{$variable-prefix}light-gray-text);
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.75rem;
}

// .right-sec
.right-sec {
  flex: 0 0 40%;
  max-width: 40%;
  @media screen and (max-width: 991px) {
    max-height: calc(100vh - 3.5rem);
    width: 25rem;
    max-width: 25rem;
    height: 100%;
    top: 3.5rem;
    right: -25rem;
    overflow: hidden;
    background-color: var(--#{$variable-prefix}light-platinum);
    box-shadow: 0.313rem 0.313rem 0.625rem var(--#{$variable-prefix}ashen-gray);
    position: fixed;
    z-index: 11;
    opacity: 0;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
  }
  &.is-visible {
    opacity: 1;
    right: 0;
  }
  .tab-view {
    display: flex;
    justify-content: center;
    min-height: 100%;
    flex-wrap: wrap;
    .tab-content {
      flex: 1;
      overflow: auto;
      max-height: calc(100vh - 3.5rem);
      overflow-x: hidden;
      .accordion > .card {
        border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
        border: none;
        @include border-radius(0);
        .card-header {
          @include border-radius(0);
          margin-bottom: 0;
          background: var(--#{$variable-prefix}light-platinum);
          padding: 0 1.25rem;
          // border: none;
          border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
          &:first-child {
            border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
          }
          h5 {
            a {
              width: 100%;
              display: block;
              text-align: left;
              outline: none;
              box-shadow: none;
              min-height: 2.5rem;
              font-size: 0.875rem;
              line-height: 1.25rem;
              color: var(--#{$variable-prefix}light-gray-text);
              font-weight: 600;
              text-decoration: none;
              position: relative;
            }
          }
        }
        .card-body {
          padding: 0;
          .card {
            background: transparent !important;
            padding: 0 1.25rem 0 2.5rem;
            border: none !important;
            border-bottom: 0.031rem solid rgba(var(--#{$variable-prefix}chill-gray), 0.5) !important;
            @include border-radius(0);
            @media screen and (max-width: 1199px) {
              padding: 0 0.938rem 0 1.25rem;
            }
            &:last-child {
              border: none !important;
            }
            .card-header {
              border: none;
              background: transparent !important;
              padding: 0;
              a {
                width: 100%;
                display: block;
                text-align: left;
                outline: none;
                box-shadow: none;
                min-height: 1.875rem;
                font-size: 0.875rem;
                line-height: 1.25rem;
                color: var(--#{$variable-prefix}light-gray-text);
                font-weight: 600;
                text-decoration: none;
                position: relative;
                padding: 0.625rem 0 0.625rem 1.25rem;
                &:after {
                  content: '';
                  position: absolute;
                  left: 0;
                  z-index: 11;
                  display: block;
                  width: 0.5rem;
                  height: 0.5rem;
                  border-top: 0.625rem solid var(--#{$variable-prefix}chill-gray);
                  border-left: 0.313rem solid transparent;
                  border-right: 0.313rem solid transparent;
                  transform: rotate(275deg);
                  top: 0;
                  bottom: 0;
                  margin: auto;
                }
                &[aria-expanded='true']:after {
                  transform: rotate(360deg);
                }
                strong {
                  font-size: 1.25rem;
                  line-height: 1.688rem;
                  font-weight: 700;
                  color: var(--#{$variable-prefix}cloud-gray);
                  margin-right: 0.625rem;
                }
              }
            }
          }
        }
        .card-inner {
          padding: 1rem 1.5rem;
          .label-block {
            flex: 0 0 4.5rem;
            max-width: 4.5rem;
            @media screen and (max-width: 1119px) {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
          .field-block {
            flex: 1;
            .Regular {
              width: 65%;
            }
            .size {
              width: 35%;
            }
            .img-upload {
              position: relative;
              max-width: 6.25rem;
              .img-wrap {
                width: 5.625rem;
                height: 4.375rem;
                border: 0.063rem solid var(--#{$variable-prefix}chill-gray);
                box-sizing: border-box;
                border-radius: 0.125rem;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .upload-btn {
                width: 1.938rem;
                height: 1.938rem;
                background: var(--#{$variable-prefix}bg-pale-silver);
                opacity: 0.75;
                box-shadow: 0 0.25rem 0.25rem rgba(176, 176, 176, 0.15);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 3.125rem;
                position: absolute;
                bottom: -0.25rem;
                right: -0.125rem;
                cursor: pointer;
                input[type='file'] {
                  position: absolute;
                  left: 0;
                  top: 0;
                  opacity: 0;
                  cursor: pointer;
                  width: 100%;
                  height: 100%;
                }
                svg {
                  stroke: var(--#{$variable-prefix}light-gray-text);
                }
              }
            }
            .editor-list {
              li {
                flex: 0 0 25%;
                border-top: 0.031rem solid var(--#{$variable-prefix}chill-gray);
                border-bottom: 0.031rem solid var(--#{$variable-prefix}chill-gray);
                &:first-child {
                  border-left: 0.031rem solid var(--#{$variable-prefix}chill-gray);
                  border-radius: 0.125rem 0 0 0.125rem;
                }
                &:last-child {
                  border-right: 0.031rem solid var(--#{$variable-prefix}chill-gray);
                  border-radius: 0 0.125rem 0.125rem 0;
                }
                button {
                  border-radius: 0;
                  width: 100%;
                }
              }
            }
          }
          textarea.form-control {
            border: 0.063rem solid var(--#{$variable-prefix}chill-gray);
            padding: 0.938rem !important;
            border-radius: 0.25rem;
            height: 8.125rem;
            color: var(--#{$variable-prefix}light-gray-text);
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 400;
            resize: none;
            @media screen and (max-width: 1199px) {
              padding: 0.5rem !important;
            }
          }

          .edit-label {
            color: var(--#{$variable-prefix}light-gray-text);
            font-size: 0.75rem;
            line-height: 1rem;
            font-weight: 600;
            margin-bottom: 0;
            @media screen and (max-width: 1199px) {
              margin-bottom: 0.375rem;
            }
          }
          p {
            // background: var(--#{$variable-prefix}light-lavender);
            font-size: 0.75rem;
            line-height: 1.125rem;
            font-weight: 400;
            color: var(--#{$variable-prefix}light-gray-text);
            margin-bottom: 0;
            // padding: 0.5rem 1.5rem;
            display: inline-block;
            a {
              margin: 0 0.625rem;
              font-weight: 600;
              font-size: 0.75rem;
              line-height: 1.125rem;
              color: $theme-blue;
              text-decoration: underline;
              display: inline-block;
            }
          }
        }
      }
      .card-header {
        a {
          position: relative;
          padding-left: 1.25rem;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            z-index: 11;
            display: block;
            width: 0.5rem;
            height: 0.5rem;
            border-top: 0.5rem solid var(--#{$variable-prefix}chill-gray);
            border-left: 0.313rem solid transparent;
            border-right: 0.313rem solid transparent;
            transform: rotate(275deg);
            top: -0.313rem;
            bottom: 0;
            margin: auto;
          }
          &[aria-expanded='true']:after {
            transform: rotate(360deg);
          }
        }
      }
    }
    .tab-head {
      flex: 0 0 6.5rem;
      max-width: 6.5rem;
      background: var(--#{$variable-prefix}medium-dark-gray);
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: auto;
      max-height: calc(100vh - 3.5rem);
      position: relative;
      overflow-x: hidden;
      &::before {
        content: '';
        background: url('../../img/right-bar-bg.png') no-repeat;
        height: 6.875rem;
        width: 6.5rem;
        position: fixed;
        bottom: 0;
        z-index: 0;
        right: 0;
        background-size: cover;
      }
      ul {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // flex-direction: column;
        z-index: 1;
        position: relative;
        padding: 1.5rem 0.625rem;
        li {
          text-align: center;
          margin-bottom: 0.813rem;
          a {
            display: block;
            span {
              background: linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.08) 100%);
              box-shadow: 0 0.25rem 0 rgba(0, 0, 0, 0.03);
              backdrop-filter: blur(0.25rem);
              border-radius: 0.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 3.125rem;
              height: 3.125rem;
              margin: 0 auto;
              margin-bottom: 0.625rem;
            }
            small {
              font-size: 0.875rem;
              line-height: 1.25rem;
              font-weight: 600;
              color: rgba(var(--#{$variable-prefix}white-color), 0.35);
            }
          }
          &.active {
            a {
              display: block;
              span {
                background: $theme-blue;
                box-shadow:
                  0 0.25rem 0 rgba(70, 139, 232, 0.24),
                  0 0 2.813rem 0.25rem rgba(70, 139, 232, 0.3);
              }
              small {
                color: var(--#{$variable-prefix}white-color);
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// video::-webkit-media-controls-volume-slider {
//   display: none;
// }

// video::-webkit-media-controls-mute-button {
//   display: none;
// }

.divider {
  padding: 0;
  height: 0.063rem;
  background: var(--#{$variable-prefix}chill-gray);
  margin: 0.875rem 0;
}

.fill-i {
  fill: var(--#{$variable-prefix}light-gray-text);
}
.outline-i {
  stroke: var(--#{$variable-prefix}light-gray-text);
}
.color-picker {
  .color-palette {
    width: 1.625rem;
    height: 1.625rem;
    display: block;
    background: var(--#{$variable-prefix}bg-dark-navy);
    border-radius: 3.125rem;
    position: relative;
    margin-bottom: 0.625rem;
    i {
      width: 1.25rem;
      height: 1.25rem;
      background: var(--#{$variable-prefix}background-white);
      border-radius: 3.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: -0.25rem;
      right: -0.25rem;
      cursor: pointer;
    }
  }
}

.save-apply {
  position: absolute;
  top: 4.5rem;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  p {
    background: var(--#{$variable-prefix}light-lavender);
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: var(--#{$variable-prefix}charcoal-gray);
    margin-bottom: 0;
    padding: 0.5rem 1.5rem;
    display: inline-block;
    a {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.75rem;
      color: $theme-blue;
      text-decoration: underline;
      display: inline-block;
    }
  }
}

.custom-height {
  max-height: 15rem;
  overflow-y: auto;
}

.grid-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row dense;
  grid-gap: 0.625rem;
  grid-auto-rows: minmax(4.375rem, auto);
  .grid-list {
    position: relative;
    border-radius: 0.375rem;
    overflow: hidden;
    .icon-wrap {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      background: #5161708f;
      -webkit-transition: all 0.4s ease-in-out 0s;
      -moz-transition: all 0.4s ease-in-out 0s;
      transition: all 0.4s ease-in-out 0s;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-right: 0;
        cursor: pointer;
        &.img-view {
          background: rgba(70, 139, 232, 0.146);
          opacity: 0.6;
          box-shadow:
            inset 0.35rem -0.35rem 0.35rem rgba(53, 106, 176, 0.146),
            inset -0.35rem 0.35rem 0.35rem rgba(255, 255, 255, 0.146);
          backdrop-filter: blur(0.315rem);
          &:hover {
            background: rgba(0, 0, 0, 0.6);
            box-shadow: none;
          }
        }
        &.img-edit {
          background: rgba(70, 139, 232, 0.146);
          opacity: 0.6;
          &:hover {
            background: rgba(0, 0, 0, 0.6);
            box-shadow: none;
          }
        }
        svg {
          fill: var(--#{$variable-prefix}background-white);
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      .icon-wrap {
        opacity: 1;
      }
    }
  }
}

//  binsu css on 10/11/21
.theme .panel-full-header {
  box-shadow: 0 0 0.5rem 0.125rem rgba(240, 239, 239, 0.75);
  -webkit-box-shadow: 0 0 0.5rem 0.125rem rgba(240, 239, 239, 0.75);
  -moz-box-shadow: 0 0 0.5rem 0.125rem rgba(240, 239, 239, 0.75);
}
.theme .panel-full-header .create-btn {
  box-shadow: none;
}
.cm-wrapper {
  // min-height: calc(100% - 3.5rem);
  // padding:3% 6% 1% 4%;
  padding: 2.5rem 3.875rem 2.5rem 3.75rem;
  height: calc(100vh - 3.5rem);
}
.cm-header {
  .cm-caption {
    font-size: 1.75rem;
    line-height: 2.125rem;
    font-weight: 600;
    color: $black-font;
    @media (max-width: 767px) {
      font-size: 0.938rem;
    }
  }
  .cm-subhead {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: $panel-grey-txt;
    @media (max-width: 767px) {
      font-size: 0.688rem;
    }
  }
}

.cm-blk {
  min-height: calc(100vh - 12.5rem);
}
.cm-left-blk {
  width: 16%;
  background-color: var(--#{$variable-prefix}pale-gray);
  .cm-add-mod {
    border-top: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  }
  .cm-mod-wrapper {
    min-height: calc(100vh - 14.25rem);
    max-height: calc(100vh - 14.25rem);
    overflow-y: auto;
    scrollbar-color: var(--#{$variable-prefix}scrollbar-thumb-color) var(--#{$variable-prefix}scrollbar-track-color);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      height: 0.313rem;
      width: 0.313rem;
    }

    &::-webkit-scrollbar-track {
      background: var(--#{$variable-prefix}scrollbar-track-color);
      border-radius: 0.625rem;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--#{$variable-prefix}scrollbar-thumb-color);
      border-radius: 0.625rem;
      cursor: pointer;
    }
  }
  .cm-mod-container {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: var(--#{$variable-prefix}charcoal-gray);
    position: relative;
    text-transform: uppercase;
    border-left: 0.125rem solid transparent;
    padding: 1rem 0.313rem;
    text-align: center;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    h3 {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 auto;
    }
    input {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.25rem;
      color: var(--#{$variable-prefix}light-gray-text);
      background: transparent;
      border: none;
      outline: none;
      border-bottom: 0.063rem solid $dark-grey-font;
      width: 100%;
    }
    &.active {
      border-left: 0.125rem solid $theme-blue;
      background-color: var(--#{$variable-prefix}bg-cloud-gray);
      &:hover {
        .cm-del-icon {
          display: block;
        }
      }
    }
  }
}
.cm-mod-container .cm-del-icon {
  @include position(absolute, 47%, auto, 0.5rem, auto);
  transform: translateY(-50%);
  margin-top: 0;
  display: none;
  svg {
    fill: var(--#{$variable-prefix}light-gray-text);
  }
}
.cm-right-blk {
  width: 84%;
  background-color: var(--#{$variable-prefix}bg-pale-silver);
}
.cm-add-label {
  padding: 0.5rem 0.938rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: $panel-grey-txt;
  text-transform: uppercase;
  cursor: pointer;
  .plus {
    // font-size: 1.688rem;
    // font-weight: 100;
    // line-height: 1.25rem;
    // margin-top: -0.25rem;
    padding-right: 0.375rem;
  }
  svg {
    fill: var(--#{$variable-prefix}light-gray-text);
    // fill: red;
  }
}
.cm-slide-blk {
  height: 3.25rem;
  background-color: var(--#{$variable-prefix}bg-pale-silver);
  border-bottom: 0.188rem solid var(--#{$variable-prefix}bg-cloud-gray);
  .cm-slide-wrapper {
    height: 3.25rem;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-color: var(--#{$variable-prefix}scrollbar-thumb-color) var(--#{$variable-prefix}scrollbar-track-color);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      height: 0.313rem;
      height: 0.25rem;
    }

    &::-webkit-scrollbar-track {
      background: var(--#{$variable-prefix}scrollbar-track-color);
      border-radius: 0.625rem;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--#{$variable-prefix}scrollbar-thumb-color);
      border-radius: 0.625rem;
      cursor: pointer;
    }
    .cm-slide-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 0.188rem solid transparent;
      cursor: pointer;
      font-weight: 600;
      &.active {
        color: var(--#{$variable-prefix}nightshade-gray);
        border-color: $theme-blue;
        &:hover {
          .cm-del-icon {
            display: block;
          }
        }
      }
    }
  }
}
.cm-screen-blk {
  border-bottom: 0.063rem solid var(--#{$variable-prefix}pale-gray);
  .cm-screen-wrapper {
    overflow-x: auto;
    scrollbar-color: var(--#{$variable-prefix}scrollbar-thumb-color) var(--#{$variable-prefix}scrollbar-track-color);
    scrollbar-width: thin;
    .cm-screen-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0.625rem;
      cursor: pointer;
      font-weight: 400;
      border-bottom: 0.188rem solid transparent;
      &.active {
        color: var(--#{$variable-prefix}nightshade-gray);
        border-bottom: 0.188rem solid var(--#{$variable-prefix}cerulean-blue);
        &:hover {
          .cm-del-icon {
            display: block;
          }
        }
      }
    }
    &::-webkit-scrollbar {
      height: 0.313rem;
      height: 0.25rem;
    }

    &::-webkit-scrollbar-track {
      background: var(--#{$variable-prefix}scrollbar-track-color);
      border-radius: 0.625rem;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--#{$variable-prefix}scrollbar-thumb-color);
      border-radius: 0.625rem;
      cursor: pointer;
    }
  }
}
.cm-gen-spec {
  min-width: 7.188rem;
  width: 7.188rem;
  position: relative;
  p {
    max-width: 4.688rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  input {
    font-size: 0.875rem;
    font-weight: 600;
    color: $dark-grey-font;
    line-height: 1.25rem;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 0.063rem solid $dark-grey-font;
    width: 100%;
  }
}
.panel-label {
  font-size: 0.875rem;
  font-weight: 400;
  color: $panel-grey-txt;
  line-height: 1.25rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cm-audscript-blk {
  padding: 1rem;
}
.panel-txtarea-blk {
  .panel-txtarea {
    @include border-radius(0.313rem);
    border: none;
    width: 100%;
    height: 5rem;
    max-height: 5rem;
    background-color: var(--#{$variable-prefix}bg-cloud-gray);
    font-size: 0.875rem;
    font-weight: 300;
    color: rgba($black-font, 0.8);
    line-height: 1.25rem;
    padding: 0.938rem !important;
    resize: none;
    border: 0.063rem solid transparent;
    scrollbar-color: var(--#{$variable-prefix}scrollbar-thumb-color) var(--#{$variable-prefix}scrollbar-track-color);
    scrollbar-width: thin;
    &::placeholder {
      color: rgba($panel-grey-txt, 0.5);
    }
    &::-webkit-scrollbar {
      height: 0.313rem;
      width: 0.313rem;
    }

    &::-webkit-scrollbar-track {
      background: var(--#{$variable-prefix}scrollbar-track-color);
      border-radius: 0.625rem;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--#{$variable-prefix}scrollbar-thumb-color);
      border-radius: 0.625rem;
      cursor: pointer;
    }
    &:focus {
      border: 0.063rem solid $theme-blue;
    }
  }
  .panel-count {
    font-size: 0.875rem;
    font-weight: 300;
    color: rgba($black-font, 0.8);
    line-height: 1.25rem;
  }
}

.cm-ost-blk {
  padding: 1rem;
  .panel-txtarea {
    height: 6.25rem;
    max-height: 6.25rem;
  }
}

.cm-full-lst-blk {
  border-bottom: 0.063rem solid var(--#{$variable-prefix}pale-gray);
  .cm-full-lst-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-color: var(--#{$variable-prefix}scrollbar-thumb-color) var(--#{$variable-prefix}scrollbar-track-color);
    scrollbar-width: thin;
    .cm-full-lst-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.625rem 0;
      cursor: pointer;
      border-bottom: 0.063rem solid transparent;
      position: relative;
      margin-right: 0.188rem;
      padding-right: 0.625rem;
      &.active {
        color: var(--#{$variable-prefix}nightshade-gray);
        border-color: $theme-blue;
        &:hover {
          .cm-del-icon {
            display: block;
          }
        }
      }
    }
    &::-webkit-scrollbar {
      height: 0.313rem;
      height: 0.25rem;
    }

    &::-webkit-scrollbar-track {
      background: var(--#{$variable-prefix}scrollbar-track-color);
      border-radius: 0.625rem;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--#{$variable-prefix}scrollbar-thumb-color);
      border-radius: 0.625rem;
      cursor: pointer;
    }
  }
}
.cm-del-icon {
  // @include position(absolute,45%,auto,0.313rem,auto);
  font-size: 1.688rem;
  font-weight: 300;
  line-height: 1.25rem;
  color: $panel-grey-txt;
  margin-top: -0.125rem;
  // transform: translateY(-50%);
  display: none;
  svg {
    fill: var(--#{$variable-prefix}light-gray-text);
  }
}
.cm-full-lst-container.active .cm-del-icon {
  color: $black-font;
}
.cm-vs-blk {
  padding: 1rem;
  .cm-vs-wrapper {
    background-color: var(--#{$variable-prefix}pale-gray);
    // padding:1.438rem 1.875rem;
    padding: 1.25rem 0 1.25rem 1.563rem;
  }
}

// navtab selector styles
.navtab-selector {
  .nav-tabs {
    border-bottom: none !important;
  }
  .nav-item {
    margin-right: 1.25rem;
  }
  .nav-link {
    font-size: 0.813rem;
    font-weight: 500;
    padding: 1.25rem 0;
    text-align: center;
    color: var(--#{$variable-prefix}white-color);
    border: none;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    &.active {
      position: relative;
      background-color: unset;
      border: 0;
      .cm-vs-icon-holder {
        border-color: $theme-blue;
        color: $theme-blue;
      }
      .tick-active {
        display: block;
      }
      .cm-asset-name {
        color: $black-font;
      }
      .cm-asset-icon svg {
        stroke: $theme-blue;
      }
    }
  }
}
.cm-vs-icon-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.125rem;
  height: 3.125rem;
  color: rgba($dark-grey-font, 0.6);
  @include border-radius(50%);
  border: 0.125rem solid rgba($dark-grey-font, 0.6);
}
.cm-asset-icon svg {
  stroke: rgba($dark-grey-font, 0.6);
}
.cm-asset-name {
  font-size: 0.875rem;
  font-weight: 400;
  color: $panel-grey-txt;
  line-height: 1.25rem;
}
.cm-vs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .tick-active {
    position: absolute;
    display: none;
  }
}
.asset-txtarea {
  .panel-txtarea {
    max-height: 7.875rem;
    height: 7.875rem;
  }
}
// .cm-illus-blk .cm-full-lst-container{width:7.813rem;}
.cm-a-space {
  width: 8.125rem;
  min-width: 8.375rem;
}
.add-tag-blk {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 7.875rem;
  max-height: 7.875rem;
  overflow-y: auto;
  padding: 0.938rem;
  background-color: var(--#{$variable-prefix}bg-cloud-gray);
  @include border-radius(0.313rem);
}
.add-tag-container {
  display: flex;
  justify-content: space-between;
  background-color: $theme-blue;
  color: var(--#{$variable-prefix}white-color);
  padding: 0 0.375rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  scrollbar-color: var(--#{$variable-prefix}scrollbar-thumb-color) var(--#{$variable-prefix}scrollbar-track-color);
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    height: 0.313rem;
    width: 0.313rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--#{$variable-prefix}scrollbar-track-color);
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--#{$variable-prefix}scrollbar-thumb-color);
    border-radius: 0.625rem;
    cursor: pointer;
  }
  .tag-name {
    font-size: 0.75rem;
    line-height: 1.75rem;
    font-weight: 200;
    padding-right: 0.313rem;
  }
  .tag-close {
    font-size: 1.438rem;
    line-height: 1.563rem;
    font-weight: 100;
    cursor: pointer;
  }
}
.add-tag-placeholder {
  font-size: 0.875rem;
  font-weight: 300;
  padding-top: 0.313rem;
  cursor: default;
  color: rgba($panel-grey-txt, 0.5);
}
.cm-style-blk {
  padding: 1rem;
  .cm-style-wrapper {
    background-color: var(--#{$variable-prefix}pale-gray);
    padding: 1.438rem 2.063rem;
  }
}
.cm-uploaded-logo {
  width: 11.938rem;
  height: 6.75rem;
  overflow: hidden;
  @include border-radius(0.375rem);
}
.cm-style-spec {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 300;
  color: $dark-grey-font;
}
.cm-style-upload-holder {
  padding-left: 2.813rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-left: 0.125rem solid rgba($dark-grey-font, 0.4);
  @media screen and (max-width: 991px) {
    padding-left: 1.563rem;
  }
  .cm-brand {
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: -ms-fit-content;
    &:hover {
      .cm-st-upload-edit {
        display: block;
      }
    }
  }
  .cm-brand-close {
    display: flex;
    align-items: center;
    justify-content: center;
    @include position(absolute, -0.375rem, auto, -0.375rem, auto);
    background-color: $theme-blue;
    width: 2.438rem;
    height: 2.438rem;
    @include border-radius(50%);
    font-size: 2.063rem;
    font-weight: 100;
    line-height: 1.438rem;
    color: var(--#{$variable-prefix}white-color);
    box-shadow: 0 0 0.625rem 0.063rem rgba(70, 139, 232, 0.21);
    cursor: pointer;
    z-index: 99;
  }
}
.cm-style-left-part {
  flex: 0 0 66%;
  max-width: 66%;
}
.cm-style-right-part {
  flex: 0 0 34%;
  max-width: 34%;
}
.cm-st-upload-edit {
  display: none;
  @include position(absolute, 0, 0, 0, 0);
  background: rgba(70, 139, 232, 0.806);
  cursor: pointer;
  box-shadow:
    inset 0.35rem -0.35rem 0.35rem rgba(53, 106, 176, 0.146),
    inset -0.35rem 0.35rem 0.35rem rgba(255, 255, 255, 0.146);
  .cm-st-edit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.course-scroller {
  max-height: calc(100vh - 18.5rem);
  overflow-y: auto;
  scrollbar-color: var(--#{$variable-prefix}scrollbar-thumb-color) var(--#{$variable-prefix}scrollbar-track-color);
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    height: 0.313rem;
    width: 0.313rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--#{$variable-prefix}scrollbar-track-color);
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--#{$variable-prefix}scrollbar-thumb-color);
    border-radius: 0.625rem;
    cursor: pointer;
  }
}
.cm-st-gallery {
  // min-height: calc(100vh - 15.625rem);
  height: 350px;
  overflow: auto;
}
.cm-gallery-wrapper {
  margin: 1.563rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: row dense;
  grid-gap: 0.938rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  .cm-gallery-container {
    @include border-radius(0.375rem);
    overflow: hidden;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    img {
      height: 100%;
      max-height: 100%;
      object-fit: fill;
    }
    .cm-gallery-menus {
      display: none;
    }
    &:hover {
      .cm-gallery-menus {
        display: block;
      }
    }
  }
  .cm-gal-menu-left {
    display: flex;
    align-items: center;
    justify-content: center;
    @include position(absolute, 0, 0, 50%, 0);
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0.375rem 0 0 0.375rem;
  }
  .cm-gal-menu-right {
    display: flex;
    align-items: center;
    justify-content: center;
    @include position(absolute, 0, 50%, 0, 0);
    background: rgba($theme-blue, 0.8);
    border-radius: 0 0.375rem 0.375rem 0;
    box-shadow:
      inset 0.35rem -0.35rem 0.35rem rgba(53, 106, 176, 0.146),
      inset -0.35rem 0.35rem 0.35rem rgba(255, 255, 255, 0.146);
  }
}
.cm-color-picker {
  margin-top: 1.25rem;
  margin-bottom: 0.938rem;
  // width: 55%;
  padding-right: 1.563rem;
  .label-block {
    flex: 0 0 6.25rem;
    max-width: 6.25rem;
    @media screen and (max-width: 1199px) {
      flex: 0 0 3.125rem;
      max-width: 3.125rem;
    }
  }
  .field-block {
    flex: 1;
    .Regular {
      width: 65%;
    }
    .size {
      width: 35%;
    }
  }
  .edit-label {
    color: rgba($panel-grey-txt, 0.5);
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    margin-bottom: 0;
  }
}
.color-picker .color-palette i svg {
  fill: $panel-grey-txt;
}

// 15-11-21
.pb-header {
  min-height: 2.5rem;
  .pb-filter-label {
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--#{$variable-prefix}light-gray-text);
    line-height: 1.25rem;
    white-space: nowrap;
  }
}
.pb-filter-holder {
  .custom-select {
    width: 100%;
    height: 2.5rem;
    .form-field {
      height: 100%;
      font-size: 0.875rem;
      font-weight: 400;
    }
    &::after {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.srch-icon {
  @include position(absolute, 50%, 0.938rem, auto, auto);
  transform: translateY(-50%);
  svg {
    width: 1.438rem;
    height: auto;
    stroke: rgba($black-font, 0.7);
  }
}
.pb-srch-customiser {
  @include border-radius(0.125rem);
  border: 0.031rem solid var(--#{$variable-prefix}chill-gray);
  height: 2.5rem;
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: -ms-fit-content;
  // width:58%;
  .srch-holder {
    position: relative;
    width: 50%;
    &::after {
      content: '';
      @include position(absolute, 0.313rem, auto, 0, 0.313rem);
      width: 0.063rem;
      background-color: var(--#{$variable-prefix}chill-gray);
    }
  }
  @media (max-width: 991px) {
    width: auto;
    margin-bottom: 0.625rem;
  }
}
.render-search-bar {
  @include border-radius(0.125rem);
  border: 0.031rem solid var(--#{$variable-prefix}chill-gray);
  height: 2.5rem;
  width: fit-content;
  .srch-holder {
    position: relative;
    width: 100%;
  }
  @media (max-width: 991px) {
    width: auto;
    margin-bottom: 0.625rem;
  }
}
.srch-inpt-holder {
  padding-left: 2.688rem;
  .srch-input {
    font-size: 0.875rem;
    font-weight: 400;
    color: $panel-grey-txt;
    padding: 0.375rem !important;
    border: 0;
    padding-right: 2rem !important;
    &::placeholder {
      color: rgba($panel-grey-txt, 0.7);
    }
  }
}
.srch-ft-selector {
  width: 50%;
  padding: 0.375rem;
  .custom-select {
    &::after {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .form-select {
    @include border-radius(0);
    border: 0;
    min-height: 2.375rem;
    font-size: 0.875rem;
    font-weight: 400;
  }
}
.pb-tbl-blk {
  margin: 0.375rem 0;
  @media screen and (max-width: 991px) {
    min-width: 75rem;
  }
  .pb-tbl-wrapper {
    @include border-radius(0.125rem);
    margin: 0.5rem 0;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 2fr;
    grid-auto-flow: row dense;
    grid-auto-rows: minmax(2.5rem, auto);
    grid-gap: 0.625rem;
    background: var(--#{$variable-prefix}bg-pale-silver);
    border: 0.031rem solid rgba(var(--#{$variable-prefix}chill-gray), 0.5);
  }

  .pb-head-label {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--#{$variable-prefix}light-gray-text);
    line-height: 1.25rem;
    padding: 0.625rem 1.5rem 0.625rem 0.625rem;
    text-transform: capitalize;
  }
  .pb-data-label {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--#{$variable-prefix}light-gray-text);
    padding: 0.625rem 1.5rem 0.625rem 0.625rem;
  }
  .pb-st-count {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.25rem;
    width: 4.688rem;
    padding-left: 0.938rem;
  }
  .pb-progress-bar {
    position: static;
    width: calc(100% - 4.688rem);
    background-color: var(--#{$variable-prefix}chill-gray);
    height: 0.313rem;
    .progress-bar {
      background: linear-gradient(90deg, #e5e7eb 0%, #e5e7eb 35.94%, #56c4f5 68.75%, #c156c2 100%);
      @include border-radius(6.25rem);
    }
    &.failed {
      .progress-bar {
        background: $theme-red;
      }
    }
  }
  .pb-data-tbl {
    min-height: 3.313rem;
    .pb-data-label {
      padding: 1rem 1.5rem 0.625rem 1rem;
    }
  }
  .pb-status-indicator {
    width: calc(100% - 6.25rem);
  }
  .pb-status-set {
    padding-left: 1.75rem;
  }
  .pb-stat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    svg {
      fill: var(--#{$variable-prefix}dark-navy);
      cursor: pointer;
    }
    &.download svg {
      stroke: var(--#{$variable-prefix}dark-navy);
    }
  }
  .failed-status {
    font-size: 0.625rem;
    line-height: 1.125rem;
    font-weight: 700;
    color: $theme-red;
    text-transform: uppercase;
    padding-left: 0.188rem;
    padding-top: 0.313rem;
  }
}

.fs-vdo-blk {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--#{$variable-prefix}background-white);
  .fs-vdo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .fs-vdo-container {
    width: 45%;
    @media (max-width: 991px) {
      width: auto;
      padding: 0 0.938rem;
    }
  }
}

@media screen and (max-width: 575px) {
  .col-xs-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
.cp-container {
  @media (max-width: 767px) {
    width: 100% !important;
  }
}

// 16/11/21
.srch-temp-blk {
  padding: 1% 3%;
  min-height: calc(100% - 3.5rem);
  .srch-temp-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.031rem solid var(--#{$variable-prefix}chill-gray);
    height: 2.5rem;
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: -ms-fit-content;
    margin: 0 auto;
  }
  .srch-holder {
    position: relative;
    .srch-input {
      background-color: transparent;
    }
  }
  .srch-icon {
    left: 0;
  }
  .srch-inpt-holder {
    padding-left: 2.188rem;
    .srch-input {
      max-width: 12.5rem;
    }
  }
}

// pagination
.pagination-holder {
  margin: 1.25rem;
  li.pagination-nav {
    @include border-radius(0.188rem);
    background: var(--#{$variable-prefix}pale-gray);
    border: 0.063rem solid transparent;
    width: 1.875rem;
    height: 1.875rem;
    text-align: center;
    line-height: 1.813rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: $panel-grey-txt;
    margin-right: 0.438rem;
    cursor: pointer;
    &:hover {
      color: $theme-blue;
    }
    &.active {
      border: 0.063rem solid $theme-blue;
      color: $theme-blue;
    }
    &.pagin-prev {
      svg {
        fill: $panel-grey-txt;
      }
      &:hover svg {
        fill: $theme-blue;
      }
      &.disabled svg {
        fill: rgba($dark-grey-font, 0.6);
      }
    }
    &.pagin-next {
      svg {
        fill: $panel-grey-txt;
      }
      &:hover svg {
        fill: $theme-blue;
      }
      &.disabled svg {
        fill: rgba($dark-grey-font, 0.6);
      }
    }
  }
}
.temp-card {
  img {
    width: 100%;
  }
  &::before {
    display: none;
    content: '';
    background: $theme-blue;
    @include position(absolute, 0, 0, 0, 0);
    z-index: 0;
    opacity: 0.5;
  }
  &::after {
    display: none;
    content: '';
    background: url(../../img/right-bar-bg.png) no-repeat;
    height: 6.875rem;
    width: 6.5rem;
    position: absolute;
    bottom: 0;
    z-index: 0;
    right: 0;
    background-size: cover;
    opacity: 0.2;
  }
}
.temp-card:hover::before {
  display: block;
}
.temp-card:hover::after {
  display: block;
}
.temp-part {
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
  }
}
.temp-left-part {
  flex: 0 0 63%;
  max-width: 63%;
  .vdo-speaker svg {
    fill: $dark-grey-font;
  }
  .play-btn svg {
    fill: $dark-grey-font;
    margin-top: -0.313rem;
  }
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.temp-right-part {
  flex: 0 0 37%;
  max-width: 37%;
  .temp-cap {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.438rem;
    margin: 0.25rem 0 0.625rem 0;
    color: $black-font;
    text-transform: uppercase;
  }
  .temp-subcap {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $panel-grey-txt;
    padding-right: 0.313rem;
  }
  .rSlider {
    margin: 0.75rem 0 0.625rem;
  }
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0 !important;
    margin-top: 0.75rem;
  }
}
.dev-spec-wrapper {
  margin-bottom: 0.5rem;
  width: 88%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row dense;
  grid-auto-rows: (minmax(6.25rem, auto));
  .dev-spec-container {
    &.active {
      .dev-spec-label {
        color: $black-font;
      }
      &:nth-child(1) .dev-spec-icon {
        background: url(../../img/Safari-active.png);
      }
      &:nth-child(2) .dev-spec-icon {
        background: url(../../img/Macbook-active.png);
      }
      &:nth-child(3) .dev-spec-icon {
        background: url(../../img/Pixel-active.png);
      }
    }
    &:nth-child(1) .dev-spec-icon {
      background: url(../../img/Safari.png);
    }
    &:nth-child(2) .dev-spec-icon {
      background: url(../../img/Macbook.png);
    }
    &:nth-child(3) .dev-spec-icon {
      background: url(../../img/Pixel.png);
    }
  }
  .dev-spec-icon {
    width: 3.75rem;
    height: 3.75rem;
    margin: 0 auto;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }
  .dev-spec-label {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $panel-grey-txt;
    text-align: center;
  }
}

.back-btn {
  position: absolute;
  left: 1.875rem;
  top: 4.375rem;
  svg {
    fill: $slate-grey;
  }
}

.loader {
  position: absolute;
  /* background: #00000078; */
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 47%);
}

.srch-input::-webkit-search-cancel-button {
  cursor: pointer;
}
.search-close {
  position: absolute;
  right: 0.375rem;
  cursor: pointer;
  top: 0.438rem;
  background: none;
  box-shadow: none;
  border: none;
}
.search-close svg {
  fill: var(--#{$variable-prefix}cerulean-blue);
  width: 1rem;
}

.srch-input:not(:valid) ~ .search-close {
  display: none;
}
.pro-temp-container {
  padding-left: 0.313rem;
  .MuiSvgIcon-root {
    fill: var(--#{$variable-prefix}cerulean-blue);
  }
  .cm-header {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--#{$variable-prefix}charcoal-gray);
    text-align: center;
  }
}

.custom-number input {
  background: var(--#{$variable-prefix}background-white);
  border: 0.031rem solid var(--#{$variable-prefix}chill-gray);
  color: var(--#{$variable-prefix}light-gray-text);
  border: none;
  min-height: 1.625rem;
}

// 23/12/21
.inp-txt {
  color: var(--#{$variable-prefix}light-gray-text);
  padding: 0.25rem 0.5rem !important;
  &::placeholder {
    color: var(--#{$variable-prefix}light-gray-text);
  }
}

.nw-color-pick {
  .custom-color-picker {
    -webkit-appearance: none;
    padding: 0;
    border: none;
    border-radius: 50%;
    width: 1.625rem;
    height: 1.625rem;
    background: none;
  }
  .custom-color-picker::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
    padding: 0;
  }
  .custom-color-picker::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 50%;
    padding: 0;
  }
  .eye-drop {
    pointer-events: none;
  }
}

.tab-assets {
  border-bottom: 0.063rem solid var(--#{$variable-prefix}pale-gray);
  .nav-tabs {
    width: fit-content;
    width: -moz-fit-content;
    width: -ms-fit-content;
    width: -webkit-fit-content;
    margin: auto;
  }
  .nav-link {
    &.active {
      border-bottom: 0.188rem solid var(--#{$variable-prefix}primary-color);
    }
  }
}
.aspt-upload-holder {
  border: 0.063rem solid #8e8e8e;
  border-radius: 0.25rem;
  padding: 1.875rem 0 2.25rem;
  width: 24.375rem;
  max-width: 24.375rem;
  min-height: 9.813rem;
  max-height: 9.813rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  .aspt-container {
    max-width: 12.5rem;
    margin: 0 auto;
  }
  .cc-csv-btn .btn {
    border-radius: 0.25rem;
  }
}
.aspt-blk {
  padding: 1.5rem 0;
}
.tab-bg-switching {
  min-height: calc(100vh - 3.5rem);
}
.inner-tab-assets {
  border-bottom: 0.063rem solid var(--#{$variable-prefix}pale-gray);
  .nav-link {
    &.active {
      border-bottom: 0.188rem solid $theme-blue;
    }
  }
}
.tab-bg-switching .cm-st-gallery {
  min-height: auto;
}

.templt-radio-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.313rem 0;
  cursor: pointer;
}
.templt-label {
  font-size: 0.813rem;
  font-weight: 500;
  color: var(--#{$variable-prefix}dark-navy);
  padding-left: 0.313rem;
  padding-right: 1.438rem;
}
.stok-selct {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0.563rem 0;
}
.cm-icon {
  margin-right: 0.563rem;
  svg {
    fill: none !important;
  }
  &.upload-icon {
    svg {
      g {
        stroke: #1c1b1f;
      }
    }
  }
  &.upload svg {
    width: 1.25rem;
    height: auto;
    fill: var(--#{$variable-prefix}dark-navy);
  }
  &.assets svg {
    margin-top: 0.125rem;
    width: 1rem;
    height: auto;
    fill: var(--#{$variable-prefix}dark-navy);
  }
  &.stock svg {
    width: 1.125rem;
    height: auto;
    fill: var(--#{$variable-prefix}dark-navy);
  }
}

.replace-modal {
  .stock-repo {
    min-height: 21.875rem;
    max-height: 21.875rem;
  }
}
.stock-repo .dot-loading,
.cm-st-gallery .dot-loading {
  background: #fff;
  padding: 0 10px;
  border-radius: 25px;
  border: solid 2px #ccc;
  position: absolute;
  left: 50%;
  top: calc(100% - 120px);
  margin: 0 0 0 -20px;
  z-index: 99;
}
.stock-repo .dot-loading img,
.cm-st-gallery .dot-loading img {
  border-radius: 10px;
}
.pb-stat-icon {
  svg {
    fill: var(--#{$variable-prefix}dark-navy);
    cursor: pointer;
  }
}

.image-height-row {
  height: 25rem;
}

.replace-of-replace-modal {
  margin: 0 !important;
  max-width: 100% !important;
}

.offcanvas-backdrop {
  background-color: var(--#{$variable-prefix}bg-dark-navy);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1040;
  &.show {
    opacity: 0.5;
  }
}

.project-compose-option {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 51.875rem;
  margin: 0 auto;
  padding: 1.875rem 0;
  flex-wrap: wrap;
  gap: 1.875rem;

  .button-wrap {
    background-color: var(--#{$variable-prefix}background-white);
    width: 100%;
  }
}

.project-compose-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  max-width: 360px;
  padding: 24px 40px;
  position: relative;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px #f1f1f1;
  box-shadow:
    0px -1px 3px 0px #f1f1f1,
    0px 1px 2px 0px #f1f1f1;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  &:hover {
    box-shadow: 0px 8px 8px -4px #e3e3e3;
    border-radius: 0.5rem;

    box-shadow:
      0px -4px 8px 0px #e3e3e3,
      0px 20px 24px -4px #f1f1f1,
      0px 8px 8px -4px #e3e3e3;

    .project-compose-btn {
      background-color: #f0edf6;
    }
    p {
      color: var(--#{$variable-prefix}primary-color);
    }
    .round-badge {
      background-color: #6750a4;
      color: #fff;
    }
  }

  .round-badge {
    position: absolute;
    right: 0.98956rem;
    top: -0.6875rem;
  }

  p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.005rem;
    svg {
      width: 21px;
      height: 21px;
    }
  }
}

.project-compose-btn {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #f7f7f7;
  border: transparent;
  color: #000;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  &:hover {
    background: #fff;
    color: var(--#{$variable-prefix}primary-color);
    border-color: var(--#{$variable-prefix}primary-color);
  }
}

.fs-13 {
  font-size: 0.813rem;
}

.preview-icons-test-admin span {
  border: 1px solid #c5b9b9;
  padding: 5px;
  svg {
    width: 40px;
    height: 40px;
  }
}

.light-border-padding {
  padding: 1rem;
  border-radius: 0.25rem;
  border: 0.063rem solid var(--bs-bg-cloud-gray);
}

.vidayo_no_item {
  width: 90%;
  height: 50vh;
  margin: auto;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.plan-type-select {
  z-index: 100;
}

.contact-sales-modal input:read-only {
  background-color: rgb(246, 246, 246);
}

.vidayo-pro-tinymce-editor {
  p {
    margin-bottom: 0.5rem;
  }

  strong {
    font-weight: revert;
  }

  ul,
  ol {
    list-style: revert;
    padding-left: 2rem;
  }
}

.video-cover-holder {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  max-width: 440px;
  margin: 1.5rem auto 0;
  padding: 0 1.55rem;

  .video-cover-wrap {
    width: 61px;
    height: 44px;
    border: 0.063rem solid #8e8e8e;
    border-radius: 0.25rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .text-files {
    color: #515151;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
    flex: 1;
  }
}

.aspt-upload-wrapper {
  position: relative;
  display: flex;
  max-width: 440px;
  margin-bottom: 1.5rem;
  padding: 1.875rem 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 0.063rem solid #8e8e8e;
  background-color: #d9d9d9;
  color: #393939;
  &:hover {
    background-color: #6750a4;
    color: #fff;
    .upload-video-wrap,
    .upload-description {
      color: #fff;
    }
  }
  .aspt-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
  }
  .upload-video-wrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.625rem;
    color: #393939;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
  }
  .upload-icon {
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .upload-description {
    color: #393939;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
  }
}
.video-tag-input {
  max-width: 390px;
  width: 100%;
  margin-bottom: 1.5rem;
}

.add-new-inner {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin: 0 0 1rem;
  h4 {
    font-size: 15px;
    color: #79747e;
    font-weight: 600;
  }
  span {
    display: block;
    color: #515151;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
    flex: 1 1;
  }
}

.rearrange-segment-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  max-height: 26rem;
  overflow-y: auto;
  .carousel-block {
    width: 9.375rem;
    margin: 0.125rem;
  }
  .screen-thumbnail-items {
    cursor: move;
  }
  .txt {
    padding: 0 0.25rem;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    font-weight: 600;
  }
  .video-play-btn {
    display: none !important;
  }
}

.dragItem {
  &:hover {
    .screen-thumbnail-items {
      border-style: dashed;
      cursor: move;
    }
  }
}
.dragTop {
  display: block;
  height: 20px;
}
.dragDown {
  display: block;
  height: 20px;
}

/* Trim Video Component */
.thin-progress-bar {
  height: 0.5rem;
  border-radius: 0;
}
.videoTrim {
  display: flex;
  flex-direction: column;
  .MuiSlider-valueLabel {
    transform: translateY(-100%) scale(1);
  }
  video {
    max-height: 300px;
    object-fit: scale-down;
  }
  .videContainer {
    position: relative;
    button {
      margin: 0px;
      padding: 0px;
      height: 35px;
      width: 35px;
      position: absolute;
      left: calc(50% - 17px);
      top: calc(50% - 17px);
      border: none;
      background: #f5f5f5;
      border-radius: 35px;
      svg {
        width: 40%;
        height: 40%;
        position: relative;
        top: -1px;
        left: 2px;
      }
    }
  }
}
.MuiSlider-thumb[data-index='1'] {
  background-color: var(--bs-primary-color);
}
.pdfSpinner {
  position: absolute;
  width: 100%;
  height: calc(100vh - 375px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -24px;
}
.pdfDoc {
  min-height: calc(100vh - 262px);
  max-height: calc(100vh - 262px);
}
.disableSegment {
  opacity: 0.5;
  pointer-events: none;
}

.small-text {
  font-size: 0.875rem;
}


.sidebar-header-info {
  width: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: var(--#{$variable-prefix}bg-dark-navy);
  border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-dark-gray);
  color: var(--#{$variable-prefix}white-color);
  display: flex;
  padding: 0.75rem 1rem;
  align-items: flex-start;
  gap: 0.938rem;
  z-index: 1;
  &:hover {
    color: var(--#{$variable-prefix}white-color);
  }
  .icon-wrap {
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    background: var(--#{$variable-prefix}primary-color);
  }
  .info-wrap {
    overflow: hidden;
    h4 {
      margin: 0 0 0.25rem;
      color: var(--#{$variable-prefix}white-color);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.005rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    span {
      display: block;
      color: var(--#{$variable-prefix}primary-color);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.005rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.draft-video-holder {
  display: flex;
  align-items: center;
  gap: .5rem;
  padding: 0 .9rem;
  cursor: pointer;
  svg {
    width: 16px;
  }
  .draft-title {
    color: #515151;
    font-size: 0.875rem;
    font-weight: 500;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}