.process-document-content {
  position: relative;
  z-index: 1;
  .screen-sidebar {
    padding: 1.25rem 0 0;
  }
  .document-sidebar-content {
    padding: 0;
    flex-direction: row;
    gap: inherit;
    .docs-process-header {
      padding-top: 1.25rem;
    }
  }
}
.docs-process-header {
  display: flex;
  padding: 0 2rem 1.25rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.063rem solid #f1f1f1;
  h4 {
    color: #000 !important;
    font-size: 1.5rem !important;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
  }
  .chat-title-wrap {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}

.docs-progress-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 1rem;
  width: 100%;
  h4 {
    margin: 0;
    color: #585858;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
  progress {
    width: 100%;
    accent-color: #6750a4;
    height: 1.625rem;
  }
  progress::-webkit-progress-bar {
    background-color: rgb(178, 255, 255);
  }

  progress::-webkit-progress-value {
    background-color: #04af2f;
  }
}

.media-form-component {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.25rem;
  .custom-media-input {
    display: flex;
    max-width: 42.3125rem;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
  }
  .media-input-container {
    display: flex;
    width: 3.75rem;
    height: 5.6875rem;
    min-width: 3.75rem;
    max-width: 8.6875rem;
    align-items: flex-start;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.docs-card-wrapper {
  padding: 0.5rem 1.55rem;
  border-radius: 0.5rem;
  border: 0.063rem solid #d9d9d9;
  background-color: #fff;
  cursor: auto;
  .dropdown-toggle::after {
    display: none;
  }
  .card-header {
    flex-wrap: wrap;
  }
  @media (max-width: 991px) {
    .common-dropdown-wrap {
      margin-top: 0;
    }
  }
  .card-body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .editable-content {
    .editable-title,
    .editable-input {
      overflow: hidden;
      color: #585858;
      text-overflow: ellipsis;
      font-weight: 700;
      word-break: break-word;
    }
  }
  .process-video-btn {
    font-size: 1rem;
    font-weight: 400;
  }
  .common-card-arrow-wrap {
    flex-wrap: wrap;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .droppable {
    width: 100%;
    .itemBlock {
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
  }
  .common-dropdown-menu {
    .dropdown-item {
      justify-content: space-between;
    }
  }
}
.segments-docs {
  max-height: calc(100vh - 4.2rem);
  overflow-y: auto;
  scrollbar-color: auto;
  scrollbar-width: auto;
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.5rem;
    cursor: pointer;
  }
}

.docs-info-box {
  display: flex;
  padding: 0.75rem;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.5rem;
  border: 0.063rem solid #b3a7d1;
  background-color: #f0edf6;
  margin-bottom: 1.25rem;
  .info-wrap {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex: 1;
    svg {
      width: 1.25rem;
      height: 1.25rem;
      flex-shrink: 0;
    }
    span {
      display: block;
      color: #000;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
    }
  }
  .cross-icon {
    cursor: pointer;
    svg {
      path {
        fill: #585858;
      }
    }
  }
}

.more-action-dropdown {
  .show {
    .common-dropdown-btn {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .common-dropdown-btn {
    width: auto;
    height: auto;
    border-radius: 0.25rem;
    border: 0.063rem solid #d9d9d9;
    display: flex;
    padding: 0.6rem 0.8rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    color: #8d8d8d;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  .common-dropdown-menu {
    .dropdown-item {
      color: #8d8d8d;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
      justify-content: space-between;
      &:hover,
      &.active {
        background-color: #f0edf6;
        color: #6750a4;
      }
      svg {
        width: 1.375rem;
        height: 1.375rem;
      }
      .arrow-right-icon {
        svg {
          width: 0.875rem;
          height: 0.875rem;
        }
      }
    }
  }
}

.nested-dropdown-menu {
  display: inline-flex;
  padding: 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.375rem;
  background: #fff;
  box-shadow:
    0 -0.063rem 0.188rem 0 #f1f1f1,
    0 0.063rem 0.125rem 0 #f1f1f1;
  .move-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    color: #8d8d8d;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 0.75rem;
  }
}

.dropdown-search-container {
  display: flex;
  padding: 0.75rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 0.063rem solid #d9d9d9;
  background-color: #fff;
  .search-input {
    color: #8d8d8d;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    padding: 0;
    border: transparent;
  }
}

.process-document-left {
  display: flex;
  padding: 1.5rem 1.25rem 1.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  flex: 1 0 0;
  align-self: stretch;
}

.process-title {
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
}

.iframe-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  width: 100%;
  .iframe-content {
    width: 100%;
    min-height: calc(100vh - 15rem);
    overflow-y: auto;
    border: 0.625rem solid #d9d9d9;
    background-color: #d9d9d9;
    iframe {
      width: 100%;
      min-height: calc(100vh - 15rem);
    }
  }
}
.ifram-btn-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  flex-wrap: wrap;
  align-self: stretch;
  .btn {
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    svg {
      width: 1.125rem;
    }
    &.outline-btn {
      padding: 0.6rem 1rem;
    }
    &.bg-process-btn {
      background-color: #4e378a;
      color: #fff;
    }
    &.disabled {
      pointer-events: none;
      background-color: #f1f1f1;
      color: #fff;
    }
  }
}

.drag-wrap {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  flex: 1;
  @media (max-width: 767px) {
    width: 100%;
  }
  .custom-form-floating {
    flex: 1;
  }
}

.drag-holder {
  display: flex;
  align-items: center;
  position: relative;

  .common-dropdown-menu.dropdown-menu {
    left: -1.125rem;
  }
}

.drag-btn {
  display: flex;
  align-items: center;
  width: 0.875rem;
  height: 1.2rem;
  position: absolute;
  left: -1.125rem;
  border-radius: 0.125rem;
  background: #d9d9d9;
  padding: 0;
  border: transparent;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  &::after {
    display: none;
  }
  &:hover {
    .drag-hover {
      opacity: 1;
      display: block;
    }
  }
  .drag-hover {
    opacity: 0;
    width: 8.625rem;
    display: none;
    padding: 0.75rem;
    text-align: center;
    position: absolute;
    right: -5.875rem;
    bottom: -4.5rem;
    border-radius: 0.25rem;
    background: var(--background-fill-pure, #fff);
    color: #585858;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    box-shadow:
      0 -0.063rem 0.188rem 0 #f1f1f1,
      0 0.063rem 0.125rem 0 #f1f1f1;
    z-index: 1;
    transition: all;
    white-space: normal;
    strong {
      font-weight: 600;
    }
  }
}

.screen-process-body {
  max-height: inherit;
}

.screen-process-scroll {
  min-height: calc(100vh - 13.1rem);
  max-height: calc(100vh - 13.1rem);
  overflow-y: auto;
  padding: 1.25rem 1rem 2rem 2rem;
  margin-right: 1rem;

  scrollbar-color: auto;
  scrollbar-width: auto;
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.5rem;
    cursor: pointer;
  }
}

.primary-breadcrumb {
  display: flex;
  padding: 0.8rem 1.25rem;
  align-items: center;
  gap: 0.375rem;
  align-self: stretch;
  background: #fff;
  box-shadow:
    0 -0.063rem 0.188rem 0 #f1f1f1,
    0 0.063rem 0.125rem 0 #f1f1f1;
  .breadcrumb {
    flex-wrap: wrap;
  }
  .common-dropdown-menu {
    @media (max-width: 991px) {
      position: absolute;
    }
  }
  .breadcrumb-item {
    display: flex;
    padding: 0.5rem 0.5rem 0.5rem 0;
    align-items: center;
    gap: 0.375rem;
    color: #585858;
    text-overflow: ellipsis;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    &.active {
      font-weight: 600;
      color: #01003e;
    }
    a {
      font-weight: 400;
      overflow: hidden;
      white-space: nowrap;
      max-width: 11.25rem;
      text-overflow: ellipsis;
      flex: 1;
    }
    span {
      overflow: hidden;
      white-space: nowrap;
      max-width: 11.25rem;
      text-overflow: ellipsis;
    }
  }
  .dropdown-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2625rem;
    overflow: hidden;
    color: #585858;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    text-decoration: none;
  }
}

.docs-process-footer {
  width: 100%;
  display: flex;
  padding: 1rem 2rem;
  justify-content: flex-end;
  gap: 0.75rem;
  background: #fff;
  box-shadow:
    0 -0.063rem 0.188rem 0 #f1f1f1,
    0 0.063rem 0.125rem 0 #f1f1f1;
  .bg-process-btn {
    background-color: #4e378a;
    color: #fff;

    &.disabled {
      pointer-events: none;
      background-color: #f1f1f1;
      color: #fff;
    }
  }
}

.processed-sidebar-content {
  background-color: transparent;
  border: none;
  .docs-process-header {
    border-bottom: none;
    h4 {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      align-self: stretch;
      color: #585858;
      font-size: 1.125rem;
      svg {
        width: 1.1rem;
        height: 1.1rem;
      }
    }
  }
  .screen-process-body {
    margin: 2rem;
    border-radius: 0.5rem;
    border: 0.063rem solid #f1f1f1;
    background-color: #fff;
    &.screen-docs {
      margin: 1.5rem 1.5rem 1.5rem 0;
      .docs-process-header {
        padding: 1rem 1.5rem;
      }
      .screen-process-scroll {
        min-height: calc(100vh - 15.7rem);
        max-height: calc(100vh - 15.7rem);
      }
    }
  }
  .screen-process-scroll {
    min-height: calc(100vh - 21.8rem);
    max-height: calc(100vh - 21.8rem);
  }
  .docs-process-footer {
    box-shadow:
      0 -1px 3px 0 var(--colors-greys-050, #f1f1f1),
      0 1px 2px 0 var(--colors-greys-050, #f1f1f1);
  }
}

.max-limit-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.75rem;
    .bg-primary-btn {
      padding: 0.75rem 1rem;
    }
  }
}
