.subscriptions {
  .subscriptions__header {
    text-align: center;
    margin-block: 2.5rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h3 {
      font-size: clamp(1.375rem, 1.5vw, 2rem);
      color: #515151;
      font-weight: 700;
      margin-bottom: 1.25rem;
    }
    p {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: #515151;
      letter-spacing: 0.001rem;
      @media screen and (min-width: 1200px) {
        width: 70%;
      }
    }
  }
  .interval-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.25rem;
    gap: 0.625rem;
    padding: 0.625rem;
    .switch-button {
      color: #13171f;
      // font-family: Roboto;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.02rem;
      border: none;
      background-color: transparent;
      width: fit-content;
      border-radius: 1.875rem;
      padding: 0.625rem 1.875rem;
      .save {
        color: #13171f;
        // font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.02rem;
      }
    }
    .active {
      color: #fff;
      // font-family: Roboto;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.02rem;
      background-color: #6750a4;
      .save {
        color: #fff;
      }
    }
  }
  .subscriptions__body {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.25rem;
    padding-bottom: 5rem;
  }
  .subscribed {
    border: 0.063rem solid #fff;
    cursor: none;
    &:hover {
      cursor: text;
    }
  }
  .subscribed-label {
    background-color: #00aa3a;
    color: #fff;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.005rem;
    padding: 0.125rem 0.375rem;
    border-radius: 1.25rem;
  }
}
.subcsription-card {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: clamp(18.75rem, 25vw, 20rem);
  padding: 1.25rem;
  border-radius: 0.625rem;
  background-color: #fff;
  border: 0.125rem solid #e5e5e5;
  word-wrap: break-word;
  color: #515151;
  @media (max-width: 1600px) {
    width: clamp(17.625rem, 25vw, 17.625rem);
  }
  &:hover {
    border-color: var(--#{$variable-prefix}primary-color);
    .subscribe-btn {
      background-color: var(--#{$variable-prefix}primary-color) !important;
      color: #fff;
    }
  }
  .name-price {
    border-bottom: 0.063rem solid #d9d9d9;
    margin-bottom: 1.25rem;
    padding-bottom: 1.25rem;
    width: 100%;
    .name-container {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      margin-bottom: 0.625rem;
    }
    .name {
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 400;
      color: #515151;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .price-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.625rem;
      .price {
        // margin-bottom: 1.25rem;
        display: flex;
        align-items: center;
        .currency-price {
          font-size: 1.625rem;
          font-weight: 700;
          color: #13171f;
          @media screen and (min-width: 1600px) {
            font-size: 1.875rem;
          }
        }
        .interval {
          font-size: 1.125rem;
          display: flex;
          align-items: center;
          min-width: fit-content;
        }
      }
      .billed-tag {
        font-size: 0.875rem;
        color: #000;
        font-weight: 600;
      }
    }
    .interval-discount {
      color: var(--#{$variable-prefix}primary-color);
      font-size: 0.875rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        min-height: 0.875rem;
      }
    }
  }
  .lets-talk {
    font-size: 1.625rem;
    font-weight: 700;
    color: #13171f;
    margin-bottom: 0.625rem;
    @media screen and (min-width: 1600px) {
      font-size: 1.875rem;
    }
  }
  .features-container {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1.25rem;
    width: 100%;
    .features-header {
      color: #515151;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 600;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .subcsription_features {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    &.extra-features {
      border-bottom: none;
      padding-top: 1.125rem;
      border-top: 0.063rem solid #d9d9d9;
    }
  }
  button {
    cursor: pointer;
    font-size: 1rem;
    padding: 0.875rem 1.875rem;
    line-height: normal;
    letter-spacing: 0.005rem;

    &:hover {
      background-color: var(--bs-primary-color);
      color: var(--bs-background-white);
    }
  }
  .subscribe-btn {
    width: 100%;
    margin-bottom: 1.25rem;
  }
  .contact-sales-btn {
    width: 100%;
    margin-bottom: 1.25rem;
    border: 0.063rem solid var(--#{$variable-prefix}primary-color);
    &:hover {
      color: var(--#{$variable-prefix}primary-color);
    }
  }
}
.subscribed-plan {
  border: 0.125rem solid #6750a4;
  background: #fff;
  color: #13171f;
  z-index: 1;
  overflow: hidden;
}

.plan-header {
  padding-inline: 2.5rem;
}

.plans-details {
  display: flex;
  flex: 1;
  min-height: calc(100vh - 3.5rem);
  height: 100%;
  // @media screen and (min-width: 1920px) {
  //   padding: unset;
  //   max-width: 90rem;
  //   margin: 0 auto;
  // }
  @media (max-width: 1199px) {
    flex-direction: column-reverse;
    gap: 1rem;

    .selected-plan-mobile-view {
      margin-top: 1.5rem;
      display: flex !important;
      align-items: center;
      gap: 0.8rem;

      .selected-plan-text {
        font-size: 0.75rem;
        color: #6750a4;
        font-weight: 600;
        line-height: 0.875rem;
        border: 0.063rem solid;
        padding: 0.25rem 0.75rem;
        border-radius: 1.25rem;
        display: inline-block;
        border-color: #6750a4;
        background-color: #f6f2ff;
      }
    }

    .plan-title.price-details-title {
      font-size: 1rem;
    }
  }

  .desktop-view .selected-plan-text {
    font-size: 0.75rem;
    color: #6750a4;
    font-weight: 600;
    line-height: 0.875rem;
    border: 0.063rem solid;
    padding: 0.25rem 0.75rem;
    border-radius: 1.25rem;
    display: inline-block;
    border-color: #6750a4;
    background-color: #f6f2ff;
  }

  .price {
    color: #13171f;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: 0.011rem;

    strong {
      color: #13171f;
      // font-family: Roboto;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 0.015rem;
    }
  }
  .plan-title {
    margin: 0;
    color: #1c1c1c;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.015rem;
  }
  .plans-details__right {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 1.875rem;
    padding: 2.5rem 2.5rem 2.5rem 0;
    // width: calc((100% - 3.75rem) / 2);
    flex: 1;
    @media (max-width: 1199px) {
      padding: 0 1.875rem 1.875rem;
      flex-direction: column-reverse;
    }
    @media (max-width: 767px) {
      padding: 0 0 1.875rem;
    }
    .plan-description {
      color: #000;
      // font-family: Roboto;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0.02rem;
      @media (max-width: 767px) {
        font-size: 0.875rem;
      }
    }
    .feature-title {
      color: #13171f;
      // font-family: Roboto;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.011rem;
    }
    .feature-container {
      display: flex;
      align-items: start;
      gap: 1.25rem;
      flex-direction: column;
    }
  }
  .plans-details__left {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2.5rem 0 2.5rem 2.5rem;
    border-left: 0.063rem solid #d9d9d9;
    flex: 1;
    .vidayo-pro-tinymce-editor {
      p {
        margin: 0;
      }
    }
    @media (max-width: 1199px) {
      border: transparent;
      padding: 0 1.875rem 1.875rem;
    }
    @media (max-width: 767px) {
      padding: 0 0 0.625rem;
    }
    .plan-description {
      @media (max-width: 767px) {
        font-size: 0.875rem;
      }
    }
  }
}
.price-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.875rem;
  .price-container {
    border-radius: 0.25rem;
    border: 0.094rem solid #d9d9d9;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    height: 100%;
    .interval-title {
      color: #000;
      // font-family: Roboto;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.011rem;
    }
    .save-btn {
      background-color: var(--#{$variable-prefix}primary-color);
      color: #fff;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.005rem;
      border-radius: 0.25rem;
      padding: 0.375rem;
    }
  }
  .active-interval {
    border-color: var(--#{$variable-prefix}primary-color);
    border-width: 0.125rem;
  }
}
.subscribed-container {
  .plans-title {
    color: #13171f;
    // font-family: Roboto;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.011rem;
    margin-top: 1.875rem;
    margin-bottom: 1.25rem;
  }
  .plans-container {
    display: flex;
    align-items: center;
    gap: 1.875rem;
    flex-wrap: nowrap;
  }
  .questions-text {
    color: #13171f;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.011rem;
    margin-top: 1.875rem;
    a {
      color: var(--Background-color-Secondary, #6750a4);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.005rem;
      text-decoration-line: underline;
    }
  }
}
.dot {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
}
.active-plan {
  background-color: #00aa3a;
  box-shadow: 0 0 4px 0 #00aa3a;
}
.inactive-plan {
  background-color: #ff8a00;
  box-shadow: 0 0 4px 0 #ff8a00;
}
.deleted-plan {
  background-color: #e00000;
  box-shadow: 0 0 4px 0 #e00000;
}
.currency-price {
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
}
.plan-header-container {
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  padding: 0.625rem 2.5rem;
  position: relative;
  border-bottom: 0.063rem solid #d9d9d9;
  .back-button {
    position: absolute;
    left: 1.5rem;
    top: 0.25rem;
  }
  .login-page-logo {
    margin-bottom: unset;
    display: flex;
    align-items: center;
  }
  ul {
    display: flex;
    list-style: none;
    gap: 2.5rem;
    // flex-grow: 1;
    justify-content: center;
    li {
      display: flex;
      color: #79747e;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.005rem;
      gap: 1rem;
      .number-circle {
        border: 0.125rem solid #79747e;
        display: flex;
        width: 1.625rem;
        height: 1.625rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }
    }
    .active-page {
      color: var(--#{$variable-prefix}primary-color);
      .number-circle {
        border-color: var(--#{$variable-prefix}primary-color);
      }
    }
  }
}

.subscriptions-nav {
  .features-container {
    margin-left: 1.25rem;
    @media (max-width: 991px) {
      margin-left: 0;
      flex-wrap: wrap;
    }
  }
  &.header-navbar {
    @media (max-width: 991px) {
      height: auto;
    }
  }
}

.price-detail-wrapper {
  display: flex;
  align-items: center;
  gap: 1.875rem;
  width: 100%;
  @media (max-width: 767px) {
    gap: 1.5rem;
  }
  @media (max-width: 679px) {
    flex-direction: column;
  }
}

.price-detail-box {
  border: 0.063rem solid transparent;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  background-color: #fff;
  @media (max-width: 1199px) {
    width: 100%;
  }
  &.active {
    border: 0.125rem solid #6750a4;
  }
  h2 {
    margin-bottom: 0.625rem;
    color: #515151;
    font-size: 1.125rem;
    font-weight: 400;
  }

  .vat-text {
    font-size: 0.75rem;
    font-weight: 400;
    color: #717171;
    span {
      color: #6750a4;
    }
  }
  .line {
    display: block;
    margin: 1.25rem 0;
    border-bottom: 0.063rem dashed #d9d9d9;
  }
  h4 {
    margin-bottom: 1.25rem;
    font-size: 1rem;
    font-weight: 700;
    color: #515151;
  }
}

.subscription-holder {
  display: flex;
  align-items: center;
  gap: 0.063rem;
  margin-bottom: 0.625rem;
  .price {
    display: block;
    color: #13171f;
    font-weight: 700;
    font-size: 1.875rem;
    @media (max-width: 767px) {
      font-size: 1.5rem;
    }
    span {
      color: #6750a4;
    }
  }
  .plan {
    font-size: 0.875rem;
    color: #515151;
  }
}

.price-breakdown-list {
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      font-size: 0.75rem;
      font-weight: 400;
      color: #393939;
    }
    strong {
      color: #1c1c1c;
      font-size: 0.75rem;
      font-weight: 700;
    }
    &.footer {
      padding-top: 1rem;
      border-top: 0.063rem solid #fbfaff;
    }
  }
}

.unleash-text {
  display: block;
  font-size: 1rem;
  color: #000;
  font-weight: 400;
}

.create-account-btn-wrapper {
  width: 100%;
  transition: bottom 0.3s ease;
  @media (max-width: 767px) {
    position: relative;
    &.sticky {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1.25rem;
      background-color: #fff;
      z-index: 1000;
    }
  }
}

.selected-plan-wrap {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  @media (max-width: 1199px) {
    display: none;
  }
}

.selected-plan-mobile-view {
  display: none;
}

.plan-feature-wrap {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  .feature-container {
    margin-bottom: 1rem;
  }
}

.feature-list-wrap {
  display: flex;
  gap: 1.875rem;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .feature-title {
    margin-bottom: 1rem;
    color: #1c1c1c;
    font-size: 1.125rem;
    font-weight: 700;

    @media (max-width: 1500px) {
      font-size: 1rem;
      font-weight: 600;
    }

    @media (max-width: 767px) {
      font-size: 1rem;
      font-weight: 700;
    }
  }
  .feature-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    p {
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: 400;
      color: #1c1c1c;
      margin: 0;
      padding-left: 1.875rem;
      position: relative;
      svg {
        width: 1.125rem;
        height: 1.125rem;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        path,
        rect {
          stroke: #00aa3a;
        }
      }
    }

    .feature-contents-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;

      p {
        padding-left: 1.5rem;
      }
    }

    @media screen and (max-width: 1500px) {
      .feature-contents-wrap {
        grid-template-columns: 1fr;
      }
    }
  }
}

.annual-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.common-badge {
  display: block;
  padding: 0.313rem 0.625rem;
  border-radius: 0.125rem;
  background-color: #f6f2ff;
  font-size: 0.875rem;
  line-height: 1.172rem;
  font-weight: 400;
  color: #6750a4;
}

.round-badge {
  display: block;
  width: 3.25rem;
  height: 1.5rem;
  padding: 0.313rem 0.75rem;
  border-radius: 1rem;
  background-color: #3e3e3e;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
}

.save-badge-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 0.625rem;
  h2 {
    margin: 0;
  }
}

.plan-detail-wrapper {
  background-color: #fbfaff;
  display: flex;
}
