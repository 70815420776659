.upload-file-content {
  display: flex;
  max-width: 35.75rem;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0.25rem;
  background-color: #fff;
  margin: 0 auto;
  h4 {
    color: #000;
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
  }
}

.file-upload {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0.375rem;
  border-radius: 0.5rem;
  border: 0.063rem dashed #6750a4;
  text-align: center;
  color: #585858;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;

  .file-label {
    display: block;
    width: 100%;
    padding: 2.5rem;
    cursor: pointer;
  }

  .file-input {
    display: none;
  }
  span {
    color: #6750a4;
    font-weight: 600;
  }
}

.upload-file-info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0.25rem;
  span {
    color: #8d8d8d;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    margin-right: 1.5rem;
    &:last-child {
      margin-right: 0;
    }
    strong {
      color: #585858;
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
    }
  }
}

.upload-file-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  .btn {
    padding: 0.625rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .bg-cancel-btn {
    border: 0.063rem solid #8d8d8d;
    color: #8d8d8d;
  }
  .bg-process-btn {
    background-color: #4e378a;
    color: #fff;
    &.disabled {
      pointer-events: none;
      background-color: #f1f1f1;
      color: #fff;
    }
  }
}

.file-upload-item {
  width: 100%;
  display: flex;
  padding: 1rem;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 0.063rem solid #e4e7ec;
  background: #fff;
  .file-info-wrap {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    flex: 1 0 0;
  }
  .icon-wrap {
    display: flex;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 1.125rem;
    border: 0.063rem solid #6750a4;
    color: #6750a4;
  }
  .info-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    flex: 1 0 0;
    h4 {
      color: #000;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
      text-align: left;
    }
  }
  .progress-bar {
    &.bg-primary {
      background-color: #6750a4 !important;
    }
  }
  .progress-wrap {
    padding: 0;
    .progress-value {
      color: #585858 !important;
    }
  }
  .delete-icon-btn {
    border: none;
    background-color: transparent;
    outline: none;
  }
  
  &.error-upload-item {
    border-color: #e04d4d;
    .delete-icon-btn {
      color: #871818;
    }
    .icon-wrap {
      border-color: #d30000;
      color: #d30000;
    }
    .info-wrap {
      h4 {
        color: #871818;
      }
      p {
        color: #e04d4d;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
      }
      button {
        margin-top: .5rem;
        display: block;
        color: #871818;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem;
        cursor: pointer;
        border: none;
        outline: none;
        background-color: transparent;
      }
    }
  }
}
